import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    inset: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300,
    margin: 0,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  optionsPopup: {
    width: '80%',
    maxWidth: 400, // Adjust this value as necessary
    background: 'white',
    padding: 20,
    borderRadius: 10,
    position: 'relative',
  },
  option: {
    margin: '10px 0',
  },
  label: {
    display: 'block',
    marginBottom: 5,
  },
  range: {
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const OptionsPopup = ({ open, onClose, subscribeNewsletter, setSubscribeNewsletter, tourSpeedGraph, setTourSpeedGraph, tourSpeedList, setTourSpeedList, spinSpeed, setSpinSpeed, imageSize, setImageSize, useAspectRatio, setUseAspectRatio, resetCookieConsent, setResetCookieConsent }) => {
  const classes = useStyles();
 
  const [tempSubscribeNewsletter, setTempSubscribeNewsletter] = useState(subscribeNewsletter);
  const [tempTourSpeedGraph, setTempTourSpeedGraph] = useState(tourSpeedGraph);
  const [tempTourSpeedList, setTempTourSpeedList] = useState(tourSpeedList);
  const [tempSpinSpeed, setTempSpinSpeed] = useState(spinSpeed);
  const [tempImageSize, setTempImageSize] = useState(imageSize);
  const [tempUseAspectRatio, setTempUseAspectRatio] = useState(useAspectRatio);
  const [tempResetCookieConsent, setTempResetCookieConsent] = useState(resetCookieConsent);

  const handleSliderChange = (event, newValue, setStateFunc) => {
    setStateFunc(newValue);
  };

  const handleSave = () => {
    setTourSpeedGraph(tempTourSpeedGraph);
    setTourSpeedList(tempTourSpeedList);
    setSpinSpeed(tempSpinSpeed);
    setImageSize(tempImageSize);
    setUseAspectRatio(tempUseAspectRatio);
    setResetCookieConsent(tempResetCookieConsent);

    if (tempSubscribeNewsletter !== subscribeNewsletter) {
      setSubscribeNewsletter(tempSubscribeNewsletter);
      onClose(true, tempSubscribeNewsletter);
    } else {
      onClose(false);
    }
  };

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
      <DialogTitle id="form-dialog-title">
        Options
      </DialogTitle>
      <DialogContent className={classes.optionsPopup}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <Typography id="aspect-ratio-checkbox" gutterBottom style={{ marginRight: 10 }}>
            Subscribe to E3D Daily Newsletter
          </Typography>
          <Checkbox
            checked={tempSubscribeNewsletter}
            onChange={(e) => setTempSubscribeNewsletter(e.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <Typography id="tour-speed-slider" gutterBottom style={{ marginRight: 10 }}>
            Graph View Tour Delay (ms)
          </Typography>
          <Slider
            value={tempTourSpeedGraph}
            onChange={(e, newValue) => handleSliderChange(e, newValue, setTempTourSpeedGraph)}
            aria-labelledby="tour-speed-slider"
            min={1000}
            max={10000}
            style={{ flex: 1 }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <Typography id="tour-speed-slider" gutterBottom style={{ marginRight: 10 }}>
            List View Tour Delay (ms)
          </Typography>
          <Slider
            value={tempTourSpeedList}
            onChange={(e, newValue) => handleSliderChange(e, newValue, setTempTourSpeedList)}
            aria-labelledby="tour-speed-slider"
            min={500}
            max={5000}
            style={{ flex: 1 }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <Typography id="spin-speed-slider" gutterBottom style={{ marginRight: 10 }}>
            Spin Delay (ms)
          </Typography>
          <Slider
            value={tempSpinSpeed}
            onChange={(e, newValue) => handleSliderChange(e, newValue, setTempSpinSpeed)}
            aria-labelledby="spin-speed-slider"
            min={1}
            max={1000}
            style={{ flex: 1 }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <Typography id="spin-speed-slider" gutterBottom style={{ marginRight: 10 }}>
            Graph Node Image Size
          </Typography>
          <Slider
            value={tempImageSize}
            onChange={(e, newValue) => handleSliderChange(e, newValue, setTempImageSize)}
            aria-labelledby="spin-speed-slider"
            min={1}
            max={100}
            style={{ flex: 1 }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <Typography id="aspect-ratio-checkbox" gutterBottom style={{ marginRight: 10 }}>
            Fill Image to Window
          </Typography>
          <Checkbox
            checked={tempUseAspectRatio}
            onChange={(e) => setTempUseAspectRatio(e.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
          <Typography id="aspect-ratio-checkbox" gutterBottom style={{ marginRight: 10 }}>
            Reset Cookie Consent
          </Typography>
          <Checkbox
            checked={tempResetCookieConsent}
            onChange={(e) => setTempResetCookieConsent(e.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};