import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { SERVER_NAME } from './config';
import useStyles from './sharedStyles'; 
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Checkbox, FormControlLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear'; 
import SearchIcon from '@material-ui/icons/Search';

export const SearchDialog = ({ searchTextParam, setSearchTextParam, open, onSearch, onClose }) => {
  const classes = useStyles();
  const [searchFieldText, setSearchFieldText] = useState(searchTextParam);
  //const inputRef = useRef();

    //useEffect(() => {
    //  inputRef.current.focus();
    //}, [searchFieldText]);
    
    const handleChange = (event) => {
      setSearchFieldText(event.target.value);
    };
  
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        setSearchTextParam(searchFieldText);
        onSearch(searchFieldText);
      }
    };

  return ReactDOM.createPortal(
      (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" className={classes.overlay}>
          <DialogTitle id="form-dialog-title">
            Search
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              //inputRef={inputRef}
              autoFocus
              margin="dense"
              id="search"
              type="text"
              fullWidth
              placeholder="e.g. Tether, 0x123456..."
              value={searchFieldText}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon/>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {searchTextParam && (
                      <IconButton
                        aria-label="clear text"
                        onClick={() => setSearchFieldText('')}
                        edge="end"
                        size="small"
                      >
                        <ClearIcon  />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => onSearch(searchFieldText)} color="primary">
              Search
            </Button>
          </DialogActions>
        </Dialog>
      ),
      document.getElementById('portal-root')
    );
};

export const NodeInfoDialog = ({ onClose, top, left }) => {
  const classes = useStyles();

  const dialogStyles = {
    position: 'absolute', // Use absolute positioning within the fixed overlay
    top: `${top}px`,  // Adjusted Y coordinate
    left: `${left}px`, // Adjusted X coordinate
    transform: 'translate(-50%, -50%)' // Center the dialog on the cursor
  };

  return ReactDOM.createPortal(
    (
      <Dialog open onClose={onClose} aria-labelledby="cookie-consent-title" className={classes.overlay} style={dialogStyles}>
        <DialogTitle id="cookie-consent-title">
          Cookie Consent
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          So much information
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    ),
    document.getElementById('portal-root')
  );
};

export const AboutDialog = ({ onClose }) => {
  const classes = useStyles();

  return ReactDOM.createPortal(
    (
      <div className={classes.overlay} onClick={onClose}>
        <div className={classes.scrollDialog} onClick={(e) => e.stopPropagation()} style={{ overflowY: 'auto' }}>
          <AboutContent />
          <Button variant="contained" onClick={onClose} className={`${classes.button} ${classes.closeButton}`}>
            Close
          </Button>
        </div>
      </div>
    ),
    document.getElementById('portal-root')
  );
};

export const AboutContent = () => {
  return (
  <div>
    <h2>Welcome to E3D (Beta)</h2>
    <p>E3D is the first of its kind state-of-the-art cloud-based platform that brings the Ethereum blockchain to life in a 3D interactive graph that breaks down the complex world of blockchain data into an accessible, visual, and immersive experience.
       It is also fun and beautiful. With E3D you get the big picture, and the ability to move around in it, and change it to suit your style.</p>
    
    <h3>Why E3D is Amazing!</h3>
    <ul>
        <li><strong>Immersive 3D Visualization:</strong> Dive into the digital cosmos where each token, smart contract, and transaction is not just a data point, but a visual story. With real-time 3D graphics, you can navigate the Ethereum ecosystem like never before.</li>
        <li><strong>Intuitive User Interface:</strong> Whether you’re a seasoned crypto trader or new to the blockchain space, the E3D UI is designed for effortless interaction. It’s simple to understand, fun to explore, and works seamlessly on AWS-supported servers.</li>
        <li><strong>Dynamic Data Interaction:</strong> Instantly visualize the latest transactions, explore connections between addresses, and interact with the blockchain data in real-time. Click through the graph to learn, analyze, and discover.</li>
    </ul>

    <h3>Features That Empower You!</h3>
    <ul>
        <li><strong>Search and Discover:</strong> Use our powerful search feature to locate addresses or tokens within the Ethereum network, revealing their activity and interconnections.</li>
        <li><strong>Detailed Insights:</strong> Our interface displays intricate details of each transaction, providing you with the information you need at a glance.</li>
        <li><strong>Seamless Navigation:</strong> Click on any list item to auto-navigate through the blockchain universe, focusing on the elements that matter to you.</li>
        <li><strong>Etherscan Integration:</strong> A shift-click on any graph element opens up detailed information on the etherscan.io website for in-depth analysis.</li>
        <li><strong>Take the Tour:</strong> Sit back and take a tour of the tokens. Stop the tour and move through them manually with the left and right arrows</li>
        <li><strong>Auto Refresh:</strong> Turn on Auto Refresh and let the graph update for you. Perfect for the Investor Info wall</li>
    </ul>

    <h3>Exciting Uses Cases!</h3>
    <ul>
        <li><strong>Market Trend Analysis:</strong> Stay ahead of the curve by observing the latest tokens and their interactions within the larger ecosystem.</li>
        <li><strong>Investment Opportunities:</strong> Discover potential investment gems before they hit the major exchanges.</li>
        <li><strong>Educational Tool:</strong> Use our platform as a teaching aid to explain the complexities of blockchain technology in a visually engaging manner.</li>
        <li><strong>Development Resource:</strong> Access a comprehensive tool for developers to analyze smart contracts and blockchain projects.</li>
    </ul>

    <h3>Why Sign Up Now?</h3>
    <ul>
        <li><strong>Early Access:</strong> Be one of the first to experience the future of blockchain interaction.</li>
        <li><strong>Premium Features:</strong> Subscribers gain access to premium features like auto-updates and guided tours, providing an enriched experience.</li>
        <li><strong>Join the Community:</strong> Engage with fellow blockchain enthusiasts and be part of a community that’s shaping the future of blockchain visualization.</li>
        <li><strong>Future Innovations:</strong> With plans to introduce AI-driven insights and direct wallet integrations, the potential of FutCo is limitless.</li>
    </ul>

    <p>Join E3D today and turn your blockchain exploration into an adventure. Sign up now and be part of the movement that’s making blockchain data engaging, comprehensive, and, most of all, fun!</p>
  </div>
  );
};



export const CookieConsentDialog = ({ onClose, onAccept }) => {
  const classes = useStyles();

  return ReactDOM.createPortal(
    (
      <Dialog open onClose={onClose} aria-labelledby="cookie-consent-title" className={classes.overlay} 
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}>
        <DialogTitle id="cookie-consent-title">
          Cookie Consent
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          We use cookies to improve your experience on our site. By accepting, you agree to our use of cookies.
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Decline
          </Button>
          <Button onClick={onAccept} color="primary">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    ),
    document.getElementById('portal-root')
  );
};

export const VerifyEmailCodeDialog = ({ onClose, username }) => {
  const classes = useStyles();
  const [code, setCode] = useState(new Array(6).fill(''));
  
  const handleChange = (element, index) => {
    const value = element.value;

    // Check if the input is a number and has only one character
    if (value === '' || (isFinite(value) && value.length <= 1)) {
      let newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
  
      // Focus next input if the value is not empty
      if (value !== '' && element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };

  const handleSubmit = async () => {
    const verificationCode = code.join('');

    try {
      const response = await axios.post(`${SERVER_NAME}/verifyEmailCode`, {
        username: username,
        code: verificationCode
      });

      if (response.data.success) {
        alert('Email verified successfully.');
        onClose(); // Close the dialog on successful verification
      } else {
        alert('Invalid or expired verification code.');
        // Trigger the resend code logic here if needed
      }
    } catch (error) {
      console.error('Verification error:', error);
      alert('Error during verification.');
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text'); // Get pasted value
    if (/^\d{6}$/.test(pasteData)) { // Ensure it’s a 6-digit numeric code
      const newCode = [...code]; // Copy the current code state
      pasteData.split('').forEach((digit, index) => {
        if (index < newCode.length) {
          newCode[index] = digit; // Set each digit to the corresponding input
        }
      });
      setCode(newCode); // Update the code state
    }
  };
  
  return ReactDOM.createPortal(
    (
      <Dialog open onClose={onClose} aria-labelledby="verify-email-title" className={classes.overlay}>
        <DialogTitle id="verify-email-title">
          Verify Code From Email
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.codeInputContainer}>
            {code.map((_, index) => (
              <input
                className={classes.codeInput}
                key={index}
                type="number"
                maxLength="1"
                value={code[index]}
                onChange={(e) => handleChange(e.target, index)}
                onPaste={(e) => handlePaste(e)}
                style={{ /* Inline styles for demonstration, should be moved to classes */
                  width: '1em', // Only wide enough for one character
                  padding: '10px', // Space inside the fields
                  margin: '5px', // Space between the fields
                  border: '2px solid black', // Fat and bold border
                  fontSize: '20px', // Bigger font size
                }}
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            Verify
          </Button>
        </DialogActions>
      </Dialog>
    ),
    document.getElementById('portal-root')
  );
};


export const SignupDialog = ({ onClose, onSignup }) => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [subscribeNewsletter, setSubscribeNewsletter] = useState(true);

  const handleSignup = async () => {
    // Check if passwords match
    if (password !== confirmPassword) {
      alert("Passwords don't match.");
      return;
    }
  
    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    // Check if the email is valid
    if (!emailPattern.test(username)) {
      alert("Please enter a valid email address.");
      return;
    }
  
    // Proceed with the signup if the email is valid
    onSignup(username, password, subscribeNewsletter);
  };

  return ReactDOM.createPortal(
    (
      <Dialog open onClose={onClose} aria-labelledby="form-dialog-title" className={classes.overlay}>
        <DialogTitle id="form-dialog-title">
          Signup
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="Username (email address)"
            type="text"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="e.g. johndoe@gmail.com"
          />
          <TextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={subscribeNewsletter}
                onChange={(e) => setSubscribeNewsletter(e.target.checked)}
                name="subscribeNewsletter"
                color="primary"
              />
            }
            label="Sign up for the daily E3D Newsletter"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSignup} color="primary">
            Signup
          </Button>
        </DialogActions>
      </Dialog>
    ),
    document.getElementById('portal-root')
  );
};

  export const LoginDialog = ({ onClose, onLogin, setShowSignup }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const classes = useStyles();
    
    const handleLogin = () => {
  
      // Email validation regex pattern
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
      // Check if the email is valid
      if (!emailPattern.test(username)) {
        alert("Please enter a valid email address.");
        return;
      }
    
      onLogin(username, password);
    };
  
    return ReactDOM.createPortal(
        (
          <Dialog open onClose={onClose} aria-labelledby="form-dialog-title" className={classes.overlay}>
            <DialogTitle id="form-dialog-title">
              Sign In
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="username"
                  label="Username (email address)"
                  type="text"
                  fullWidth
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="e.g. johndoe@gmail.com"
                />
                <TextField
                  margin="dense"
                  id="password"
                  label="Password"
                  type="password"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Login
                </Button>
              </DialogActions>
            </form>
            <p 
              onClick={() => {
                onClose();
                setShowSignup(true);
              }}
              style={{ 
                cursor: 'pointer', 
                marginTop: '1em', 
                color: 'gray', 
                fontStyle: 'italic',
                marginLeft: '2em', // adjust this value to move the text to the right
                textDecoration: 'underline' // underline the text
              }}
            >
              Don't have an account? Sign up
            </p>
          </Dialog>
        ),
        document.getElementById('portal-root')
      );
  };
  
  export const LogoutConfirmDialog = ({ onClose, onLogout }) => {
    const classes = useStyles();
    
    const handleLogout = () => {
      onLogout();
    };
  
    return ReactDOM.createPortal(
    (
        <div className={classes.overlay} onClick={onClose}>
        <div className={classes.dialog} onClick={(e) => e.stopPropagation()}>
            <h2>Are you sure you want to logout?</h2>
            <Button variant="contained" onClick={onClose} className={`${classes.button} ${classes.cancelButton}`}>
              Cancel
            </Button>
            <Button variant="contained" onClick={onLogout} className={`${classes.button} ${classes.logoutButton}`}>
              Logout
            </Button>
        </div>
        </div>
    ),
    document.getElementById('portal-root')
    );
  };

  export const MembersOnlyDialog = ({ onClose, onSignup, onLogin }) => {
    const classes = useStyles();
  
    return ReactDOM.createPortal(
    (
        <Dialog open onClose={onClose} aria-labelledby="form-dialog-title" className={classes.overlay}>
            <DialogTitle id="form-dialog-title">
                Welcome to FutCo, the Utilimate Ethereum Blockchain Metaverse!
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
                Login / Signup for free to use this and many other features!
            </DialogContent>
            <DialogActions>
              <Button onClick={onSignup} color="primary">
                Signup
              </Button>
              <Button onClick={onLogin} color="primary">
                Login
              </Button>
            </DialogActions>
          </Dialog>
    ),
    document.getElementById('portal-root')
    );
  };
  
