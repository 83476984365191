import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
//import Slider from 'react-slider';
import { ForceGraph2D, ForceGraph3D } from 'react-force-graph';
import SpriteText from 'three-spritetext';
import { SphereGeometry, Mesh, Color, DirectionalLight, MeshPhongMaterial, AmbientLight} from 'three';
import { Sprite, TextureLoader, SpriteMaterial, Texture, VideoTexture } from 'three';
import Papa from 'papaparse';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
//import Divider from '@material-ui/core/Divider';
import { Button, TextField } from '@material-ui/core';
import { Menu, MenuItem } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear'; 
import SearchIcon from '@material-ui/icons/Search';
import { jsPDF } from 'jspdf';
import axios from 'axios';
import Web3 from 'web3';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SearchDialog, AboutDialog, LoginDialog, SignupDialog, VerifyEmailCodeDialog, LogoutConfirmDialog, MembersOnlyDialog, CookieConsentDialog } from './AuthDialogs';
import { NodeInfoDialog } from './AuthDialogs';
import { SaveMapDialog } from './MapDialogs';
import { LoadMapDialog } from './MapDialogs';
import { NodeList, LinkList, LinkListPCAP } from './NodeLists';
import useStyles from './sharedStyles'; 
import { SERVER_NAME, ETHERSCAN, BSCSCAN, PCAP, etherscanLogo } from './config';
import { FaCompress, FaExpand, FaArrowsAlt, FaCircle, FaDotCircle, FaUserCircle, FaUndo, FaExclamationTriangle, FaCog, FaChevronRight } from 'react-icons/fa';
import { FaSync, FaBars, FaRedo, FaArrowRight, FaArrowLeft, FaLock, FaGlobe, FaCircleNotch, FaGripLines, FaNewspaper, FaTimes, FaHourglassHalf } from 'react-icons/fa';
import { FaRegSquare, FaCube, FaCubes, FaTh, FaUsers, FaObjectGroup, FaClock, FaCertificate, FaBitcoin, FaFilter, FaSearch, FaFont } from 'react-icons/fa';
import { FaSave, FaCalendarAlt, FaFileExport, FaDollarSign, FaChartBar, FaQuestionCircle, FaEllipsisH, FaBus, FaPlus, FaMinus, FaEye, FaTools } from 'react-icons/fa';
import { genericTokenIcon, genericUserIcon, futLogoIcon200 } from './config';
import TokenInfoComponent from './TokenInfoComponent';
//import FutcoChart from './FutcoChart';
import WalletConnect from './WalletConnect';
import { initGA, logPageView } from './analytics';
import { TokenContainer } from './TokenContainer'; 
import { OptionsPopup } from './OptionsPopup';
import { Newsletter } from './Newsletter';
//import { set } from 'react-ga';
//import Cookies from 'js-cookie';
import HomePage from './HomePage';
import ShortcutPage from './ShortcutPage';


const web3 = new Web3();
axios.defaults.withCredentials = true;

// Outside your component
let showAdvanced = true;
let isLoaded = false;
let sparkLength = 24;
let isMobile = false;

function App() {
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });
  const [tokenList, setTokenList] = useState([]);
  const [tokenGraphList, setTokenGraphList] = useState([]);
  const [linkCount, setLinkCount] = useState( new Map() );
  const [transactions, setTransactions] = useState(null);
  const [originalGraphData, setOriginalGraphData] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [searchText, setSearchText] = useState('');
  const classes = useStyles();
  const graphRef = useRef(null);
  const [mapData, setMapData] = useState(new Map());
  const [showText, setShowText] = useState(true);
  const [intervalEthId, setIntervalEthId] = useState(null);
  const [intervalBscId, setIntervalBscId] = useState(null);
  const simulationRef = useRef();
  const [PauseSimulation, setPauseSimulation] = useState(false);
  const [showPanel, setShowPanel] = useState(true);
  const [showBottom, setShowBottom] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isFirstEngineStop, setIsFirstEngineStop] = useState(true);
  const [is3DMode, setIs3DMode] = useState(true);
  const [progressVisible, setProgressVisible] = useState(false);
  const [progress, setProgress] = useState(0); // Initialize progress state to 0
  const [dataSource, setDataSource] = useState(0); // 0 = PCAP, 1 = ETH, 2 = BSC
  const [maxLinks, setMaxLinks] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [dateRange, setDateRange] = useState({ startDate: 0, endDate: 0 });
  const [dateRange2, setDateRange2] = useState({ startDate: 0, endDate: 0 });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showMembersOnly, setShowMembersOnly] = useState(false);
  const [showVerifyEmailCode, setShowVerifyEmailCode] = useState(false);
  const [showAdvancedToolbar, setShowAdvancedToolbar] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isTourRunning, setIsTourRunning] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [userID, setUserID] = useState('');
  const [showCookieDialog, setShowCookieDialog] = useState(true);
  const [cookieConsent, setCookieConsent] = useState(false);
  const [resetCookieConsent, setResetCookieConsent] = useState(false);
  const [showNodeInfoDialog, setShowNodeInfoDialog] = useState(false);
  const [rate, setRate] = useState(null);
  const [showAboutDialog, setShowAboutDialog] = useState(false);
  const [showSearchDialog, setShowSearchDialog] = useState(false);
  const [showSaveMapDialog, setShowSaveMapDialog] = useState(false);
  const [showLoadMapDialog, setShowLoadMapDialog] = useState(false);
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [currentNodeIndex, setCurrentNodeIndex] = useState(0);
  const [spinCamera, setSpinCamera] = useState(true);
  const [cursorPos, setCursorPos] = useState({ x: null, y: null });
  const [tokenData, setTokenData] = useState(null);
  const [activeNodeId, setActiveNodeId] = useState(null);
  const [defaultNodeId, setDefaultNodeId] = useState(null);
  const [updateCoordinatesIntervalId, setUpdateCoordinatesIntervalId] = useState(null);
  const [activeNodeName, setActiveNodeName] = useState(null);
  const [activeLinkId, setActiveLinkId] = useState(null);
  const [mode3D, setMode3D] = useState(true);
  const [globalDistance, setGlobalDistance] = useState(1000);
  const [globalSize, setGlobalSize] = useState(2000);
  const [userTransactions, setUserTransactions] = useState(false);
  const [useTime, setUseTime] = useState(true);
  const [viewGraph, setViewGraph] = useState(false);
  const [filterNFT, setFilterNFT] = useState(null);
  const [filterToken, setFilterToken] = useState(null);
  const [filterValue, setFilterValue] = useState(false);
  const [newPositions, setNewPositions] = useState(null);
  const [rangeInterval, setRangeInterval] = useState(300);
  const [maps, setMaps] = useState(60);
  const [chartData, setChartData] = useState(null);
  const [showChart, setShowChart] = useState(true);
  const [isPortrait, setIsPortrait] = useState(false);
  const [isFirstOrient, setIsFirstOrient] = useState(true);
  const [maxTransactions, setMaxTransactions] = useState('10000');
  const [maxNodes, setMaxNodes] = useState(-1);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [tokenUniswapVersion, setTokenUniswapVersion] = useState(0);
  const [e3dPrice, setE3dPrice] = useState(0);
  const [reload, setReload] = useState(false);
  const [imageKey, setImageKey] = useState(Date.now());
  const cameraSpinIntervalRef = useRef(null);
  const [imgRefs, setImgRefs] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);
  const [graphKey, setGraphKey] = useState(0);
  const [parseURL, setParseURL] = useState(true);
  const hasRun = useRef(false);
  const graphDataRef = useRef(graphData);
  const [showOptionsPopup, setShowOptionsPopup] = useState(false);
  const [showNewsletter, setShowNewsletter] = useState(false);
  const [newsDate, setNewsDate] = useState(null);
  const [tourSpeedGraph, setTourSpeedGraph] = useState(6000);
  const [tourSpeedList, setTourSpeedList] = useState(2000);
  const [spinSpeed, setSpinSpeed] = useState(60);
  const [useAspectRatio, setUseAspectRatio] = useState(false);
  const [imageSize, setImageSize] = useState(100);
  const [organicLayout, setOrganicLayout] = useState(false);
  const nodeListRef = useRef();
  const memoizedGraphData = useMemo(() => graphData, [graphData]);
  const memoizedGraphRef = useMemo(() => graphRef, [graphRef]);
  const memoizedDataSource = useMemo(() => dataSource, [dataSource]);
  const memoizedTokenList = useMemo(() => tokenList, [tokenList]);
  const [showHomePage, setShowHomePage] = useState(true);
  const [showShortcutPage, setShowShortcutPage] = useState(false);

  const [subscribeNewsletter, setSubscribeNewsletter] = useState(false);
  const [showPicker, setShowPicker] = useState(false);

// Save options to localStorage when they are updated
useEffect(() => {
  if (cookieConsent && isLoaded) { // Check if the user has accepted the cookie consent
    localStorage.setItem('tourSpeedGraph', tourSpeedGraph);
    localStorage.setItem('tourSpeedList', tourSpeedList);
    localStorage.setItem('spinSpeed', spinSpeed);
    localStorage.setItem('useAspectRatio', useAspectRatio.toString());
    localStorage.setItem('imageSize', imageSize);
    localStorage.setItem('userID', userID);
    localStorage.setItem('viewGraph', viewGraph);
    localStorage.setItem('filterNFT', filterNFT);
    localStorage.setItem('filterToken', filterToken);
  }
}, [tourSpeedGraph, tourSpeedList, spinSpeed, useAspectRatio, imageSize, cookieConsent, userID, viewGraph, filterNFT, filterToken]);

// Restore options from localStorage when the component mounts
useEffect(() => {
  if (cookieConsent) { // Check if the user has accepted the cookie consent
    const savedTourSpeedGraph = localStorage.getItem('tourSpeedGraph');
    const savedTourSpeedList = localStorage.getItem('tourSpeedList');
    const savedSpinSpeed = localStorage.getItem('spinSpeed');
    const savedUseAspectRatio = localStorage.getItem('useAspectRatio');
    const savedImageSize = localStorage.getItem('imageSize');
    const savedUserID = localStorage.getItem('userID');
    const savedViewGraph = localStorage.getItem('viewGraph');
    const savedFilterNFT = localStorage.getItem('filterNFT');
    const savedFilterToken = localStorage.getItem('filterToken');

    if (savedTourSpeedGraph) setTourSpeedGraph(savedTourSpeedGraph);
    if (savedTourSpeedList) setTourSpeedList(savedTourSpeedList);
    // check spinSpeed, it may have been to 0 using nospin in params
    if (savedSpinSpeed && spinSpeed) setSpinSpeed(savedSpinSpeed);
    if (savedUseAspectRatio) setUseAspectRatio(savedUseAspectRatio === 'true');
    if (savedImageSize) setImageSize(savedImageSize);
    if (savedUserID) setUserID(savedUserID);
    if (savedViewGraph) setUserID(savedViewGraph);
    if (savedFilterNFT) setUserID(savedFilterNFT);
    if (savedFilterToken) setUserID(savedFilterToken);
  }
}, [cookieConsent]);

  // Update the ref whenever graphData changes
  useEffect(() => {
    graphDataRef.current = graphData;
  }, [graphData]);

  useEffect(() => {
    if (hasRun.current) { return; }
  
    //defaults
    setFilterNFT(false);
    setFilterToken(true);
    setViewGraph(true);

    const pathParts = window.location.pathname.split('/');
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.length || pathParts[1].length) {
      setShowHomePage(false);
    }

    if (searchParams.has('graph')) {
      setViewGraph(true);
      setShowHomePage(false);
    }

    if (pathParts[1] === 'price') {
      setViewGraph(false);
      setShowHomePage(false);
      
      // Check if there is a part after 'nft'
      if (pathParts[2]) {
        const address = pathParts[2];
        
        // Check if the address is in the correct form
        const isAddress = /^0x[a-fA-F0-9]{40}$/.test(address);
        if (isAddress) {
          setDefaultNodeId(address);
        }
      }
    }
  
    if (pathParts[1] === 'nft') {
      setFilterToken(false);
      setFilterNFT(true);
      setViewGraph(false);
      setShowShortcutPage(false);

      // Check if there is a part after 'nft'
      if (pathParts[2]) {
        const address = pathParts[2];
        
        // Check if the address is in the correct form
        const isAddress = /^0x[a-fA-F0-9]{40}$/.test(address);
        if (isAddress) {
          setDefaultNodeId(address);
        }
      }
    }
  
    if (pathParts[1] === 'token') {
      setFilterToken(true);
      setFilterNFT(false);
      setShowShortcutPage(false);

      // Check if there is a part after 'nft'
      if (pathParts[2]) {
        const address = pathParts[2];
        
        // Check if the address is in the correct form
        const isAddress = /^0x[a-fA-F0-9]{40}$/.test(address);
        if (isAddress) {
          setDefaultNodeId(address);
        }
      }
    }

    // Check for '?tour' in the URL
    if (searchParams.has('tour')) {
      setTimeout(() => setIsTourRunning(true), 5000); // Delay showing the tour to ensure the graph is loaded
      setShowShortcutPage(false);
    }
  
    // Check for '?news' in the URL
    if (searchParams.has('news')) {
      setShowNewsletter(true); 
      setShowShortcutPage(false);

      // Check for 'date' in the URL
      if (searchParams.has('date')) {
        const newsDate = searchParams.get('newsDate');
        console.log('News Date:', newsDate);
        setNewsDate(newsDate)
      }
    }

    if (pathParts[1] === 'news') {
      setShowNewsletter(true); 
      setShowShortcutPage(false);
      
      // Check if there is a param after 'news'
      if (pathParts[2]) {
        const newsDate = pathParts[2];
        console.log('News Date:', newsDate);
        setNewsDate(newsDate);

        // Parse the newsDate string
        const [datePart, timePart] = newsDate.split('_');
        const [year, month, day] = datePart.split('-').map(Number);
        const [hours, minutes, seconds] = timePart.split('-').map(Number);

        // Create a Date object from the parsed components
        const parsedDate = new Date(year, month - 1, day, hours, minutes, seconds);

        // Calculate the beginning of the day
        const startDate = new Date(parsedDate);
        startDate.setHours(0, 0, 0, 0);

        // Calculate the end of the day
        const endDate = new Date(parsedDate);
        endDate.setHours(23, 59, 59, 999);

        console.log('Start Date:', startDate);
        console.log('End Date:', endDate);
        const startTime = Math.floor(startDate.getTime() / 1000)
        const endTime = Math.floor(endDate.getTime() / 1000)
        //setDateRange( startTime, endTime);
        setDateRange2({startDate: startTime, endDate: endTime});
        console.log('Date Range:', dateRange2);
      }
    }

    // Check for 'users' in the URL
    if (searchParams.has('users')) {
      setUserTransactions(true); 
      setShowHomePage(false);
    }

   // Check for 'organic' in the URL
   if (searchParams.has('organic')) {
     setOrganicLayout(true); 
     setShowHomePage(false);
   }

    // Check for 'maxNodes' in the URL
    if (searchParams.has('maxNodes')) {
      const maxNodesValue = parseInt(searchParams.get('maxNodes'), 10);
      if (!isNaN(maxNodesValue)) {
        setMaxNodes(maxNodesValue);
        setShowHomePage(false);
      }
    }

    // Check for 'nospin' in the URL
    if (searchParams.has('nospin')) {
      setSpinSpeed(0); 
      setSpinCamera(false);
      setShowHomePage(false);
    }

    // Check for 'nopanel' in the URL
    if (searchParams.has('nopanel')) {
      setShowPanel(false); 
      setShowHomePage(false);
    }

    hasRun.current = true;
  }, []);

  useEffect(() => {
    setImgRefs(graphData.nodes.map(() => React.createRef()));
  }, [graphData.nodes]);

  useEffect(() => {
    initGA();
    logPageView('/'); // Log initial page view
  }, []);

  const handleOrientationChange = () => {
    if (isMobileDevice()) {
      isMobile = true;
      showAdvanced = false;
      setShowChart(true);

      const portrait = window.innerWidth < window.innerHeight;
      if (portrait) {
        setShowBottom(true);
        setShowPanel(false);
        setIsPortrait(true);
      } else {
        setShowBottom(false);
        setShowPanel(true);
        setIsPortrait(false);      
      }
    }
  };

  useEffect(() => {
    handleOrientationChange();

    window.addEventListener('resize', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  let isLoading = false;
  function setIsLoading(x) {
    isLoading = x;
  }

  const uploadNFTImage = async (name, description) => {
    if (!graphRef.current) return;
    
    const renderer = graphRef.current.renderer();
    const scene = graphRef.current.scene();
    const camera = graphRef.current.camera();

    // Render the scene to the WebGL renderer
    renderer.render(scene, camera);

    // Extract NFT contract addresses and positions from graphData
    const tokenListMap = graphData.nodes.map(node => ({
        address: node.id, 
        x: node.x,
        y: node.y,
        z: node.z
    }));

    // Define NFT metadata with the new timestamp
    const timestamp = dateRange.startTime;
    const nftName = (name) ? name : "FutCo NFT";
    const nftDescription = (description) ? description : "The Ethereum blockchain is also art";
    const nftMetadata = {
        name: nftName,
        description: nftDescription,
        properties: {
          authors: [{ name: "FutCo" }],
          timestamp: timestamp, // Adding the timestamp to properties
          tokenList: tokenListMap,
     
        }
    };

    // Use the WebGL context to capture the current frame
    const gl = renderer.getContext();
    const width = gl.drawingBufferWidth;
    const height = gl.drawingBufferHeight;

    // Create a new ArrayBuffer and read pixel data into it
    const pixels = new Uint8Array(width * height * 4);
    gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixels);

    // Create a 2D canvas to assemble the image
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');

    // Flip the image data and put it onto the canvas
    const imageData = new ImageData(new Uint8ClampedArray(pixels), width, height);
    context.putImageData(imageData, 0, 0);
    context.translate(0, canvas.height);
    context.scale(1, -1);
    context.drawImage(canvas, 0, 0);

    // Convert the canvas to a PNG blob
    canvas.toBlob(async (blob) => {
        // Add the image blob to the NFT metadata
        nftMetadata.image = blob;

        // Prepare the form data
        const formData = new FormData();
        formData.append('image', blob, 'nft-image.png');
        formData.append('nft', JSON.stringify(nftMetadata));

        // Send the form data to your endpoint
        try {
            const response = await fetch('/store-nft', {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();
            if (response.ok) {
                console.log('NFT stored successfully!', result);
                saveMap(result.data.metadata);
                // Handle success, such as showing a message to the user or redirecting
            } else {
                console.error('Error storing NFT:', result);
                // Handle error, such as showing an error message to the user
            }
        } catch (error) {
            console.error('Network error:', error);
            // Handle network error
        }
    }, 'image/png');
  };

  let cachedLoginStatus = null; // Holds the cached login status

  // Function to reset the cached login status - call this when logging in or out
  function resetCachedLoginStatus() {
      cachedLoginStatus = null;
  }

  async function isLoggedIn() {
      //if (cachedLoginStatus !== null) {
      //    return cachedLoginStatus; // Return the cached status if it exists
      //} else {
          try {
              const url = SERVER_NAME + '/auth/status';
              const response = await axios.get(url, { withCredentials: true });
              cachedLoginStatus = response.data.isAuthenticated; // Update the cache with the latest status
              if (cachedLoginStatus && response.data.user && response.data.user.email) { 
                setIsAuthenticated(true);
                setUsername(response.data.user.email);
              }
              return cachedLoginStatus;
          } catch (error) {
              console.error('Error checking login status:', error);
              cachedLoginStatus = false; // Assume not logged in if there's an error
              return false;
          }
      //}
  }


  async function saveMap(name) {
    // Check if user is logged in
    if (!await isLoggedIn()) {
      alert('Please log in to save the map');
      return;
    }
  
    // Limit the original array to the first 10 nodes before mapping
    const nodeList = graphData.nodes.map(node => ({
      id: node.id,
      x: node.x,
      y: node.y,
      z: node.z
    }));

    //console.log(nodeList);

    const timestamp = dateRange.startDate;

    // Prepare the map data
    const mapData = {
      name: name,
      timestamp: timestamp,
      nodes: nodeList
    };
  
    const jsonString = JSON.stringify(mapData);
    const sizeInBytes = new TextEncoder().encode(jsonString).length;
    console.log(`Size of JSON: ${sizeInBytes} bytes`);

    try {
      const response = await axios.post(`${SERVER_NAME}/maps/save`, mapData, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true // Important for including cookies in cross-origin requests, equivalent to credentials: 'include' in fetch
      });
      // Further processing...
      if (response.status === 200) {
        // Handle success
       // alert('Map saved successfully');
        setShowSaveMapDialog(false);
      } else {
        // Handle any errors that occurred during the request
        alert('Failed to save the map');
      }
    } catch (error) {
      console.error('Error sending map data:', error);
      alert('Error sending map data');
    }
  }
  

  async function selectMap() {
    // Check if user is logged in
    if (!isLoggedIn()) {
      alert('Please log in to load a map');
      return;
    }
  
    try {
      const response = await axios.get(`${SERVER_NAME}/maps/list`,  {
        withCredentials: true // Important for including cookies in cross-origin requests, equivalent to credentials: 'include' in fetch
      });
      // Further processing...
      if (response.status === 200) {
        setMaps(response.data.mapNames);
        setShowLoadMapDialog(true);
        console.log('Map name list loaded successfully');
      } else {
        // Handle any errors that occurred during the request
        console.log('Failed to save the map');
      }
    } catch (error) {
      console.error('Error loading map data:', error);
      alert('Error loading map list');
    }
  }
  
  async function openMap(mapName) {
    // Check if user is logged in
    if (!await isLoggedIn()) { // Ensure this is awaited to correctly check the login status asynchronously
        alert('Please log in to open a map');
        return;
    }

    try {
        // The mapName should be part of the URL or the configuration object, not a separate argument
        const response = await axios.get(`${SERVER_NAME}/maps/open/${encodeURIComponent(mapName)}`, {
            withCredentials: true // Important for including cookies in cross-origin requests, equivalent to credentials: 'include' in fetch
        });

        // Further processing...
        if (response.status === 200 && response.data) {
            const map = response.data.map;
            //console.log(map);
            setShowLoadMapDialog(false); // Presumably closes the dialog for loading maps
            console.log('Map opened successfully');

            // Assuming setDateRange and getTransactions are functions you have for handling the map data
            // Uncomment setDateRange if it needs to be set here
            // setDateRange(map.timestamp); // Update the date range based on the map's timestamp
            resetGraph();
            await getTransactions(dataSource, null, false, map.timestamp);
            setNewPositions(map.nodes);
 
        } else {
            // Handle any errors that occurred during the request
            console.log('Failed to open the map');
        }
    } catch (error) {
        console.error('Error loading map data:', error);
        alert('Error loading map data');
    }
  }

  function updateCoordinates() {
    if (showAdvanced && graphRef.current && graphRef.current.cameraPosition) {
      const position = graphRef.current.cameraPosition();
      const x = document.getElementById('X');
      const y = document.getElementById('Y');
      const z = document.getElementById('Z');
  
      if (x && y && z) {
        x.innerHTML = parseInt(position.x, 10).toString();
        y.innerHTML = parseInt(position.y, 10).toString();
        z.innerHTML = parseInt(position.z, 10).toString();
      }
    }
  }

  useEffect(() => {
    if (newPositions) {
      // Create a new array for nodes to ensure React detects the change
      const updatedNodes = graphData.nodes.map(node => {
        const mapNode = newPositions.find(mapNode => mapNode.id === node.id);
        if (mapNode) {
          //console.log(mapNode);
          // Return a new object for the updated node to ensure React detects the change
          return {
            ...node,
            x: mapNode.x,
            y: mapNode.y,
            z: mapNode.z,
            fx: mapNode.x, // Fixed positions in the force-directed graph
            fy: mapNode.y,
            fz: mapNode.z
          };
        }
        return node; // Return the original node if no updates are necessary
      });
  
      // Update the graph data state with the new nodes array
      setGraphData(prevData => ({
        ...prevData, // Spread the previous data to maintain other state properties
        nodes: updatedNodes // Set the new nodes array
      }));
  
      setNewPositions(null); // Reset newPositions now that we've processed them
    }
  }, [newPositions, graphData, setGraphData]); // Add setGraphData if it's from useState
  

  /*
  useEffect(() => {
    if (viewGraph && graphRef && graphRef.current) {
      // Increase the repulsion between nodes
      graphRef.current.d3Force('charge').strength(-120);

      // Increase the distance between linked nodes
      graphRef.current.d3Force('link').distance(100);

      // Reheat simulation to ensure continuous animation
      graphRef.current.d3ReheatSimulation();
    }
  }, [graphData]); // Rerun when data changes
*/

  function logHeight() {
    const topContainer = document.getElementById('topContainer');
    const linkContainer = document.getElementById('linkContainer');
    const bottomContainer = document.getElementById('bottomContainer');
    const graphContainer = document.getElementById('graphContainer');
    const panelContainer = document.getElementById('panelContainer');
    const container = document.getElementById('container');

    console.log("topContainer: " + topContainer.clientHeight);
    console.log("linkContainer: " + linkContainer.clientHeight);
    console.log("botttomContainer: " + bottomContainer.clientHeight);
    console.log("graphContainer: " + graphContainer.clientHeight);
    //console.log("panelContainer: " + panelContainer.clientHeight);
    console.log("container: " + container.clientHeight);
  }

  useEffect(() => {
    if (updateCoordinatesIntervalId == null) {
      const id = setInterval( updateCoordinates, 500);
      setUpdateCoordinatesIntervalId(id);
    }

    const handleResize = () => {
      if (filterNFT == null || filterToken == null) { return; }

      const topContainer = document.getElementById('topContainer');
      const linkContainer = document.getElementById('linkContainer');
      const bottomContainer = document.getElementById('bottomContainer');
      const mainContainer = document.getElementById( (viewGraph) ? 'graphContainer' : 'tokenContainer' );
      const panelContainer = document.getElementById('panelContainer');
      const container = document.getElementById('container');
      const nodeListContainerMobile = document.getElementById('nodeListContainerMobile');
      const nodeListContainer = document.getElementById('nodeListContainer');

      let width = window.innerWidth;
      let height = window.innerHeight;

      const topHeight = topContainer ? topContainer.clientHeight : 0;
      const bottomHeight = bottomContainer ? bottomContainer.clientHeight : 0;
      let linkHeight = linkContainer ? (viewGraph ? 300 : (height - topHeight - 5)) : 0;  // -5 is a hack to make it work
      if ((filterNFT || viewGraph) && (isMobile && isPortrait)) {
        linkHeight = (height - topHeight - 5) / 2;
      }

      const mainHeight = mainContainer ? height - topHeight - linkHeight : 0;

      if ((filterNFT || (isMobile && isPortrait)) && linkContainer) {
        const linkTop = mainHeight + topHeight;
        linkContainer.style.top = `${linkTop}px`; 
        linkContainer.style.height = `${linkHeight}px`; 
        linkContainer.style.maxHeight = `${linkHeight}px`; 
        linkContainer.style.scrollHeight = `${linkHeight}px`; 

        if (nodeListContainerMobile) {
          nodeListContainerMobile.style.height = `${linkHeight}px`; 
          nodeListContainerMobile.style.maxHeight = `${linkHeight}px`; 
          nodeListContainerMobile.style.scrollHeight = `${linkHeight}px`; 
        }
      }

      const panelHeight = mainHeight;
      
      if (mainContainer) {
        setDimensions({
          width: mainContainer.clientWidth,
          height: mainHeight
        });
      }
    

      if (linkContainer && nodeListContainer) {
        linkContainer.style.height = `${linkHeight}px`; 
        linkContainer.style.maxHeight = `${linkHeight}px`; 
        linkContainer.style.scrollHeight = `${linkHeight}px`; 
      
        if (!viewGraph && filterToken) { 
          const lessWidth = width - 5;
          linkContainer.style.width = `${lessWidth}px`; 
          linkContainer.style.maxWidth = `${lessWidth}px`; 
          nodeListContainer.style.width = `${lessWidth}px`; 
          nodeListContainer.style.maxWidth = `${lessWidth}px`; 
        }
      }

      if (container) {
        container.style.height = `${mainHeight}px`; 
        container.style.maxHeight = `${mainHeight}px`; 
        container.style.scrollHeight = `${mainHeight}px`; 
      }

      if (mainContainer) {
        mainContainer.style.height = `${mainHeight}px`; // Set the height using style.height
        mainContainer.style.maxHeight = `${mainHeight}px`; // Set the height using style.height
        mainContainer.style.scrollHeight = `${mainHeight}px`; // Set the height using style.height
      }
 
      if (panelContainer) {
        panelContainer.style.height = `${mainHeight}px`; 
        panelContainer.style.maxHeight = `${mainHeight}px`; 
        panelContainer.style.scrollHeight = `${mainHeight}px`; 
      }

    };

    window.addEventListener('resize', handleResize);
   
    handleResize();
  
    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [showPanel, showBottom, isMobile, isPortrait, viewGraph, filterToken, filterNFT, isDataReady, memoizedGraphData.nodes.length]);


  const updateRate = async () => {
    await axios.get(`${SERVER_NAME}/rate`)
    .then(response => {
      if (response.data && response.data.rate) {
        setRate(response.data.rate);
      }
    })
    .catch(error => console.error('Error fetching rate:', error));
  }
 
  useEffect(() => {
    isLoggedIn();
    updateRate();

    if (window.innerWidth < 800) {
      setShowPanel(false)
    }
  }, []);

  const checkCookieConsent = () => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent) {
      setCookieConsent( (consent == 'accepted') ? true : false);
      setShowCookieDialog(false);
    }
  };

  const handleAcceptCookies = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setCookieConsent(true);
    setShowCookieDialog(false);
  };

  const handleDeclineCookies = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setCookieConsent(false);
    setShowCookieDialog(false);
  };

  const handleCloseNodeInfoDialog = () => {
    setShowNodeInfoDialog(false);
  };

  const handleCloseTokenInfo = () => {
    setTokenData(null);
  };

  const handleCloseChart = () => {
    setShowChart(false);
  };

  // Check the user's cookie consent preference on component mount
  React.useEffect(() => {
    checkCookieConsent();
  }, []);

  useEffect(() => {
    if (resetCookieConsent) {
      setShowCookieDialog(true);
      setCookieConsent(false);
      setResetCookieConsent(false);
    }
  }, [resetCookieConsent]);


  function handleDistributeIconsAsLine() {
    const nodes = graphData.nodes;
    // Sort nodes based on price
    const sortedNodes = nodes.sort((a, b) => a.price - b.price);
    distributeIconsAsLine(sortedNodes);
  }

  const distributeIconsAsLine = (nodes) => {
      const totalNodes = nodes.length;
      const gap = 200; // This defines the spacing between each node on the line

      // Starting point for the first node, centers the middle node
      const startX = -gap * (totalNodes - 1) / 2;

      for (let i = 0; i < totalNodes; i++) {
          // Each node's x position is increased by 'gap' units from the starting x position
          nodes[i].x = startX + i * gap;
          nodes[i].y = 0; // All nodes at the same y position for a straight line
          nodes[i].z = 0; // Assuming a 2D layout for simplicity

          // Fix the nodes to their new positions
          nodes[i].fx = nodes[i].x;
          nodes[i].fy = nodes[i].y;
          nodes[i].fz = nodes[i].z; // If 3D is needed, adjust z accordingly
      }

      // Assume `setGraphData` is a function to update the graph's data state
      setGraphData({ ...graphData, nodes: [...nodes] });
  };

  const distributeIconsOnSphere = (nodes, radius) => {
    const totalNodes = nodes.length;
    const phi = Math.PI * (3 - Math.sqrt(5)); // Golden angle in radians

    for (let i = 0; i < totalNodes; i++) {
        const y = 1 - (i / (totalNodes - 1)) * 2; // y goes from 1 to -1
        const radiusAtY = Math.sqrt(1 - y * y); // radius at y

        const theta = phi * i; // golden angle increment

        const x = Math.cos(theta) * radiusAtY;
        const z = Math.sin(theta) * radiusAtY;

        nodes[i].x = radius * x;
        nodes[i].y = radius * y;
        nodes[i].z = (mode3D) ? (radius * z) : globalDistance;

        // Fix the nodes to their new positions
        nodes[i].fx = nodes[i].x;
        nodes[i].fy = nodes[i].y;
        nodes[i].fz = nodes[i].z;
    }
  };

  const distributeIconsInGridOrCube = (nodes, size) => {
    const totalNodes = nodes.length;
    const sideLength = mode3D ? Math.cbrt(totalNodes) : Math.sqrt(totalNodes); // Use cube root for 3D, square root for 2D
    const nodesPerSide = Math.ceil(sideLength);
    const spacing = size / nodesPerSide;

    let index = 0;
    for (let z = 0; z < (mode3D ? nodesPerSide : 1) && index < totalNodes; z++) {
        for (let y = 0; y < nodesPerSide && index < totalNodes; y++) {
            for (let x = 0; x < nodesPerSide && index < totalNodes; x++, index++) {
                const posX = x * spacing - (size / 2) + (spacing / 2);
                const posY = y * spacing - (size / 2) + (spacing / 2);
                const posZ = mode3D ? (z * spacing - (size / 2) + (spacing / 2)) : 0; // Adjust Z only for 3D

                nodes[index].x = posX;
                nodes[index].y = posY;
                nodes[index].z = posZ;

                // Fix the nodes to their new positions
                nodes[index].fx = nodes[index].x;
                nodes[index].fy = nodes[index].y;
                nodes[index].fz = nodes[index].z;
            }
        }
    }

    setGraphData({ ...graphData });
  };
 
  const distributeNodesInTriangle = (filteredNodes) => {
    const nodes = filteredNodes ? filteredNodes : graphData.nodes;
    const spacing = 300;
    const vspacing = 200;
    const hspacing = 400;

    // Ensure all nodes are defined
    nodes.forEach(node => {
        if (node) { // Only update linkCount if node is defined
            node.linkCount = linkCount.get(node.id) || 0;
        }
    });

    // Sort nodes to have fewer links at the top
    nodes.sort((a, b) => a.linkCount - b.linkCount);

    let levels = 1;
    let nodeIndex = 0;
    // Find the number of levels needed to ensure all nodes fit
    while ((levels * (levels + 1)) / 2 < nodes.length) {
        levels++;
    }

    // Calculate the total height and maximum width for centering
    let totalHeight = (levels - 1) * vspacing; // Assuming '100' is the vertical spacing
    let maxWidth = (levels - 1) * hspacing; // Assuming '100' is the horizontal spacing

    while (nodeIndex < nodes.length && levels > 0) {
        let currentLevelWidth = (levels - 1) * spacing; // Width of current level
        let xOffset = -currentLevelWidth / 2; // Centering horizontally
        let yOffset = -totalHeight / 2 + ((levels - 1) * spacing); // Centering vertically

        for (let i = 0; i < levels && nodeIndex < nodes.length; i++) {
            // Ensure node exists before setting properties
            if (nodes[nodeIndex]) {
                nodes[nodeIndex].x = xOffset + i * 100; // Place nodes horizontally
                nodes[nodeIndex].y = yOffset; // Place nodes vertically
                nodes[nodeIndex].z = 0; // Flat for 2D

                // Fix positions
                nodes[nodeIndex].fx = nodes[nodeIndex].x;
                nodes[nodeIndex].fy = nodes[nodeIndex].y;
                nodes[nodeIndex].fz = nodes[nodeIndex].z;
            }

            nodeIndex++;
        }
        levels--;  // Decrease the number of nodes for the next level
    }
    setGraphData({ ...graphData });
  };

  const distributeNodesInPyramid = (filteredNodes) => {
    const nodes = filteredNodes ? filteredNodes : graphData.nodes;

    // Assign linkCount based on the map
    nodes.forEach(node => {
        node.linkCount = linkCount.get(node.id) || 0;
    });

    // Sort nodes for 3D pyramid with high link count nodes at the bottom
    nodes.sort((a, b) => mode3D ? b.linkCount - a.linkCount : a.linkCount - b.linkCount);

    let levels = 1;
    let nodesCount = nodes.length;
    // Adjust levels calculation for 3D structure
    while ((levels * (levels + 1) * (2 * levels + 1) / 6) < nodesCount) {
        levels++;
    }

    const spacing = 300; // Adjust based on desired distance between nodes
    let nodeIndex = 0;  // Index to track the node being placed

    // Calculate and distribute nodes for each level
    for (let level = 0; level < levels && nodeIndex < nodesCount; level++) {
        let levelWidth = levels - level;  // Width of this level in the pyramid
        let rowStartIndex = nodeIndex;  // Keep track of the start index for this row

        for (let row = 0; row < levelWidth && nodeIndex < nodesCount; row++) {
            for (let col = 0; col < levelWidth && nodeIndex < nodesCount; col++) {
                // Calculate the position for each node
                let x = (col - levelWidth / 2 + 0.5) * spacing;
                let y = (levelWidth / 2 - row - 0.5) * spacing;
                let z = mode3D ? -level * spacing : 0;  // Move up each level in 3D

                nodes[nodeIndex].x = x;
                nodes[nodeIndex].y = y;
                nodes[nodeIndex].z = z;

                // Fix the nodes to their new positions
                nodes[nodeIndex].fx = nodes[nodeIndex].x;
                nodes[nodeIndex].fy = nodes[nodeIndex].y;
                nodes[nodeIndex].fz = nodes[nodeIndex].z;

                nodeIndex++;  // Move to the next node
            }
        }
    }
    setGraphData({ ...graphData });
  };


  const distributeNodesInPyramidOrTriangle = (filteredNodes) => {
    const nodes = filteredNodes ? filteredNodes : graphData.nodes;

    if (mode3D) {
      distributeNodesInPyramid(nodes);
    }
    else {
      distributeNodesInTriangle(nodes);
    }

  };

  
  const saveOriginalLocations = (nodes) => {
    nodes.forEach(node => {
      // Save original positions
      node.originalX = node.fx || node.x;
      node.originalY = node.fy || node.y;
      node.originalZ = node.fz || node.z;
    });
  };
  

  const handleDistributeAll = () => {
    if (mode3D) {
      handleDistributeSphereAll();
    } else {
      handleDistributeCircleAll();
    }
  }

  const handleDistributeCircleAll = () => {
    handleDistributeCircleUsers();
    handleDistributeCircleTokens();
    setGraphData({ ...graphData });
  };

  const handleDistributeSphereAll = () => {
    const nodes = graphData.nodes;
    distributeIcons(nodes, 1500);
    setGraphData({ ...graphData });
  }

  const handleDistributeUsers= () => {
    const nodesWithoutIcons = graphData.nodes.filter(node => node.icon == '');
    distributeIcons(nodesWithoutIcons, graphData.nodes.length * 15); //1500);
    setGraphData({ ...graphData });
  }

  const handleDistributeTokens = () => {
    const nodesWithIcons = graphData.nodes.filter(node => node.icon !== '');
    distributeIcons(nodesWithIcons, 1800);
    setGraphData({ ...graphData });
  }

  const handleDistributeUndo = () => {
    // Reset to original locations
    const nodes = graphData.nodes;
    nodes.forEach(node => {
      // Reset positions
      node.fx = node.originalX;
      node.fy = node.originalY;
      node.fz = node.originalZ;
    });
    setGraphData({ ...graphData });
  };


  const distributeIcons = (nodes, radius) => {
    if (mode3D) {
      distributeIconsOnSphere(nodes,radius);
    } 
    else {
      distributeIconsOnCircle(nodes,radius);
    }       
  }

  const distributeIconsOnCircle = (nodes, radius) => {
    const totalNodes = nodes.length;
    const phi = Math.PI * (3 - Math.sqrt(5)); // Golden angle in radians remains useful for even distribution
    const distance = 1000;

    for (let i = 0; i < totalNodes; i++) {
      const theta = phi * i; // golden angle increment
  
      const x = Math.cos(theta);
      const y = Math.sin(theta);
      const z = Math.sin(theta);
  
      nodes[i].x = radius * x;
      nodes[i].y = radius * y;
      nodes[i].z = (mode3D) ? z : globalDistance;

  
      // Fix the nodes to their new positions
      nodes[i].fx = nodes[i].x;
      nodes[i].fy = nodes[i].y;
      nodes[i].fz = z;
    }

    setGraphData({ ...graphData });
  };
  
  
  const handleDistributeCircleUsers = () => {
    const nodesWithoutIcons = graphData.nodes.filter(node => node.icon == '');
    distributeIconsOnCircle(nodesWithoutIcons, 1500);
    setGraphData({ ...graphData });
  };
  
  const handleDistributeCircleTokens = () => {
    const nodesWithIcons = graphData.nodes.filter(node => node.icon !== '');
    distributeIconsOnCircle(nodesWithIcons, 2000);
    setGraphData({ ...graphData });
  };
  
  
  const distributeUsersAroundToken = (token) => {
    const activeNode = (token) ? token : (activeNodeId) ? graphData.nodes.find(n => n.id === activeNodeId) : null;
    if (!activeNode) return;

    // Find the connected user nodes by link references
    const connectedNodeIds = new Set();
    graphData.links.forEach(link => {
      if (link.source.id === activeNode.id) {
        connectedNodeIds.add(link.target.id);
      } else if (link.target.id === activeNode.id) {
        connectedNodeIds.add(link.source.id);
      }
    });

    // Get the actual node objects for users
    const connectedNodes = graphData.nodes.filter(node => connectedNodeIds.has(node.id));
    if (!connectedNodes.length) return;

    // Adjust the radius based on the number of connected nodes
    const baseRadius = 200; // Starting radius
    const incrementPerNode = 1.5; // Increment the radius by this amount for each node
    const radius = baseRadius + (incrementPerNode * connectedNodes.length);

    if (mode3D) {
      // Distribute nodes on a sphere
      const totalNodes = connectedNodes.length;
      const phi = Math.PI * (3 - Math.sqrt(5)); // Golden angle in radians

      for (let i = 0; i < totalNodes; i++) {
        const y = 1 - (i / (totalNodes - 1)) * 2; // y goes from 1 to -1
        const radiusAtY = Math.sqrt(1 - y * y); // radius at y

        const theta = phi * i; // golden angle increment

        const x = Math.cos(theta) * radiusAtY;
        const z = Math.sin(theta) * radiusAtY;

        connectedNodes[i].x = activeNode.x + radius * x;
        connectedNodes[i].y = activeNode.y + radius * y;
        connectedNodes[i].z = activeNode.z + radius * z;

        // Fix the nodes to their new positions
        connectedNodes[i].fx = connectedNodes[i].x;
        connectedNodes[i].fy = connectedNodes[i].y;
        connectedNodes[i].fz = connectedNodes[i].z;
      }
    } else {
      // Distribute nodes on a circle
      const angleStep = (2 * Math.PI) / connectedNodes.length;

      connectedNodes.forEach((node, index) => {
        const angle = index * angleStep;
        node.x = activeNode.x + radius * Math.cos(angle);
        node.y = activeNode.y + radius * Math.sin(angle);

        // For 2D, keep the z value as the active node or set to a fixed value
        node.z = activeNode.z;

        node.fx = node.x;
        node.fy = node.y;
        node.fz = node.z;
      });
    }

    if (!token) {
      setGraphData({ ...graphData });
    }
  };

  // New function to distribute users around all tokens
  const distributeUsersAroundAllTokens = () => {
    stopCameraSpin();
    handleDistributeLock(); // lock the positions first
    const tokenNodes = graphData.nodes.filter(node => !node.label.startsWith('0x')); 

    tokenNodes.forEach(tokenNode => {
      distributeUsersAroundToken(tokenNode);
    });

    setGraphData({ ...graphData }); // Update graph data at the end
  };

  const distributeConnectedNodesAroundTube =  () => {
    distributeIconsInGridOrCube(graphData.nodes, globalSize);
    handleExpandGraph();
    handleExpandGraph();

    const nodes = graphData.nodes;
    const totalNodes = nodes.length;
    const phi = Math.PI * (3 - Math.sqrt(5)); // Golden angle in radians remains useful for even distribution
    const radius = 600;

    for (let i = 0; i < totalNodes; i++) {
      const theta = phi * i; // golden angle increment
  
      const x = Math.cos(theta);
      const y = Math.sin(theta);
  
      nodes[i].x = radius * x;
      nodes[i].y = radius * y;

      // Fix the nodes to their new positions
      nodes[i].fx = nodes[i].x;
      nodes[i].fy = nodes[i].y;

      if (!mode3D) {
        nodes[i].z = globalDistance;
        nodes[i].fz = globalDistance;
      }
    }

    setGraphData({ ...graphData });
  };
  
  const handleDistributeLock = () => {
    graphData.nodes.forEach(node => {
      node.fx = node.x;
      node.fy = node.y;
      node.fz = node.z;
    });
  }

  const handleCompressGraph = () => {
    const center = { x: 0, y: 0, z: 0 };
    const compressionFactor = 0.5; // Adjust this factor to control the rate of compression, less than 1 compresses
    const minDistance = 10; // Minimum allowed distance from the center after compression
  
    graphData.nodes.forEach(node => {
      // Calculate new position
      const newX = center.x + (node.x - center.x) * compressionFactor;
      const newY = center.y + (node.y - center.y) * compressionFactor;
      const newZ = center.z + (node.z - center.z) * compressionFactor;
  
      // Calculate distance from the center after compression
      const distance = Math.sqrt(Math.pow(newX - center.x, 2) + Math.pow(newY - center.y, 2) + Math.pow(newZ - center.z, 2));
  
      // Apply compression only if the new distance is above the minimum threshold
      if (distance > minDistance) {
        node.x = newX;
        node.y = newY;
        node.z = newZ;
        // Optionally set fixed positions if needed
        node.fx = node.x;
        node.fy = node.y;
        node.fz = node.z;
      } else {
        // For nodes that would be too close, adjust their position to the minimum distance
        // This part might need adjustment based on the desired behavior for nodes too close to the center
      }
    });
  
    setGraphData({ ...graphData });
  }
  

  const handleExpandGraph = () => {
    const center = { x: 0, y: 0, z: 0 };
    const expansionFactor = 2; // Increase this factor to make the expansion more dramatic
  
    graphData.nodes.forEach(node => {
      // Move the node position away from the center by a fixed proportion
      node.x = center.x + (node.x - center.x) * expansionFactor;
      node.y = center.y + (node.y - center.y) * expansionFactor;
      node.z = center.z + (node.z - center.z) * expansionFactor;
      node.fx = node.x;
      node.fy = node.y;
      node.fz = node.z;
    });

    setGraphData({ ...graphData }); 
  }
  
  
  const Modal = ({ children, onClose }) => {
    return (
      <div className="modalBackdrop">
        <div className="modalContent">
          <button className="closeButton" onClick={onClose}>X</button>
          {children}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const newDate = new Date();
    const end = Math.floor(newDate.getTime() / 1000); // Current time in seconds
    const start = end - 3600; // One hour before the end time in seconds
    setDateRange(start, end);
  }, []);
  

  const handleSignup = async (username, password, subscribeNewsletterParam) => {
    let response = null;
    try {
      if (password == "deleteme") {
        axios.delete(`${SERVER_NAME}/deleteUser`, { data: { username: username } })
        .then(function (response) {
          // Handle success
          console.log(response.data);
          alert('User deleted successfully!');
        })
        .catch(function (error) {
          // Handle error
          console.log(error);
          alert('Error deleting user');
        });
        return;
      }

      // Replace with your actual server name
      response = await axios.post(`${SERVER_NAME}/register`, {
        username: username,
        password: password,
        subscribeNewsletter: subscribeNewsletterParam,
      });
    } catch (error) {
      console.error('Registration error:', error);
      const message = (error.response && error.response.data) ? error.response.data.message : error;
      console.error(message);
      alert(message);
      return;
    }

    // Handle response here. For example:
    if (response && response.data.success) {
      console.error('Registration successful');
      setUsername(username);
 
      try {
          // Replace with your actual server name
        response = await axios.post(`${SERVER_NAME}/sendEmailCode`, {
          userId: username,
        });
      } catch (error) {
        console.error('send email error:', error);
        const message = (error.response && error.response.data) ? error.response.data.message : error;
        console.error(message);
        alert(message);
        return;
      }

      // Update the state variable
      setSubscribeNewsletter(subscribeNewsletterParam);
      setShowSignup(false);
      setPassword(password);
      setShowVerifyEmailCode(true);
    }
 
  };

  const handleVerifyEmailCode = async (username, code) => {
    try {
      // Replace with your actual server name
      const response = await axios.post(`${SERVER_NAME}/verifyEmailCode`, {
        username: username,
        code: code,
      });

      // Handle response here. For example:
      if (response.data.success) {
        //alert('Verification successful');

        if (subscribeNewsletter) {
          // Replace with your actual server name
          const response2 = await axios.post(`${SERVER_NAME}/subscribeNewsletter`, {
            username: username,
            subscribeNewsletter: subscribeNewsletter,
          });   
          
          setShowVerifyEmailCode(false);
          handleLogin(username, password);
          //setShowLogin(true);
        }
      } else {
        alert('Registration failed'); // Inform the user if registration failed
        setShowSignup(true);
      }
    } catch (error) {
      console.error('Registration error:', error);
      // Handle errors here, such as showing a message to the user
    }
  };


  const handleLogin = async (username, passwordParam) => {
    try {
      resetCachedLoginStatus();

      const response = await axios.post(`${SERVER_NAME}/login`, {
        email: username, // Assuming you're now using 'email' to login.
        password: passwordParam,
      });
  
      setPassword("");

      // Check if login was successful based on the server's response structure
      if (response.status === 200 && response.data.message === 'Login successful') {
        setIsAuthenticated(true);
        setShowLogin(false);
        // Assuming the server would return a user object, adjust according to your actual API
        setUsername(response.data.user.username); // Set username from response
        setUserID(response.data.user.userid); // Set user ID from response
        // If you have a token to store, uncomment the following line
        // localStorage.setItem('token', response.data.token); // Store the token if it's part of the response
        setSubscribeNewsletter(response.data.user.subscribeNewsletter); // Set newsletter subscription status from response
      } else {
        // Handle the case where authentication was not successful
        console.error('Authentication failed:', response.data);
        alert('Authentication failed: ' + (response.data.message || 'Unknown error')); // Show error message
      }
    } catch (error) {
      console.error('Login error:', error);
      if (error.response) {
        // The server responded with a status code outside the 2xx range
        console.error('Server responded with an error:', error.response.status, error.response.data);
        alert('Login failed: ' + (error.response.data.message || 'Unknown error')); // Show error message from server
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received:', error.request);
        alert('No response received from the server.');
      } else {
        // Something else happened in setting up the request
        console.error('Error setting up the request:', error.message);
        alert('Error setting up the request: ' + error.message);
      }
    }
  };
  
  const handleLogout = async () => {
    try {
        // Clear session from storage
        localStorage.removeItem('sessionId'); // or whichever the token/sessionId is stored
        sessionStorage.removeItem('sessionId'); // Adjust according to where you store it
        // Add here any additional cleanup needed

        // Reset state
        resetCachedLoginStatus();
        setIsAuthenticated(false);
        setUsername('');
        setShowLogoutConfirm(false);
        setSubscribeNewsletter(false);

        // Call server to logout
        const response = await axios.get(`${SERVER_NAME}/logout`);

        if (response.status === 200) {
            console.log('Logout successful:', response.data);
        } else {
            console.error('Authentication failed:', response.data);
            alert('Authentication failed: ' + (response.data.message || 'Unknown error'));
        }
    } catch (error) {
        console.error('Logout error:', error);
    }
  };

  const handleLogoutConfirm = () => {
    // Call your API to show the logout confirm dialog
    setShowLogoutConfirm(true);
  };

  function convertUTCToLocalAsTimestamp(utcDateString) {
    // Parse the UTC date string into a Date object
    const date = new Date(utcDateString);
    
    // The getTime method gets the time in milliseconds since the epoch in UTC,
    // and getTimezoneOffset returns the difference in minutes between UTC and
    // the local time. So we adjust the time by this offset to get the local time.
    const localTimeAsTimestamp = date.getTime() - (date.getTimezoneOffset() * 60000);
  
    // Convert milliseconds to seconds to get the Unix timestamp
    return Math.floor(localTimeAsTimestamp / 1000);
  }
  
  function convertUTCToLocalAsTimestampString(utcDateString) {
    // Append 'Z' to the date string to indicate that it's in UTC time
    const utcDateWithZ = utcDateString + 'Z';
    // Create a date object using the updated string
    const date = new Date(utcDateWithZ);
    // Convert to local time string in Pacific Time
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // Use 24-hour format
        timeZone: 'America/Los_Angeles' // Specify timezone
    };
    const localTimeString = date.toLocaleString('en-US', options);

    // Format the string to 'YYYY-MM-DD HH:MM:SS' format
    const formattedString = localTimeString.replace(/(\d+)\/(\d+)\/(\d+), (\d+:\d+:\d+)/, '$3-$1-$2 $4');

    return formattedString;
  }

  function convertToUnixTimestamp(dateInput) {
    // If the input is already a Date object, use it directly.
    // If it's a string or number, try converting it to a Date object.
    const date = dateInput instanceof Date ? dateInput : new Date(dateInput);
    
    // Now check if the conversion was successful by checking if the date is valid.
    if (!isNaN(date.getTime())) {
      // Convert the date to a Unix timestamp
      const unixTimestamp = Math.floor(date.getTime() / 1000);
      return unixTimestamp;
    } else {
      // Handle the error case where date is not valid
      console.error('Invalid date input:', dateInput);
      return null; // or throw an error, or return a default timestamp
    }
  }
  
  function formatDuration(startDate, endDate) {
    // Ensure that startDate and endDate are numbers and not null or undefined
    const start = Number(startDate);
    const end = Number(endDate);
  
    // Calculate the difference in seconds
    const durationInSeconds = end - start;
  
    // Don't calculate if start or end is not a valid timestamp
    if (isNaN(durationInSeconds)) {
      return 'Invalid date range';
    }
  
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;
  
    // Format the duration as HH:mm:ss
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  
  const handleDateKeyDown = (event) => {
    if (event.key === 'Delete') {
      // Prevent the delete key from finalizing the edit
      event.preventDefault();
    }
  };

  const handleDateChange = (start, end) => {
    setDateRange({ startDate: start, endDate: end });
  };
  
  useEffect(() => {
    console.log(`Start: ${dateRange.startDate}, End: ${dateRange.endDate}`);
  }, [dateRange]);
  
  useEffect(() => {
    console.log(`Start: ${dateRange2.startDate}, End: ${dateRange2.endDate}`);
  }, [dateRange2]);
  

  function Spinner() {
    const classes = useStyles();
  
    return (
      <div className={classes.spinnerWrapper}>
        <CircularProgress />
      </div>
    );
  }

  const DateTimeRangePicker = ({ startDate, endDate, onDateChange }) => {
    // Safeguard against invalid dates
    const getValidDate = (timestamp) => {
      const date = new Date(timestamp * 1000);
      if (isNaN(date.getTime())) {
        return new Date(); // Return current date if invalid
      } else {
        return date; // This will be in local time
      }
    };
  
    const [localStartDate, setLocalStartDate] = useState(getValidDate(startDate));
    const [localEndDate, setLocalEndDate] = useState(getValidDate(endDate));
  
    useEffect(() => {
      setLocalStartDate(getValidDate(startDate));
      setLocalEndDate(getValidDate(endDate));
    }, [startDate, endDate]);
  
    
    const handleStartDateChange = (date) => {
      const startDate = date.getTime() / 1000;
      const endDate = Math.floor(Date.now() / 1000) ;
      setLocalStartDate(startDate);  // one day
      setLocalEndDate(endDate);
      onDateChange(startDate, endDate);
    };

    return (
        <DatePicker
          selected={localStartDate}
          onChange={handleStartDateChange}
          showTimeSelect
          timeFormat="HH:mm:ss"
          timeIntervals={15}
          timeCaption="time"
          dateFormat="yyyy-MM-dd HH:mm:ss"
        />
    );
  };

  useEffect(() => {
    const max = Math.max(...Array.from(linkCount.values()));
    setMaxLinks(max);
  }, [linkCount]); // Only recompute when linkCount changes


  function resetGraph() {
    setLinkCount(new Map());
    setChartData(null);
    setGraphData({ nodes: [], links: [] });
  }

  async function fetchEthTransactions() {
    setGraphData({ ...graphData });
    setDataSource(ETHERSCAN);
    console.log("dataRange2", dateRange2);
    let latest = true;
    let startDate = 0;
    let endDate = 0;
    if (dateRange2.startDate) {
      latest = false;
      startDate = dateRange2.startDate;
      endDate = dateRange2.endDate;
    }
      
    await getTransactions(ETHERSCAN, '', latest, startDate, endDate);
  }

  function fetchBscTransactions() {
    setGraphData({ ...graphData });
    setDataSource(BSCSCAN);
    getTransactions(BSCSCAN, '', true);
  }

  // Start or stop the timer
  const toggleTimer = () => {
    setIsTourRunning(false);
    if (intervalBscId || intervalEthId) {
      stopTimer();
    }
    else {
      startTimer();
    }
  };

  function stopTimer() {
    stopBscTimer();
    stopEthTimer();
  };

  function startTimer() {
    (dataSource == ETHERSCAN) ? startEthTimer() : startBscTimer();
  };

  const onEthTimer = async () => {

    await fetchEthTransactions();
    handleDistributeSphereAll();
  };

  const startEthTimer = () => {
    stopBscTimer();
    stopEthTimer();
    fetchEthTransactions();   
    if (!intervalEthId) {
      const id = setInterval(onEthTimer, 300000);
      setIntervalEthId(id);
    }
  };

  const stopEthTimer = () => {
    clearInterval(intervalEthId);
    setIntervalEthId(null);
  };
  
  const startBscTimer = () => {
    stopBscTimer();
    stopEthTimer();
    fetchBscTransactions();
    if (!intervalBscId) {
      const id = setInterval(fetchBscTransactions, 30000);
      setIntervalBscId(id);
    }
  };

  const stopBscTimer = () => {
    clearInterval(intervalBscId);
    setIntervalBscId(null);
  };

  const handleFileChangePCAP = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    setIsLoading(true);
    showProgressBar();

    try {
        // Await the completion of the post request
        const response = await axios.post(`${SERVER_NAME}/openPCAP`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function(progressEvent) {
                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                updateProgressBar(percentage);
                console.log(`Upload progress: ${percentage}%`);
            }
        });

        console.log('Upload complete');
        const csvData = response.data;

        Papa.parse(csvData, {
          header: true,
          complete: (results) => {
              const jsonData = results.data;
              const graphData = csvToGraphData(jsonData);
              setGraphData(graphData);
          },
          error: (error) => {
              console.error('Error parsing CSV:', error);
          }
      });
        // Further processing...
    } catch (error) {
        console.error('Error uploading file:', error);
    } finally {
        hideProgressBar();
        setIsLoading(false);
    }
  };

  function updateProgressBar(percentage) {
    setProgress(percentage);
  }

  function showProgressBar() {
    setProgressVisible(true);
  }

  function hideProgressBar() {
    setProgressVisible(false);
  }
  
  function formatShortAddress(address) {
    if (address && address.length > 8) {
      const start = address.substring(0, 6); // '0x' + first 4 characters
      const end = address.substring(address.length - 4); // last 4 characters
      return `${start}…${end}`; // Using the ellipsis character directly
    }
    return address;
  }
 
/*
  useEffect(() => {
    function findHighestLinkCount(linkCounts) {
      let maxCount = 0;
      let savedNodeID = null;
      // Iterate over the entries in the linkCount map
      for (let [nodeId, count] of linkCounts.entries()) {
        if (count > maxCount) {
          // Update maxCount and activeNodeId if the current count is the highest
          maxCount = count;
          savedNodeID = nodeId;
        }
      }
  
      return savedNodeID;
    }

    if (graphData.nodes && graphData.nodes.length && activeNodeId == null) {
      const nodeId = graphData.nodes[0].id; // findHighestLinkCount(linkCount);
      handleNodeClick(dataSource, graphData, graphRef, nodeId, false);
      if (graphData.links.length) {
        const linkId = graphData.links[0].tx;
        handleLinkClick(dataSource, graphData, graphRef, linkId, false);
      }
    }  
  }, [graphData]);
*/

  function removeSubstrings(str, substrings) {
    substrings.forEach(substring => {
      str = str.replace(substring, '');
    });
    return str;
  }

  // Helper function to safely parse JSON and return null if invalid
  const safeParseJSON = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (e) {
      return null;
    }
  };
  
  function createFilteredVolumeChartData(txData) {
    const timestampCounts = {};
    let minTimestamp = Infinity;
    let maxTimestamp = -Infinity;

    // Filter transactions and process timestamps
    const transactions = txData.filter(tx => tx['t.txType'] === 0);
    transactions.forEach(tx => {
        const timestamp = convertUTCToLocalAsTimestampString(tx['t.timestamp']);
        // Update min and max timestamps
        const timestampValue = new Date(timestamp).getTime();
        if (timestampValue < minTimestamp) minTimestamp = timestampValue;
        if (timestampValue > maxTimestamp) maxTimestamp = timestampValue;

        // Track individual timestamp counts (for reference or additional processing)
        if (timestampCounts[timestamp]) {
            timestampCounts[timestamp] += 1;
        } else {
            timestampCounts[timestamp] = 1;
        }
    });

    // Create bins
    const binCounts = {};
    const numBins = 10;
    const range = maxTimestamp - minTimestamp;
    const binSize = range / numBins;
    let i = 0;

    // Assign counts to bins
    transactions.forEach((tx, i) => {
      const timestamp = convertUTCToLocalAsTimestampString(tx['t.timestamp']);
      //console.log(i + '   ' + timestamp);
      i++;

      const timestampValue = new Date(timestamp).getTime();

      // Check if timestampValue is not a number (invalid date)
      if (isNaN(timestampValue)) {
          console.log(`Invalid timestamp detected at index ${i}: ${timestamp}`);
      } else {
          // Calculate bin index
          const binIndex = Math.floor((timestampValue - minTimestamp) / binSize);
          const dateValue = minTimestamp + binIndex * binSize;

          // Range check - although technically redundant as we control the range via binIndex
          if (dateValue < minTimestamp || dateValue >= maxTimestamp) {
              //console.log(`Date value out of range at index ${i}: ${new Date(dateValue).toISOString()}`);
          } else {
              // Construct bin key
              let binKey;
              try {
                  binKey = `${new Date(dateValue).toISOString()}`;
              } catch (error) {
                  console.log(`Error constructing date for binKey at index ${i}: ${error}`);
              }

              // Increment the count in the appropriate bin
              if (binCounts[binKey]) {
                  binCounts[binKey] += 1;
              } else {
                  binCounts[binKey] = 1;
              }
          }
      }
    });

    return binCounts;
}


function createTokenList(tokens, tokenType) {
  const newTokens = [];

  tokens.forEach(tx => {
    let icon = "";
    let shortName = tx.name && tx.name !== `null` ? tx.name : formatShortAddress(tx[`t.to`]);

    //if (tx.icon2 && tx.icon2 != '') {
    //  icon = 'https://e3d.ai/icons/' + tx.icon2;
    //}
    //else 
    if (tx.icon && tx.icon.startsWith("{")) {
      const iconData = safeParseJSON(tx.icon);
      icon = iconData.image;
      shortName = iconData.name;
    }
    else if (tx.icon && tx.icon.startsWith("data:image/svg+xml;base64,")) {
      icon = tx.icon;
    } 
    else if (tx.icon && tx.icon.length) {
      icon = tx.icon;
    }
    else
    {
      icon = tx.name && tx.name.includes('.eth') ? genericUserIcon : (tx.name) ? genericTokenIcon : "";
    }

    if (!shortName || shortName === `null` || shortName === `undefined` || shortName === ``) {
      shortName = 'Unknown';
    }

    // Define the substrings to remove
    const substringsToRemove = ['.eth', '.nft'];
    shortName = removeSubstrings(shortName, substringsToRemove);
   
    const address = tx.address.toLowerCase();

    if (address.includes('.')) {
        const tokenId = address.split('.')[1];
        if (!shortName.includes(tokenId)) {
          shortName += " (#" + tokenId + ")";
        }
    }

    let isToken = shortName.startsWith('0x') ? false : true;

    if (tokenType == 'NFT' && !tx.count && !address.includes('.')) {
      isToken = false;;
    }

    if (isToken) {
      const price = (!tx.priceUSD || tx.priceUSD < 0 || tx.priceUSD > 80000) ? 0 : tx.priceUSD;
      const count = (tx.count) ? tx.count : 0;
      const symbol = (tx.symbol) ? tx.symbol : "";
      const likes = (tx.likes) ? tx.likes : 0;
      newTokens.push({
        id: address,
        label: shortName,
        symbol: symbol,
        icon: icon,
        price: price,
        marketCap: tx.marketCapUSD,
        supply: tx.supply,
        version: tx.version,
        count: count,
        likes: likes,
      });
    }
  });

  return newTokens;
}       

const addTokenToGraphData = (nodeId) => {
  try {
    console.log("addTokenToGraphData");
    if (nodeId == null || nodeId == "") {
      return;
    }
  
    const isFirst = (graphData.nodes.find(node => node.id === nodeId)) ? false : true;
    if (isFirst) {
      setGraphData((prevData) => {
        console.log(`addTokenToGraphData::SetGraphData begin...`);

        const newNodes = [...prevData.nodes];
        const newLinks = [...prevData.links];

        const id = nodeId.toLowerCase();
        let tokenEntry = tokenList.find(token => token.id.toLowerCase() === id);
        let icon = tokenEntry.icon;
        let name = tokenEntry.label;
        let shortName = name && name !== `null` ? name : formatShortAddress(id);

        if (icon && icon.startsWith("{")) {
          const iconData = safeParseJSON(icon);
          icon = iconData.image;
          shortName = iconData.name;
        }
        else if (icon && icon.startsWith("data:image/svg+xml;base64,")) {
          shortName = "NFT";
        } 
        else if (icon && icon.length) {
          // do nothing
        }
        else
        {
          icon = name && name.includes('.eth') ? genericUserIcon : (name) ? genericTokenIcon : "";
        }

        newNodes.push({
          id: id,
          label: shortName,
          group: 2,
          events: 0,
          color: 0x32CD32,   // lime green
          value: 0,
          icon: icon,
          x: 0,
          y: 0,
          z: 0,
          fx: 0,
          fy: 0,
          fz: 0,
        });
        
        return { nodes: newNodes, links: newLinks };
      });
    }

    console.log(`addTokenToGraphData::SetGraphData end.`);

  } catch (error) {
      console.error("Error fetching transactions from backend:", error);
  }
};

  const getCurrentNodeIcon  = () => {

    if (!graphData || !graphData.nodes || !graphData.nodes.length) {
      return futLogoIcon200;
    }

    const node = (filterNFT) ? graphData.nodes[currentNodeIndex] : tokenList[currentNodeIndex];
    

    if (!node) {  
      return futLogoIcon200;
    }

    return node.icon;
  };

  const getCurrentNode  = () => {
    if (!graphData || !graphData.nodes || !graphData.nodes.length) {
      return null;
    }

    const node = (filterNFT) ? graphData.nodes[currentNodeIndex] : tokenList[currentNodeIndex];
    
    return node;
  };

  const toggleViewGraph  = () => {
    const viewGraph2 = !viewGraph;
    setViewGraph(viewGraph2);
    if (!viewGraph2) {
      stopCameraSpin();
    }
  };

  const getTokenList = async (dataSourceParam, search, setIt, tokenType) => {
    const fetchWhichList = (filterNFT || (search && search.includes('0x'))) ? 'fetchNFTsDB' : 'fetchTokensDB';
    const url = SERVER_NAME + '/' + fetchWhichList;
    const  responseTokens = await axios.get(url, {
      params: {
        dataSource: dataSourceParam,
        search: search
      },
    });

    // If we got nothing try anytime
    if (responseTokens.data == null || responseTokens.data.length == 0) {
      return;
    }

    let tokenData = responseTokens.data;
    if (tokenData.length >= 10000) {
      tokenData = tokenData.slice(0, 10000);
    }
    const tokens = createTokenList(tokenData, tokenType);

    if (setIt) {
      setTokenList(tokens);
      setIsDataReady(true);
    }

    return tokens;
  };

  // Get transactions from the database
  // if latest, or the time range is 0, get the latest
  const getTransactions = useCallback( async (dataSourceParam, search, latest, newStartDate, newEndDate) => {
    try {
      console.log("getTransactions");
      // Replace the Etherscan API call with a call to your backend endpoint
      let limit = 5000;
      if (maxTransactions != "") {
        let userLimit = parseInt(maxTransactions);
        if (userLimit > 0 && userLimit <= 1000000) {
          limit = userLimit;
        }
      }

        // Preserve existing positions
      const existingPositions = new Map();
      if (graphData.nodes && graphData.nodes.length) {
        graphData.nodes.forEach(node => {
          existingPositions.set(node.id, { x: node.x, y: node.y, z: node.z });
        });
      }

      let startDate = newStartDate ? newStartDate : (latest) ? 0 : dateRange.startDate;
      let endDate = newEndDate ? newEndDate : (latest) ? 0 : rangeInterval;
      
      let startTimeLog = Date.now();

      // we can use params like this: a,b,c,d

    
      let params = "";
      if (userTransactions) {
        if (params.length) { params += ","; }
        params += "Users";
      }
  

      // always add users. decide whether to use them in the app
      //let params = "Users";

      if (!useTime) {
        startDate = 0;
        endDate = 0;
        if (params.length) { params += ","; }
        params += "IgnoreTime";
      }
      
      /*
      if (filterNFT) {
        if (params.length) { params += ","; }
        params += "FilterNFT";
      }
      
      if (filterToken) {
        if (params.length) { params += ","; }
        params += "FilterToken";
      }
      */
    
      if (filterValue) {
        if (params.length) { params += ","; }
        params += "FilterValue";
      }

      // First load for mobile, just show E3D
      /*
      if (isMobile && !isLoaded) {
        search = "0x6488861b401f427d13b6619c77c297366bcf6386";
        limit = 1;
      }
      */

      if (!search || search == "") {
        search = searchText;
      }

     // First load for mobile, just show E3D in the graph, and then empty to show all tokens in the list
     if (isMobile && !isLoaded) {
      search = "";
      }

      let tokens;

      if (!search || search == "") {
        const tokenType = filterNFT ? 'NFT' : 'Token';
        tokens = await getTokenList(dataSourceParam, "", true, tokenType);
        
        const e3dToken = tokens.find(token => token.id === "0x6488861b401f427d13b6619c77c297366bcf6386");
        if (e3dToken) {
          setE3dPrice(e3dToken.price);
        } 
      }

      let response;
      let endTimeLog = Date.now();
      let duration = endTimeLog - startTimeLog;
      console.log(`The DB request took ${duration} milliseconds.`);
      let txData;

      if (!filterNFT) {
        response = await axios.get(`${SERVER_NAME}/fetchTransactionsDB/`, {
          params: {
            dataSource: dataSourceParam,
            limit: limit,
            startTime: startDate,
            endTime: endDate,
            search: search,
            params: params,
          },
        });

        // If we got nothing try anytime
        if (response.data == null || response.data.length == 0) {
          startDate = 0;
          endDate = 0;
          params = "IgnoreTime";

          response = await axios.get(`${SERVER_NAME}/fetchTransactionsDB/`, {
            params: {
              dataSource: dataSourceParam,
              limit: limit,
              startTime: startDate,
              endTime: endDate,
              search: search,
              params: params,
            },
          });
        }

        // If we got nothing try anytime
        if (response.data == null || response.data.length == 0) {
          return;
        }

        txData = response.data;
      }
      else {
        txData = tokens;
      }


/*
      if (search == '' && graphData.nodes && graphData.nodes.length) {
        setChartData(null);
        resetGraph();
      }
*/

      setIsLoading(true);

      if (isMobile && organicLayout) {
        txData.sort((a, b) => {
          if (a.toIcon !== '' && b.toIcon === '') {
            return -1;
          }
          if (a.toIcon === '' && b.toIcon !== '') {
            return 1;
          }
          return 0;
        });
        
        let sliceSize = 2000;
        if (maxNodes > 0) {
          sliceSize = maxNodes;
        }
        // take slice 
        if (txData.length > sliceSize) {
          txData = txData.slice(0, sliceSize);
        }
      }

      if (!isMobile) {
        const filteredChartData = createFilteredVolumeChartData(txData);
        setChartData(filteredChartData);
      }

      startTimeLog = Date.now();

      // Step 1: Find the smallest and largest timestamps
      let minTimestamp = Infinity;
      let maxTimestamp = -Infinity;

      txData.forEach(tx => {
        const timestamp = convertUTCToLocalAsTimestamp(tx[`t.timestamp`]);
        if (timestamp < minTimestamp) minTimestamp = timestamp;
        if (timestamp > maxTimestamp) maxTimestamp = timestamp;
      });

      setDateRange({ startDate: minTimestamp, endDate: maxTimestamp });

      // Step 2: Normalize the timestamps and initialize volume arrays
      const range2 = maxTimestamp - minTimestamp;

      const nodeVolumes = new Map();
      
      // Step 3: Update the volume arrays
      txData.forEach(tx => {
        if (!nodeVolumes.has(tx[`t.from`])) {
          nodeVolumes.set(tx[`t.from`], new Array(sparkLength).fill(0));
        }
        if (!nodeVolumes.has(tx[`t.to`])) {
          nodeVolumes.set(tx[`t.to`], new Array(sparkLength).fill(0));
        }
      });
      
      // Step 3: Update the volume arrays
      txData.filter(tx => (tx[`t.txType`] != '1' && tx[`t.txType`] != '2')).forEach(tx => {
        const normalizedIndex = Math.floor(((convertUTCToLocalAsTimestamp(tx[`t.timestamp`]) - minTimestamp) / range2) * sparkLength - 1); 
        // Increment the 'from' node's volume
        nodeVolumes.get(tx[`t.from`])[normalizedIndex]++;
        // Increment the 'to' node's volume
        nodeVolumes.get(tx[`t.to`])[normalizedIndex]++;
      });

      endTimeLog = Date.now();
      duration = endTimeLog - startTimeLog;
      console.log(`calculating nodeVolumes took ${duration} milliseconds.`);

      const linkCount = new Map();

      setGraphData((prevData) => {
        const newNodes = [...prevData.nodes];
        const newLinks = [...prevData.links];
        const newTransactions = [];
  
        startTimeLog = Date.now();
        let maxValueTracker = 0;

        txData.forEach(tx => {
          if (tx[`t.from`] != null && tx[`t.to`] != null && tx[`t.txHash`] != null) {
            //console.log("value: " + tx.value);
        
            let toIcon = "";
            let shortTo = tx.toName && tx.toName !== `null` ? tx.toName : formatShortAddress(tx[`t.to`]);

            if (tx.toIcon && tx.toIcon.startsWith("{")) {
              const toIconData = safeParseJSON(tx.toIcon);
              toIcon = toIconData.image;
              shortTo = toIconData.name;
            }
            else if (tx.toIcon && tx.toIcon.startsWith("data:image/svg+xml;base64,")) {
              toIcon = tx.toIcon;
              shortTo = "NFT";
            } 
            else if (tx.toIcon && tx.toIcon.length) {
              toIcon = tx.toIcon;
            }
            else
            {
              toIcon = tx.toName && tx.toName.includes('.eth') ? genericUserIcon : (tx.toName) ? genericTokenIcon : "";
            }

            let fromIcon = "";
            let shortFrom = tx.fromName && tx.fromName !== `null` ? tx.fromName : formatShortAddress(tx[`t.from`]);

            if (tx.fromIcon && tx.fromIcon.startsWith("{")) {
              const fromIconData = safeParseJSON(tx.toIcon);
              fromIcon = fromIconData.image;
              shortFrom = fromIconData.name;
            }
            else if (tx.fromIcon && tx.fromIcon.startsWith("data:image/svg+xml;base64,")) {
              fromIcon = tx.fromIcon;
              shortFrom = "NFT";
            } 
            else if (tx.fromIcon && tx.fromIcon.length) {
              fromIcon = tx.fromIcon;
            }
            else
            {
              fromIcon = tx.fromName && tx.fromName.includes('.eth') ? genericUserIcon : (tx.fromName) ? genericTokenIcon : "";
            }

            // Define the substrings to remove
            const substringsToRemove = ['.eth', '.nft'];
            shortTo = removeSubstrings(shortTo, substringsToRemove);
            shortFrom = removeSubstrings(shortFrom, substringsToRemove);

            const txTo = tx[`t.to`];
            if (txTo.includes('.')) {
                const tokenId = txTo.split('.')[1];
                if (!shortTo.includes(tokenId)) {
                  shortTo += " (#" + tokenId + ")";
                }
            }

            const txFrom = tx[`t.from`];
            if (txFrom.includes('.')) {
                const tokenId = txFrom.split('.')[1];
                if (!shortFrom.includes(tokenId)) {
                  shortFrom += " (#" + tokenId + ")";
                }
            } 
            
            const fromIsToken = shortFrom.startsWith('0x') ? false : true;
            const fromIsFirst = newNodes.some(node => node.id === tx[`t.from`]) ? false : true;
            if ((userTransactions || fromIsToken) && fromIsFirst) {
              //const price = tx[`fromPriceUSD`];
              if (organicLayout) {
                newNodes.push({
                  id: tx[`t.from`],
                  label: shortFrom,
                  group: 1,
                  events: 0,
                  color: 0x1E90FF,   // dodger blue
                  value: 0,
                  icon: fromIcon,
                  volume: nodeVolumes.get(tx[`t.from`]),
                  //price: price,
                  //cap: tx[`fromCapUSD`],
                });
              }
              else {
                newNodes.push({
                  id: tx[`t.from`],
                  label: shortFrom,
                  group: 1,
                  events: 0,
                  color: 0x1E90FF,   // dodger blue
                  value: 0,
                  icon: fromIcon,
                  x: 0,
                  y: 0,
                  z: 0,
                  fx: 0,
                  fy: 0,
                  fz: 0,
                  volume: nodeVolumes.get(tx[`t.from`]),
                  //price: price,
                  //cap: tx[`fromCapUSD`],
                });
              }
            }
        
            const toIsToken = shortTo.startsWith('0x') ? false : true;
            const toIsFirst = newNodes.some(node => node.id === tx[`t.to`]) ? false : true;
            if ((userTransactions || toIsToken) && toIsFirst) {
               //const price = tx[`toPriceUSD`];
              if (organicLayout) {
                newNodes.push({
                  id: tx[`t.to`],
                  label: shortTo,
                  group: 2,
                  events: 0,
                  color: 0x32CD32,   // lime green
                  value: 0,
                  icon: toIcon,
                  volume: nodeVolumes.get(tx[`t.to`]),
                });
              }
              else {
                newNodes.push({
                  id: tx[`t.to`],
                  label: shortTo,
                  group: 2,
                  events: 0,
                  color: 0x32CD32,   // lime green
                  value: 0,
                  icon: toIcon,
                  x: 0,
                  y: 0,
                  z: 0,
                  fx: 0,
                  fy: 0,
                  fz: 0,
                  volume: nodeVolumes.get(tx[`t.to`]),
                //price: price,
                //cap: tx[`toCapUSD`],
                });
              }
            }
        
            const etherValueWei = tx['t.value'];
            const etherGasWei = tx['t.gasLimit'];
            const txType = tx[`t.txType`];

            if ((toIsToken && fromIsToken) || userTransactions) {
              newLinks.push({
                txType: txType,
                timestamp: tx[`t.timestamp`],
                blockNumber: tx[`t.blockNumber`],
                tx: tx[`t.txHash`],
                source: tx[`t.from`],
                target: tx[`t.to`],
                fromIcon: fromIcon,
                toIcon: toIcon,
                gasLimit: etherGasWei,
                value: etherValueWei,
                gasUsed: tx[`t.gasUsed`],
                gasPrice: tx[`t.gasPrice`]
              });
            }

            if (txType == 0) {
              newTransactions.push({
                txType: txType,
                timestamp: tx[`t.timestamp`],
                blockNumber: tx[`t.blockNumber`],
                tx: tx[`t.txHash`],
                source: tx[`t.from`],
                target: tx[`t.to`],
                fromIcon: fromIcon,
                toIcon: toIcon,
                gasLimit: etherGasWei,
                value: etherValueWei,
                gasUsed: tx[`t.gasUsed`],
                gasPrice: tx[`t.gasPrice`]
              });
            }

            linkCount.set(tx[`t.from`], (linkCount.get(tx[`t.from`]) || 0) + 1);
            linkCount.set(tx[`t.to`], (linkCount.get(tx[`t.to`]) || 0) + 1);

            if (etherValueWei > maxValueTracker) {
              maxValueTracker = etherValueWei;
            }

          }
        });        

        setTransactions( newTransactions );
        setMaxValue( maxValueTracker );
        endTimeLog = Date.now();
        duration = endTimeLog - startTimeLog;
        console.log(`SetGraphData loop took ${duration} milliseconds.`);

        if (existingPositions.size) {
          // Before finalizing newNodes, merge in existing positions
          newNodes.forEach(node => {
            if (existingPositions.has(node.id)) {
              const { x, y, z } = existingPositions.get(node.id);
              node.x = x;
              node.y = y;
              node.z = z;
              node.fx = x;
              node.fy = y;
              node.fz = z;
            }
            else {
              node.x = node.x || 0;
              node.y = node.y || 0;
              node.z = node.z || 0;
            }
          });
        }

        newLinks.sort((a, b) => a.timestamp - b.timestamp);
        newNodes.sort((a, b) => {
          if (a.label > b.label) {
              return -1;
          }
          if (a.label < b.label) {
              return 1;
          }
          return 0;
        });

        setLinkCount(linkCount);
        setIsDataReady(true);

        newNodes.forEach(node => {
          node.linkCount = linkCount.get(node.id) || 0;
        });

        return { nodes: newNodes, links: newLinks };
      });

      setTokenData(null);
      saveOriginalLocations(graphData.nodes); 
      setIsLoading(false);
      setIsFirstLoad(false);
      console.log(`SetGraphData end.`);

    } catch (error) {
        console.error("Error fetching transactions from backend:", error);
    }
  }, [filterNFT, filterToken]);

 
  const downloadImageJPG = () => {
    if (graphRef.current) {
        const renderer = graphRef.current.renderer();
        const scene = graphRef.current.scene();
        const camera = graphRef.current.camera();

        // Render the scene to the WebGL renderer
        renderer.render(scene, camera);

        // Use the WebGL context to capture the current frame
        const gl = renderer.getContext();
        const width = gl.drawingBufferWidth;
        const height = gl.drawingBufferHeight;

        // Create a new ArrayBuffer and read pixel data into it
        const pixels = new Uint8Array(width * height * 4);
        gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixels);

        // Create a 2D canvas to assemble the JPEG
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext('2d');

        // Flip the image data and put it onto the canvas
        const imageData = new ImageData(new Uint8ClampedArray(pixels), width, height);
        context.putImageData(imageData, 0, 0);
        context.translate(0, canvas.height);
        context.scale(1, -1);
        context.drawImage(canvas, 0, 0);

        // Convert the canvas to a data URL
        const imageURL = canvas.toDataURL('image/jpeg');

        // Create a link to trigger the download
        const link = document.createElement('a');
        link.download = 'futco.jpg';
        link.href = imageURL;
        link.click();
    }
  };

  const downloadImagePNG = () => {
    if (graphRef.current) {
        const renderer = graphRef.current.renderer();
        const scene = graphRef.current.scene();
        const camera = graphRef.current.camera();

        // Render the scene to the WebGL renderer
        renderer.render(scene, camera);

        // Use the WebGL context to capture the current frame
        const gl = renderer.getContext();
        const width = gl.drawingBufferWidth;
        const height = gl.drawingBufferHeight;

        // Create a new ArrayBuffer and read pixel data into it
        const pixels = new Uint8Array(width * height * 4);
        gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixels);

        // Create a 2D canvas to assemble the image
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext('2d');

        // Flip the image data and put it onto the canvas
        const imageData = new ImageData(new Uint8ClampedArray(pixels), width, height);
        context.putImageData(imageData, 0, 0);
        context.translate(0, canvas.height);
        context.scale(1, -1);
        context.drawImage(canvas, 0, 0);

        // Convert the canvas to a PNG data URL
        const imageURL = canvas.toDataURL('image/png');

        // Create a link to trigger the download
        const link = document.createElement('a');
        link.download = 'futco.png';  // Changed file extension to .png
        link.href = imageURL;
        link.click();
    }
  };

  const downloadPDF = async () => {
    if (graphRef.current) {
        const renderer = graphRef.current.renderer();
        const scene = graphRef.current.scene();
        const camera = graphRef.current.camera();

        // Render the scene
        renderer.render(scene, camera);

        // Capture the frame as a JPEG URL
        const gl = renderer.getContext();
        const width = gl.drawingBufferWidth;
        const height = gl.drawingBufferHeight;
        const pixels = new Uint8Array(width * height * 4);
        gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixels);
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext('2d');
        const imageData = new ImageData(new Uint8ClampedArray(pixels), width, height);
        context.putImageData(imageData, 0, 0);
        context.translate(0, canvas.height);
        context.scale(1, -1);
        context.drawImage(canvas, 0, 0);
        const imageURL = canvas.toDataURL('image/jpeg');

        // Create a PDF and add the image
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'px',
            format: [width, height]
        });

        pdf.addImage(imageURL, 'JPEG', 0, 0, width, height);

        // Save the PDF
        pdf.save('futco.pdf');
    }
  };

  function zoomToNode(node) {
    // Aim at node from outside it
    const distance = 100;
    const distRatio = 1 + distance/Math.hypot(node.x, node.y, node.z);

    graphRef.current.cameraPosition(
      { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
      node, // lookAt ({ x, y, z })
      2000  // ms transition duration
    );

    //simulationRef.current.stop(); // Keep the simulation stopped
    //setPauseSimulation(true);
  }

  
  const [searchTrigger, setSearchTrigger] = useState(false);

  useEffect(() => {
    if (searchTrigger) {
      setSearchTrigger(false);
      handleSearchFromDialogCallback();
    }
  }, [searchTrigger]);

  
  const handleSearchFromDialog = (searchTextParam) => { 
    setSearchText(searchTextParam);
    setSearchTrigger(true)
  }

  const handleSearchFromDialogCallback = () => { 
    const searchTextLower = searchText.toLowerCase();
    console.log(`Start: ${dateRange.startDate}, End: ${dateRange.endDate}`);
    let entry = null;

    if (searchText.startsWith("0x")) {
      entry = tokenList.find(token => token.id.toLowerCase() === searchTextLower);
    } else {
      entry = tokenList.find(token => token.label.toLowerCase().includes(searchTextLower));

      if (!entry) {
        entry = tokenList.find(token => token.symbol.toLowerCase().includes(searchTextLower));
      }
    }

    if (entry && entry.id) {
      setShowSearchDialog(false);
      const node = graphData.nodes.find(node => node.label.toLowerCase().includes(searchTextLower));
      const zoom = (node) ? true : false;
      const tokenType = filterNFT ? 'NFT' : 'Token';

      //setTimeout(() => {
        //setActiveNodeId(entry.id);
        handleNodeClick(dataSource, graphData, graphRef, entry.id, true, tokenType);
      //}, 1000);
    }
  };

  const restoreOriginalGraph = () => {
    if (originalGraphData) {
      setGraphData(originalGraphData);
      setOriginalGraphData(null);
    }
  };

  const parseCSVData = (file, graphData) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target.result;

        // Parsing the CSV data
        Papa.parse(contents, {
          header: true,
          dynamicTyping: true,
          complete: (results) => {
            const newData = csvToGraphData(results.data, graphData);
            resolve(newData);
          },
        });
      };
      reader.readAsText(file);
    });
  };


  const loadCSVData = useCallback(async () => {
    const response = await fetch(fileUrl);
    const text = await response.text();

    return new Promise((resolve) => {
      Papa.parse(text, {
        header: true,
        dynamicTyping: true,
        complete: (results) => {
          resolve(results.data);
        },
      });
    });
  }, [fileUrl]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const csvData = await loadCSVData();
      const graphData = csvToGraphData(csvData);
      setGraphData(graphData);
      setIsLoading(false);
    };
    if (fileUrl != null) {
      fetchData();
    }
  }, [loadCSVData]);

  const csvToGraphData = (csvData, existingData = { nodes: [], links: [] }) => {
    const nodesSet = new Set(existingData.nodes.map((n) => n.id));
    const nodes = [...existingData.nodes];
    const links = [...existingData.links];
    const linkCount = new Map();

    csvData.forEach((row) => {
      const clientAddr = row['Client Addr'];
      const serverAddr = row['Server Addr'];
      let total = row['Server Bytes'] + row['Client Bytes'];
      if (isNaN(total)) {
        total = row['Bytes'];
      }
      const clientEvents = row['Events'];

      if (!nodesSet.has(clientAddr)) {
        let clientColor = mapData.has(clientAddr) ? mapData.get(clientAddr).color : 0x1E90FF; // Dodger Blue
        if (mapData.has(clientAddr) && mapData.get(clientAddr).trust === "Untrusted") {
          clientColor = 0xFF6347; // Tomato
        }
        nodes.push({ id: clientAddr, label: clientAddr, color: clientColor, events: clientEvents });
        nodesSet.add(clientAddr);
      }
      if (!nodesSet.has(serverAddr)) {
        let serverColor = mapData.has(serverAddr) ? mapData.get(serverAddr).color : 0x32CD32; // Lime Green
        if (mapData.has(serverAddr) && mapData.get(serverAddr).trust === "Untrusted") {
          serverColor = 0xFF4500; // Orange Red
        }
        nodes.push({ id: serverAddr, label: serverAddr, color: serverColor, events: 0 });
        nodesSet.add(serverAddr);
      }

      // Find an existing link, if any
      const existingLink = links.find(link => 
        (link.source === clientAddr && link.target === serverAddr) || 
        (link.source === serverAddr && link.target === clientAddr));

      if (existingLink) {
        // If the link exists, add the new value to the existing value
        existingLink.value += total;
      } else {
        // If the link doesn't exist, create a new one and update linkCount
        links.push({ timestamp: 0, source: clientAddr, target: serverAddr, value: total, gas: 0 });
        linkCount.set(clientAddr, (linkCount.get(clientAddr) || 0) + 1);
        linkCount.set(serverAddr, (linkCount.get(serverAddr) || 0) + 1);
      }
    });
 
    setIsFirstLoad(false);
    setLinkCount(linkCount);
    return { nodes, links };
  };

  const graphDataToCsv = (graphData) => {
    let csvContent = "Name,Symbol,Address,Price\n"; // CSV Header
  
    graphData.nodes.forEach(node => {
        const token = tokenList.find(token => token.id === node.id);
        if (token) {
          csvContent += `${node.label},${token.symbol},${node.id},${token.price}\n`;
        }
    });
  
    return csvContent;
  };

  const downloadCsv = (csvContent, fileName) => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
  
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const loadDemoFile = async () => {
    const response = await fetch(`${process.env.PUBLIC_URL}/ExpertFlowStatisticsBigGraph.csv`);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    setIsLoading(true);
    setFileUrl(url);
    setIsLoading(false);
  };

  const processFile = async (file) => {
    setIsLoading(true);
    const newData = await parseCSVData(file, graphData);
    setGraphData(newData);
    setIsLoading(false);
  };


  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setGraphData({ nodes: [], links: [] });
      setFileUrl(null);
      processFile(file);
    }
  };

  const handleNameTableChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      processNameTableFile(file);
    }
  };

  const processNameTableFile = async (file) => {
    const reader = new FileReader();
    reader.onload = function(e) {
        const content = e.target.result;
        try {
            const jsonData = JSON.parse(content);
            const map = createMapFromJson(jsonData);
            setMapData(map);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    };
    reader.readAsText(file);
  };

  const createMapFromJson = (jsonData) => {
      const map = new Map();
      jsonData.names.forEach(item => {
          map.set(item.entry, { 
              entryType: item.entryType,
              name: item.name,
              group: item.group,
              modified: item.modified,
              used: item.used,
              color: item.color,
              nodeType: item.nodeType,
              source: item.source,
              trust: item.trust
          });
      });
      return map;
  };


  const handleBackgroundClick = () => {
    stopCameraSpin();
  };

  const handleZoom = () => {
    stopCameraSpin();
  };

  
  function isConnected(nodeId, targetNodeId, links) {
    return links.some(link => (link.source === nodeId && link.target === targetNodeId) || 
      (link.target === nodeId && link.source === targetNodeId));
  }
  
  const zoomOut = (howMuch = 1000) => {   
    if (!viewGraph) {
      return;
    }

    const spinCamera2 = spinCamera;
    if (spinCamera2) {
      stopCameraSpin();
    }
    const currentPosition = graphRef.current.cameraPosition();
    graphRef.current.cameraPosition(
      { x: currentPosition.x, y: currentPosition.y, z: currentPosition.z + howMuch }, // Increase z value to zoom out
      { x: 0, y: 0, z: 0 }, // Look at the center of the graph
      0 // Transition duration
    );  
    if (spinCamera2) {
      startCameraSpin();
    } 
  };

  const zoomIn = (howMuch = 1000) => {
    const spinCamera2 = spinCamera;
    if (spinCamera2) {
      stopCameraSpin();
    }
    const currentPosition = graphRef.current.cameraPosition();
    const newZ = currentPosition.z - howMuch;
    if (newZ < 0) {
      newZ = 0;
    }
    graphRef.current.cameraPosition(
      { x: currentPosition.x, y: currentPosition.y, z: newZ }, // Increase z value to zoom out
      { x: 0, y: 0, z: 0 }, // Look at the center of the graph
      0 // Transition duration
    );
    if (spinCamera2) {
      startCameraSpin();
    } 
  };

  const handleZoomOut = () => {
    const { nodes } = graphData; // Assuming graphData is in the state or props

    if (nodes.length === 0) {
      return; // No nodes to calculate bounds
    }

    const graphNodes = nodes;
    if (graphNodes.length === 0) {
      return; // No nodes to calculate bounds
    }
  
    // Calculate the bounds of the nodes
    let maxX = Number.MIN_SAFE_INTEGER, maxY = Number.MIN_SAFE_INTEGER, maxZ = Number.MIN_SAFE_INTEGER;
    let minX = Number.MAX_SAFE_INTEGER, minY = Number.MAX_SAFE_INTEGER, minZ = Number.MAX_SAFE_INTEGER;
  
    graphNodes.forEach(node => {
      maxX = Math.max(node.x, maxX);
      maxY = Math.max(node.y, maxY);
      maxZ = Math.max(node.z, maxZ);
      minX = Math.min(node.x, minX);
      minY = Math.min(node.y, minY);
      minZ = Math.min(node.z, minZ);
    });
  
    // Calculate the distance needed to view all nodes
    const distance = (Math.sqrt(Math.pow(maxX - minX, 2) + Math.pow(maxY - minY, 2) + Math.pow(maxZ - minZ, 2))) / 2;
  
    // Calculate the center point of all nodes
    const centerX = (minX + maxX) / 2;
    const centerY = (minY + maxY) / 2;
    const centerZ = (minZ + maxZ) / 2;
  
    // Set the camera position to include all nodes
    graphRef.current.cameraPosition(
      { x: centerX, y: centerY, z: distance * 1.2 }, // Zoom out a bit more than the max distance
      { x: centerX, y: centerY, z: centerZ }, // Look at the center of all nodes
      2000 // ms transition duration
    );
  };
  

  const handleKeyPress = (event) => {
    if (event.key === 'Escape') { // Check if the ESC key is pressed
      handleZoomOut();
    }
    stopCameraSpin();
  };
  
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
  
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [graphData]); // Include graphData in the dependency array
  
  // Use useEffect to add event listeners after component mounts
  useEffect(() => {
    // Ensure the ref is set
    if (graphRef.current) {
        const handleMouseDown = (event) => {
            console.log('Mouse down', event);
            stopCameraSpin();
            // You can add state logic here to track dragging
        };

        // Add event listeners
        const graphContainer = graphContainerRef.current;
        if (!graphContainer) return;
      
        const container = graphRef.current; // Directly to the container
        graphContainer.addEventListener('mousedown', handleMouseDown);
  
        // Cleanup function to remove event listeners
        return () => {
          graphContainer.removeEventListener('mousedown', handleMouseDown);
        };
    }
  }, [graphRef]); // Re-run if graphRef changes


  const handleEngineStop = () => { 
    if (organicLayout && isLoaded && isFirstEngineStop) {
      stopCameraSpin();
      handleExpandGraph();
      handleExpandGraph();
      setTimeout(() => graphRef.current.zoomToFit(), 1000);
      setIsFirstEngineStop(false);
    }
  }


  const handleNodeDragEnd = (node) => {
    node.fx = node.x;
    node.fy = node.y;
    node.fz = node.z;
  };

  const handleNodeDrag = (node) => {
    graphData.nodes.forEach((n) => {
      if (n.id !== node.id) {
        node.fx = node.x;
        node.fy = node.y;
        node.fz = node.z;
      }
    });

    node.fx = node.x;
    node.fy = node.y;
    node.fz = node.z;
  };

  const handleSimulationToggle = () => {
    setPauseSimulation(!PauseSimulation);
  
    if (simulationRef.current) {
      if (!PauseSimulation) {
        // Start the simulation
        simulationRef.current.alphaTarget(0.1).restart();
      } else {
        // Stop the simulation
        simulationRef.current.stop();
      }
    }
  };

  useEffect(() => {
    if (viewGraph && graphRef && graphRef.current) {
      // Create and configure the simulation
      simulationRef.current = graphRef.current.d3Force('charge');
    }
  }, []);

  
  const handleClick = (event, { node, link }) => {
    if (node) {
      if (node.link) {
        window.open(node.link, '_blank');
      } 
      else {
        zoomToNode(node);
      }
    } else if (link) {
      if (link.link) {
        window.open(link.link, '_blank');
      }
    } else {
      if (simulationRef.current) {
        setPauseSimulation(true);
        simulationRef.current.stop();
      }
    }
  };

  // Create the alpha map (only once)
  const alphaMap = createCircularAlphaMap(128); // Adjust size as needed
  alphaMap.needsUpdate = true;

  // Function to create a circular alpha map
  function createCircularAlphaMap(size) {
      const canvas = document.createElement('canvas');
      canvas.width = size;
      canvas.height = size;
      const context = canvas.getContext('2d');
      context.fillStyle = 'black';
      context.fillRect(0, 0, size, size);
      context.fillStyle = 'white';
      context.beginPath();
      context.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
      context.fill();
      return new Texture(canvas);
  }

  function formatPrice(price) {
    if (price > 100000 || price == 0) {
      return '';
    }
  
    // Convert to string using fixed maximum number of decimal places
    const maxDecimals = 10; // You can adjust this for maximum desired decimals
    let priceStr = price.toFixed(maxDecimals);
  
    // Find the position of the first non-zero digit after the decimal point
    const decimalIndex = priceStr.indexOf('.');
    const firstNonZeroIndex = priceStr.slice(decimalIndex + 1).search(/[1-9]/) + decimalIndex + 1;
  
    // Determine the number of decimal places to show
    let requiredDecimals;
    if (firstNonZeroIndex > decimalIndex && firstNonZeroIndex - decimalIndex > 2) {
        // If the first non-zero digit is within the first two places, use that precision
        requiredDecimals = firstNonZeroIndex - decimalIndex;
    } else {
        // Otherwise, default to two decimal places
        requiredDecimals = Math.min(2, priceStr.length - decimalIndex - 1); // Avoid exceeding actual length
    }
  
    // Return the formatted price with the determined number of decimal places
    return '$' + Number(priceStr).toFixed(requiredDecimals).toString();
  }
  
  async function load() {
    handleOrientationChange();
    await fetchEthTransactions();
  }

  useEffect(() => {
    if (!isLoaded && (filterNFT != null) && (filterToken != null) && rate != null) { // } && graphRef.current && graphRef.current.cameraPosition) {
      load();
    }
  }, [rate, filterNFT, filterToken]);

  useEffect(() => {
    if (filterNFT) {
      console.log('filterNFT is true');
      // Run your code that depends on filterNFT being true
    }
  }, [filterNFT]);

  useEffect(() => {
    if (!showHomePage && !isLoaded && graphData.nodes.length) {
      if (isMobile && isPortrait) {
        zoomOut(2000);
      }

      if (organicLayout) {
        //graphRef.current.zoomToFit();
        //handleExpandGraph();
        //distributeUsersAroundToken();
        //graphRef.current.zoomToFit(1000);
      }
      else {
        handleDistributeAll();
      }
      
      if (spinSpeed) {
        startCameraSpin();
      }

      isLoaded = true;
    }
  }, [graphData, isLoaded]); // Empty dependency array

 
// Function to load an image from a URL
function loadImage(url, callback) {
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.src = url;
  img.onload = () => callback(img);
}

// Function to resize an image
function resizeImage(image, width, height, callback) {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0, width, height);
  callback(canvas.toDataURL());
}

const normalizeSize = (nodeCount, minNodeCount, maxNodeCount, minSize, maxSize) => {
  return ((maxSize - minSize) * (nodeCount - minNodeCount) / (maxNodeCount - minNodeCount)) + minSize;
};

  const getForceGraphProps = () => {
    const commonProps = {
      ref: graphRef,
      graphData: graphData,
      nodeLabel: "label",
      nodeAutoColorBy: "group",
    };

    if (is3DMode) {
      return {
        ...commonProps,
        linkDirectionalParticles: 2,
        linkDirectionalParticleSpeed: 0.008,
        backgroundColor: "#000000",
        onBackgroundClick: handleBackgroundClick,
        onNodeDragEnd: handleNodeDragEnd,
        onNodeDrag: handleNodeDrag,
        onEngineStop: handleEngineStop,
        onZoom: handleZoom,
        onClick: handleClick,
  
        // Aggressively increase link distance and repulsion
        d3Force: (forceGraphInstance) => {
          forceGraphInstance
            .d3Force('link')
            .distance(2000);  // Set a much higher link distance

          forceGraphInstance
            .d3Force('charge')
            .strength(-500);  // Stronger repulsive force between nodes
        },

        // Speed up the simulation
        //d3AlphaDecay: 0.15, // Increase the decay rate to speed up convergence
        //d3VelocityDecay: 0.4, // Reduce velocity decay to make nodes come to rest faster
        //d3AlphaMin: 0.05,     // Lower the alpha threshold for early stopping

        linkColor: (link) => {
          return 'rgba(255, 255, 255, 0.9)'; // White color with 90% opacity
        },
        
        onNodeClick: (node, e) => {
          navigator.clipboard.writeText(node.id);
          if (dataSource != PCAP) {
            if (dataSource == ETHERSCAN) {
       
              if (e.shiftKey) {
                let newId = node.id;
                if (newId.includes('.')) {
                  newId = newId.split('.')[0];
                }
                const url = `https://etherscan.io/dex/uniswapv2/${newId}`;
                window.open(url, '_blank');
              }
              else if (e.ctrlKey) {
                const explorerBaseUrl = 'https://etherscan.io';
                let middle = '/address/';
                let newId = node.id;
              
                // if it is an NFT we added a period and the token id onto the address
                if (newId.includes('.')) {
                  middle = '/nft/'
                  newId = newId.replace('.','/');
                }

                const url = explorerBaseUrl + middle + newId;
                window.open(url, '_blank');         
              }
              else {
                handleNodeClick( dataSource, graphData, graphRef, node.id, false);
              }
            }
            else {
              const explorerBaseUrl = 'https://bscscan.com';
              const url = explorerBaseUrl + '/address/' + node.id;
              window.open(url, '_blank');
            }

          } else {
            handleNodeClick(dataSource, graphData, graphRef, node.id, false);
          }
        },

        onLinkClick: (link) => {
          if (dataSource != PCAP) {
            const explorerBaseUrl = (dataSource == ETHERSCAN) ? 'https://etherscan.io' : 'https://bscscan.com';
            const url = explorerBaseUrl + '/tx/' + link.tx;
            window.open(url, '_blank');
          } else {
            handleNodeClick(dataSource, graphData, graphRef, link.target.id, false);
            setActiveLinkId(link.target.id);
          }
        },

        linkWidth: (link) => {
          const minWidth = 2;
          const maxWidth = 20;
          const size = Math.log(link.value + 1); // +1 to avoid log(0), ensures all values are greater than 0
          const logMaxValue = Math.log(maxValue + 1); // Apply the same transformation to maxValue
          const normalizedValue = (size / logMaxValue) * (maxWidth - minWidth) + minWidth;
          const width = Math.min(Math.max(normalizedValue, minWidth), maxWidth);
          return width;
      },

      nodeThreeObject: (node) => {
        // Calculate the size based on the number of nodes or links
        const nodeCount = graphData.nodes.length;
        const multiplier = (node.linkCount) ? node.linkCount : nodeCount;
        let size = 20;

        // Adjust the size based on the number of links
        if (node.icon) {
          if (!filterNFT) {
            size = ((multiplier + (imageSize * 2))) / 2;
          } else {
            const minNodeCount = 1; // minimum possible node count
            const maxNodeCount = Math.max(1000, graphData.nodes.length); // maximum possible node count
            const minSize = 50;
            const maxSize = 200;            

            size = normalizeSize(nodeCount, minNodeCount, maxNodeCount, maxSize, minSize);
          }
        }

        if (size > 300) {
          size = 300;
        }

        // Create a sphere to represent the node
        const segments = isMobile ? 16 : 32;
        const sphereGeometry = new SphereGeometry(size, segments, segments); // Use the calculated size
        const sphereMaterial = new MeshPhongMaterial({
            color: new Color(node.color),
            transparent: true,
            opacity: 0.7,
            shininess: 60,
            specular: new Color(0x111111)
        });
    
        const sphere = new Mesh(sphereGeometry, sphereMaterial);

        // Add the icon as a sprite
        if (node.icon && node.icon.startsWith('data:image/svg+xml;base64,')) {
          // Decode the base64 image
          const decodedIcon = atob(node.icon.split(',')[1]);
      
          // Create a blob from the SVG data
          const blob = new Blob([decodedIcon], { type: 'image/svg+xml' });
      
          // Create a URL from the blob
          const url = URL.createObjectURL(blob);
      
          // Load the texture from the SVG blob URL
          const spriteMap = new TextureLoader().load(url, function (texture) {
              // Update texture settings here if needed, e.g., filtering or repeat
              // It's also a good place to revoke the blob URL to release memory
              URL.revokeObjectURL(url);
          });
      
          // Create the sprite material with the loaded texture
          const spriteMaterial = new SpriteMaterial({
              map: spriteMap,
              alphaMap: alphaMap, // Ensure alphaMap is defined or remove this if not needed
              transparent: true,
              depthTest: false
          });
      
          // Create the sprite using the material
          const sprite = new Sprite(spriteMaterial);
          const spriteScale = size * 2;
          sprite.scale.set(spriteScale, spriteScale, 1);
          sprite.position.set(0, 0, 0); // size / 2 + 0.1);
          sphere.add(sprite);
          sphereMaterial.opacity = 0.0; // Or any value that suits your design
          sphereMaterial.transparent = true;
        }
        else
        if (node.icon) {
          //console.log(`Loading icon: ${node.id}  ${node.icon}`);
          const spriteMap = new TextureLoader().load(node.icon);
          const spriteMaterial = new SpriteMaterial({ 
              map: spriteMap, 
              alphaMap: alphaMap, 
              transparent: true,
              depthTest: false,
              depthWrite: false
          });

          const sprite = new Sprite(spriteMaterial);
          const spriteScale = size * 2;
          sprite.scale.set(spriteScale, spriteScale, 1);
          
          // Change the z-position based on the value of showText
          const zPosition = showText ? 0 : size + 0.1;
          sprite.position.set(0, 0, zPosition);

          sphere.add(sprite);
          sphereMaterial.opacity = 0.0; // Or any value that suits your design
          sphereMaterial.transparent = true;
        } else {
          sphereMaterial.opacity = 1.0;
          sphereMaterial.transparent = false;
        }

        if (showText && !node.label.startsWith("0x")) {
          // Create the sprite text
          const spriteText = new SpriteText(node.label);
          spriteText.color = "#FFFFFF";
          spriteText.textHeight = 40.0;
          if (filterNFT) {
            size /= 2;
          }
          spriteText.position.x = size / 2; // Adjust if necessary to align with the sphere's x position
          spriteText.position.y = size; // Adjust if necessary to align with the sphere's y position
          spriteText.position.z = size + 0.1; // Position in front of the sphere along the z-axis
          sphere.add(spriteText);
        }
      
        return sphere;
      },
      
      
        threeObject: (graphScene) => {
          const light1 = new DirectionalLight(0xffffff, 1);
          light1.position.set(2, 2, 2).normalize();
          graphScene.add(light1);
        
          const light2 = new DirectionalLight(0xffffff, 1);
          light2.position.set(-2, -2, -2).normalize(); // Light from the opposite direction
          graphScene.add(light2);
        
          const ambientLight = new AmbientLight(0x808080);
          graphScene.add(ambientLight);
        
          return graphScene;
        },
      };
    }
  
    return {
      ...commonProps,
      nodeCanvasObject: (node, ctx, globalScale) => {
        const baseRadius = 5;
        // Adjust the radius based on the zoom level
        const nodeRadius = Math.max(baseRadius / globalScale, 1); // Ensure the radius doesn't get too small
        const labelPadding = 2; // Space between the node and the text
        // Convert the node.color from a number to a hexadecimal string
        const colorHex = '#' + node.color.toString(16).padStart(6, '0');

        // Draw circle for the node
        ctx.beginPath();
        ctx.arc(node.x, node.y, nodeRadius, 0, 2 * Math.PI, false);
        ctx.fillStyle = colorHex;
        ctx.fill();
    
        // Draw text label only if showText is true
        if (showText) {
            const label = node.id;
            const fontSize = 12/globalScale;
            ctx.font = `${fontSize}px Sans-Serif`;
            ctx.textAlign = 'left'; // Align text to the left of the x-coordinate
            ctx.textBaseline = 'middle';
            ctx.fillStyle = 'black'; // Text color
    
            // Position the text to the right of the node
            const textX = node.x + nodeRadius + labelPadding;
            ctx.fillText(label, textX, node.y);
        }
      },
    };
  };

  const openIntervalInputRef = React.createRef();
  const openFileInputRef = React.createRef();
  const addFileInputRef = React.createRef();
  const openPcapRef = React.createRef();
  const openNameTableInputRef = React.createRef();
  const ForceGraphComponent = is3DMode ? ForceGraph3D : ForceGraph2D;

  const [anchorInterval, setAnchorInterval] = React.useState(null);
  const [anchorOpenSaveAs, setAnchorOpenSaveAs] = React.useState(null);
  const [anchorOpen, setAnchorOpen] = React.useState(null);
  const [anchorSave, setAnchorSave] = React.useState(null);
  const [anchorView, setAnchorView] = React.useState(null);
  const [anchorEth, setAnchorEth] = React.useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAdvanced, setAnchorElAdvanced] = useState(null);

  const handleMenuClickEl = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuCloseEl = () => {
    setAnchorEl(null);
  };

  const handleAdvancedMenuClickEl = (event) => {
    setAnchorElAdvanced(event.currentTarget);
  };

  const handleAdvancedMenuCloseEl = () => {
    setAnchorElAdvanced(null);
  };

  const handleMenuItemClick = (action) => () => {
    action();
    handleMenuCloseEl();
  };

  const handleAdvancedMenuItemClick = (action) => () => {
    action();
    handleAdvancedMenuCloseEl();
    handleMenuCloseEl(); 
  };

  const handleOpenInterval = (event) => {
    setAnchorInterval(event.currentTarget);
  };

  const handleCloseInterval = () => {
    setAnchorInterval(null);
  };

  const handleOpenOpen = (event) => {
    setAnchorOpen(event.currentTarget);
  };

  const handleCloseOpen = () => {
    setAnchorOpen(null);
  };

  const handleOpenSaveAs = (event) => {
    setAnchorOpenSaveAs(event.currentTarget);
  };

  const handleCloseSaveAs = () => {
    setAnchorOpenSaveAs(null);
  };

  const handleOpenSave = (event) => {
    setAnchorSave(event.currentTarget);
  };

  const handleCloseSave = () => {
    setAnchorSave(null);
  };

  const handleOpenView = (event) => {
    setAnchorView(event.currentTarget);
  };

  const handleCloseView = () => {
    setAnchorView(null);
  };

  const handleOpenEth = (event) => {
    setAnchorEth(event.currentTarget);
  };

  const handleCloseEth = () => {
    setAnchorEth(null);
  };

  const handleIntervalSelection = (action) => {
    handleCloseInterval();
  
    switch (action) {
      case '1m':
        setRangeInterval(60);
        break;
      case '5m':
        setRangeInterval(300);
        break;
      case '10m':
        setRangeInterval(600);
         break;
      case '30m':
        setRangeInterval(1800);
        break;
      case '1h':
        setRangeInterval(3600);
        break;
      case '1d':
        setRangeInterval(86400);
        break;
      case '1w':
        setRangeInterval(604800);
        break;
      default:
        setRangeInterval(60);
        break;      
    }
  }

  function formatIntervalRange(action) {
    let range = '';
    switch (action) {
      case '60':
        range = '1m';
        break;
      case '300':
        range = '5m';
        break;
      case '600':
        range = '10m';
         break;
      case '1800':
        range = '30m';
        break;
      case '3600':
        range = '1h';
        break;
      case '86400':
        range = '1d';
        break;
      case '604800':
        range = '1w';
        break;
      default:
        range = '1m';
        break;      
    }

    return range;
  }

  const handleMenuClick = async(action) => {
    handleCloseOpen();
    handleCloseSave();
    handleCloseView();
    handleCloseEth();
    switch (action) {
      case 'loadEth':
        fetchEthTransactions();
        break;
      case 'startTimer':
        startTimer();
        break;
      case 'startEth':
        startEthTimer();
        break;
      case 'stopEth':
        stopEthTimer();
        break;      
      case 'loadBsc':
        fetchBscTransactions();
        break;
      case 'startBsc':
        startBscTimer();
        break;
      case 'stopBsc':
        stopBscTimer();
        break;      
      case 'demo':
        loadDemoFile();
        break;
      case 'openCSV':
        openFileInputRef.current.click();
        break;
      case 'addCSV':
        addFileInputRef.current.click();
        break;
      case 'openPCAP':
        openPcapRef.current.click();
        break;
      case 'openNameTable':
        openNameTableInputRef.current.click();
        break;
      case 'reset':
        resetGraph();
        break;
      case 'refresh':
        setIsTourRunning(false);
        //resetGraph();
        await getTransactions(dataSource,'',true);
        break;
      case 'saveCSV':
        downloadCsv(graphDataToCsv(graphData), "graphData.csv");
        break;
      case 'saveJPG':
        downloadImageJPG();
        break;
      case 'savePNG':
        downloadImagePNG();
        break;
      case 'savePDF':
        downloadPDF();
        break;
      case 'spin':
        toggleCameraSpin();
        break;
      case 'zoomOut':
        handleZoomOut();
        break;
      case 'showAll':
        restoreOriginalGraph();
        break;
      case 'toggleLabels':
        setShowText(!showText);
        break;
      case 'toggleList':
        setShowPanel(!showPanel);
        //Cookies.set('showPanel', showPanel, { expires: 7 }); // Expires in 7 days
        break;
      case 'toggleBottom':
        setShowBottom(!showBottom);
        break;
      case 'toggle3D':
        setIs3DMode(!is3DMode);
        break;
      case 'toggleSim':
        handleSimulationToggle();
        break;
      default:
        break;
    }
  };

  const [theta, setTheta] = useState(0); // Initialize theta
  const [phi, setPhi] = useState(Math.PI / 2); // Initialize phi

  const handleEvent = (event) => {
    if (event.pointerType != "mouse") {
      stopCameraSpin();
    }

    if (viewGraph && graphRef.current) {
      const zoomStep = .2; // Define the zoom step
  
      if (is3DMode) {
        // Periodically reset angles to align with the current camera position
        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
          rotateCamera({ horizontal: event.key === 'ArrowLeft' ? 0.05 : -0.05 });
        } else if (event.shiftKey && (event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
          const cameraPosition = graphRef.current.cameraPosition();
          const zoomChange = event.key === 'ArrowUp' ? -zoomStep * cameraPosition.z : zoomStep * cameraPosition.z;
          graphRef.current.cameraPosition({ z: cameraPosition.z + zoomChange }, undefined, 100);
        } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
          rotateCamera({ vertical: event.key === 'ArrowUp' ? 0.05 : -0.05 });
        }
      } else {
        const currentZoom = graphRef.current.zoom();

        if (event.shiftKey && (event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
          const currentZoom = graphRef.current.zoom();
          const newZoom = event.key === 'ArrowUp' ? currentZoom * (1 - zoomStep) : currentZoom * (1 + zoomStep);
          graphRef.current.zoom(newZoom, 100);
        } else if ((event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
          // Zoom in/out 
          graphRef.current.zoom(event.key === 'ArrowUp' ? currentZoom + zoomStep : currentZoom - zoomStep, 100);
        } else if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
          // Translate left/right for 2D
          const translateStep = 30; // Define the translation step (in pixels)
          const direction = event.key === 'ArrowLeft' ? -1 : 1;
          const currentTranslate = graphRef.current.centerAt();
          graphRef.current.centerAt(currentTranslate.x + direction * translateStep, currentTranslate.y, 100);
        }
      }
    }
  };  

  const rotateCamera = (rotationAngle) => {
    const currentCameraPosition = graphRef.current.cameraPosition();
    const { x, y, z } = currentCameraPosition;

    // Calculate the current distance from the center
    const distance = Math.sqrt(x * x + y * y + z * z);

    // Calculate initial spherical coordinates
    let theta = Math.atan2(x, z);
    let phi = Math.acos(y / distance);

    // Adjust theta and phi
    if (rotationAngle.horizontal) {
      theta += rotationAngle.horizontal;
    }
    if (rotationAngle.vertical) {
      // Limit the vertical rotation to avoid flipping
      phi = Math.max(0.1, Math.min(Math.PI - 0.1, phi + rotationAngle.vertical));
    }
 
    // Convert back to Cartesian coordinates
    const newX = distance * Math.sin(phi) * Math.sin(theta);
    const newY = distance * Math.cos(phi);
    const newZ = distance * Math.sin(phi) * Math.cos(theta);

    graphRef.current.cameraPosition({ x: newX, y: newY, z: newZ }, { x: 0, y: 0, z: 0 });
  };
    

  function startCameraSpin() {
    if (!viewGraph || (spinCamera && cameraSpinIntervalRef.current)) {
      return;
    }

    if (!graphRef || !graphRef.current) {
      return;
    }
    
    setSpinCamera(true);
    const position = graphRef.current.cameraPosition();
    const distance = (!isLoaded) ? 4000 : position.z;
    let angle = 0;
    const id = setInterval(() => {
      if (viewGraph && graphRef && graphRef.current && graphRef.current.cameraPosition) {
        graphRef.current.cameraPosition({
          x: distance * Math.sin(angle),
          z: distance * Math.cos(angle)
        });
        angle += Math.PI / 1000;
      }
      //logHeight();
    }, spinSpeed);
    cameraSpinIntervalRef.current = id;
  }

  const stopCameraSpin = useCallback(() => {
    setSpinCamera(false);
    if (cameraSpinIntervalRef.current) {
      clearInterval(cameraSpinIntervalRef.current);
      cameraSpinIntervalRef.current = 0;
    }
  }, []);

  
  function toggleCameraSpin() {
    const spinCameraNew = !spinCamera;
    setSpinCamera(!spinCamera);
    (spinCameraNew) ? startCameraSpin() : stopCameraSpin();
  }

  const handleTouchStart = (event) => {
    if (event.touches.length === 2) { // Check if two fingers are placed on the screen
      stopCameraSpin();  
    }
  };

  const onPointer = (e) => {
    if (e.pointerType != 'pointermove' && e.pointerType != 'mouse') {
      stopCameraSpin(); 
    }
  };

  const graphContainerRef = useRef(null);
  const tokenContainerRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const graphContainer = graphContainerRef.current;
    if (!graphContainer) return;
  
    graphContainer.addEventListener('touchstart', handleTouchStart);
    graphContainer.addEventListener('pointerdown', onPointer);
    graphContainer.addEventListener('pointermove', onPointer);
    graphContainer.addEventListener('pointerup', onPointer);
    graphContainer.addEventListener('keydown', handleEvent);
    graphContainer.addEventListener('click', handleEvent);
  
    return () => {
      graphContainer.removeEventListener('touchstart', handleTouchStart);
      graphContainer.removeEventListener('pointerdown', onPointer);
      graphContainer.removeEventListener('pointermove', onPointer);
      graphContainer.removeEventListener('pointerup', onPointer);
      graphContainer.removeEventListener('keydown', handleEvent);
      graphContainer.removeEventListener('click', handleEvent);
    };
  }, []);

  const [timeRange, setTimeRange] = useState([20, 80]); // Example range values
  const sparklineData = [5, 10, 5, 20, 8, 15, 10, 25, 20, 30, 45]; // Example data

  // Handler for the slider
  const onSliderChange = (value) => {
    setTimeRange(value);
    // Additional logic to update your graph based on the slider's range
  };

 
  useEffect(() => {
    if (viewGraph && graphRef && graphRef.current) {
      // Access the force simulation and stop it
      const forceSim = graphRef.current.d3Force('simulation');
      if (forceSim) {
        setTimeout(() => forceSim.stop(), 100); // Stop the simulation after a brief period
      }
    }
  }, []);

  const timeIntervals = ['30s', '1m', '5m', '10m', '30m', '1h'];

  const TimeButton = ({ label, onClick }) => {
    return (
      <Button className={classes.linkButtonStyle} onClick={() => onClick(label)}>
        {label}
      </Button>
    );
  };

  // Handler for when the text field changes
  const handleMaxTransactionsChange = (event) => {
    setMaxTransactions(event.target.value);
  };

  
  // Ref to store the interval ID
  const intervalRef = useRef(null);

  // Function to be called on interval
  const goToNextNode = () => {
    setCurrentNodeIndex((currentIndex) => {
      let nextIndex = currentIndex;
      let foundNodeWithIcon = false;
      let arrowDown = false;
  
      do {
        nextIndex = (nextIndex + 1) % graphDataRef.current.nodes.length;

        if (filterNFT && nextIndex == graphDataRef.current.nodes.length - 1) {
          nextIndex = 0;
          arrowDown = true;
          // simulate an ArrowDown key press
          var event = new KeyboardEvent('keydown', {'key':'ArrowDown'});
          document.dispatchEvent(event);
          break;
        }

        if (graphDataRef.current.nodes[nextIndex].icon) {
          foundNodeWithIcon = true;
          break;
        }
  
        // If we reached the end, loop back to the start
        if (nextIndex === tokenList.length - 1) {
          setIsTourRunning(false)
          nextIndex = -1; // This will be incremented to 0 by the loop
        }
        
      } while (nextIndex !== currentIndex);
  
      if (arrowDown) {
        return nextIndex;
      }

      // If a node with an icon is found, handle the node click
      if (foundNodeWithIcon) {
        moveToNode(graphDataRef.current, graphRef, graphDataRef.current.nodes[nextIndex]); 
        //handleNodeClick(dataSource, graphData, graphRef, tokenList[nextIndex].id, true);
      }
  
      return nextIndex;
    });
  };
  

  // Effect to handle the starting and stopping of the tour
  useEffect(() => {
    if (isTourRunning) {
      // Start the interval
      intervalRef.current = setInterval(goToNextNode, (viewGraph) ? tourSpeedGraph : tourSpeedList);
    } else {
      // Clear the interval if it's running
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }

    // Cleanup interval on component unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isTourRunning]);

  // Start or stop the tour
  const toggleTour = () => {
      if (!isTourRunning) {
        goToNextNode();
      }
      setIsTourRunning(!isTourRunning);
  };

  const goToPreviousTimeRange = () => {
      setIsTourRunning(false); // Stop the tour
      const newStartDate = dateRange.startDate - 300; //(dateRange.endDate - dateRange.startDate);
      const newEndDate = dateRange.startDate - 1;
      //the global state won't be set for the fetch call, so we have to pass the new dates in
      setDateRange( newStartDate, newEndDate);
      resetGraph();
      getTransactions( dataSource, null, false, newStartDate, newEndDate );
  };

  const goToNextTimeRange = () => {
      setIsTourRunning(false); // Stop the tour
      const newStartDate = dateRange.endDate + 300; //1;
      const newEndDate = newStartDate + 300; // dateRange.endDate + (dateRange.endDate - dateRange.startDate);
      //the global state won't be set for the fetch call, so we have to pass the new dates in
      setDateRange( newStartDate, newEndDate);
      resetGraph();
      getTransactions( dataSource, null, false, newStartDate, newEndDate );
  };

  const goToPreviousNode = () => {
      stopCameraSpin();
      setIsTourRunning(false); // Stop the tour
    
      setCurrentNodeIndex((currentIndex) => {
        let prevNodeIndex = currentIndex === 0 ? graphData.nodes.length - 1 : currentIndex - 1;
    
        while (!tokenList[prevNodeIndex].icon) {
          prevNodeIndex = prevNodeIndex === 0 ? graphData.nodes.length - 1 : prevNodeIndex - 1;
          if (prevNodeIndex === currentIndex) break; // Break if a full loop is completed
        }
    
        if (tokenList[prevNodeIndex].icon) {
            moveToNode(graphData, graphRef, graphData.nodes[prevNodeIndex]);
        }
    
        return prevNodeIndex;
      });
  };
  
  const goToNextNodeManually = () => {
      stopCameraSpin();
      setIsTourRunning(false); // Stop the tour
    
      setCurrentNodeIndex((currentIndex) => {
        let nextNodeIndex = (currentIndex + 1) % graphData.nodes.length;
    
        while (!graphData.nodes[nextNodeIndex].icon) {
          nextNodeIndex = (nextNodeIndex + 1) % tokenList.length;
          if (nextNodeIndex === currentIndex) break; // Break if a full loop is completed
        }
    
        if (graphData.nodes[nextNodeIndex].icon) {
          handleNodeClick(dataSource, graphData, graphRef, graphData.nodes[nextNodeIndex].id, true);
        }
    
        return nextNodeIndex;
      });
  };


  function moveToNode(graphData, graphRef, node) {
    if (node) {
      if (viewGraph && graphRef.current) {
        // Calculate the distance you want to stop in front of the node
        const stopDistance = 800; // Distance from the node to stop at

        // Assuming the camera is above the graph looking down, we might only need to adjust z
        const finalPosition = { x: node.x, y: node.y, z: node.z - stopDistance };

        // First, turn to face the destination node immediately, might not be necessary if you're going to set lookAt in the next step anyway
        graphRef.current.cameraPosition(undefined, { x: node.x, y: node.y, z: node.z }, 1000);

        // After a delay (to allow the camera to turn), fly towards the node and adjust to look at it directly
        setTimeout(() => {
          // Move the camera to the final position and ensure it's looking at the node
          graphRef.current.cameraPosition(finalPosition, { x: node.x, y: node.y, z: node.z }, 2500); // Takes 2.5 seconds to fly
        }, 1000); // Delay to ensure the camera has turned
      }
      else {
        if (imgRefs[currentNodeIndex].current) {
          imgRefs[currentNodeIndex].current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }); // Scroll image into view
        }
        setImageKey(Date.now());
      } 
    }
  }

  // saveIconToAddress
  const saveIconToAddress = async(dataSource, address,icon) => {
    try {
      const response = await axios.get(`${SERVER_NAME}/saveIconToAddress/`, {
        params: {
          dataSource: dataSource,
          address: address,
          icon: icon,
        },
      });
    } catch (error) {
      console.error('Error saving token info:', error);
    }
  }

  const filterOnCurrentNode = async () => {
    if (!activeNodeId) return;
    let nodeId = activeNodeId.split('.')[0] || activeNodeId;
    setSearchText(nodeId);
  };
  
  const gotoEtherscan = async () => {
    if (!activeNodeId) return;
    const nodeId = activeNodeId.split('.')[0] || activeNodeId;
    const url = (filterNFT) ? `https://etherscan.io/token/${nodeId}#inventory` : `https://etherscan.io/address/${nodeId}`
    window.open(url, '_blank');
  };

  const [trigger4, setTrigger4] = useState(0);

  useEffect(() => {
      if (trigger4 == false) return;
      setTrigger4(0);
      handleDistributeSphereAll();
      //setGraphData(tokenList, []);
  }, [trigger4]);

  const [trigger3, setTrigger3] = useState(0);

  useEffect(() => {
      if (trigger3 === 0) return;
      setTrigger3(0);
      const node = graphData.nodes.find(n => n.id === activeNodeId);
      if (node) {
        handleNodeClick(dataSource, graphData, graphRef, activeNodeId, true);
      }
  }, [trigger3,isDataReady]);

  const [trigger2, setTrigger2] = useState(0);

  useEffect(() => {
      if (trigger2 === 0) return;
      setTrigger2(0);
      handleDistributeAll();
      setTrigger3(trigger3 + 1);
  }, [graphData,trigger2,isDataReady]);

  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
      if (trigger == 0) return;
      setTrigger(0);
      addTokenToGraphData(activeNodeId);
      setTrigger2(trigger2 + 1);
  }, [trigger]);

  
  const handleNodeClick = useCallback(async (dataSource, graphData, graphRef, nodeId, zoom, tokenType) => {
    if (!viewGraph && filterNFT && imgRefs.length && imgRefs.length >= currentNodeIndex && imgRefs[currentNodeIndex].current) {
      imgRefs[currentNodeIndex].current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }); // Scroll image into view
      setImageKey(Date.now());
      setActiveNodeId(nodeId);
      //return;
    }

    if (filterNFT || tokenType == 'NFT') {
      tokenType = 'NFT';
      const address = nodeId.split('.')[0];
      if (!address.length) { return; }
      setChartData(null);
      const tokens = await getTokenList(dataSource, address, false, tokenType);
      setGraphData({ nodes: tokens, links: [] });
      setImageKey(Date.now());
      setCurrentNodeIndex(0);
      nodeId = address;
      if (nodeListRef && nodeListRef.current) {
        nodeListRef.current.scrollToActiveNode(nodeId);
      }
      setActiveNodeId(nodeId);
      return;
    }

    //if (!graphRef.current) return;

    let node = graphData.nodes.find(n => n.id === nodeId);
    nodeListRef.current.scrollToActiveNode(nodeId);
    setActiveNodeId(nodeId);

    if (!node) {
      setTrigger(prevTrigger => prevTrigger + 1);

      if (!isMobile) {
        await getTransactions(dataSource, nodeId, true);
      } 
   
      return;
    }

    if (node) {
      if (zoom && viewGraph && graphRef.current) {
        stopCameraSpin();
        moveToNode(graphData, graphRef, node);  
      }
      
      setActiveNodeName(node.label);
      const tokenEntry = tokenList.find(t => t.id === nodeId);
      if (tokenEntry) {
        setCurrentNodeIndex(tokenList.indexOf(tokenEntry));
      }
    } 

    if (showChart) {
      const tokenName = nodeId;
      const serverUrl = SERVER_NAME + '/token-info/' + tokenName;
    
      try {
        const response = await fetch(serverUrl);
        const data = await response.json();
        if (data) {
          //console.log(data);
          setTokenData((data && data.description && data.description.en) ? data : null);
        
          if (node && node.icon == genericTokenIcon && data && data.image && data.image.large) {
            saveIconToAddress( dataSource, nodeId, data.image.large);
            node.icon = data.image.large;
          }
        }
      } catch (error) {
        console.error('Error fetching token info:', error);
      }
    }
  }, [dataSource, graphData, graphRef]);
  
  const handleLinkClick = async (dataSource, graphData, graphRef, linkId, zoom) => {
    const link = graphData.links.find(n => n.tx === linkId);
    if (!link || !graphRef.current) return;

    const node = graphData.nodes.find(n => n.id === link.target.id);
    if (!node) return;
  
    if (zoom) {
      stopCameraSpin();
      moveToNode(graphData, graphRef, node);
    }
    setActiveLinkId(linkId);
   
    const tokenName = node.id;
    const serverUrl = SERVER_NAME + '/token-info/' + tokenName;
  
    try {
      const response = await fetch(serverUrl);
      const data = await response.json();
      // Display data in a popup dialog
      //console.log(data);
      setTokenData((data && data.description) ? data : null);
    
      if (node.icon == genericTokenIcon && data && data.contract_address && data.image.large) {
        saveIconToAddress( dataSource, data.contract_address, data.image.large);
        node.icon = data.image.large;
      }
    } catch (error) {
      console.error('Error fetching token info:', error);
    }
  };
  
  useEffect(() => {
    if (filterNFT && isDataReady && tokenList.length > 0) {
  
      // we might already have a node id passed in with the url (eg /nft/0x1234...)
      let nodeId = defaultNodeId;
      let zoom = true;

      if (!nodeId) {
        if (filterNFT) {
          const tokens = tokenList.sort((a, b) => {
            // Check if the first character of each label is a number
            const aIsNumber = !isNaN(a.label[0]);
            const bIsNumber = !isNaN(b.label[0]);
        
            // If a starts with a letter and b starts with a number, a comes first
            if (!aIsNumber && bIsNumber) {
                return -1;
            }
        
            // If a starts with a number and b starts with a letter, b comes first
            if (aIsNumber && !bIsNumber) {
                return 1;
            }
        
            // If both start with a letter or both start with a number, compare as usual
            return a.label.localeCompare(b.label);
          });

          nodeId = tokens[0].id;
        } else if (filterToken) {
          const tokens = tokenList.sort((a, b) => b.price - a.price);
          nodeId = tokens[0].id;
          zoom = false;
        }
      }

      if (nodeId) {
        setTimeout(() => {
          setActiveNodeId(nodeId);
          const tokenType = filterNFT ? 'NFT' : 'Token';
          handleNodeClick(dataSource, graphData, graphRef, nodeId, zoom, tokenType);
        }, 2000);
      }
    }
  }, [isDataReady]);


  const loadView = async () => {
    if (filterNFT) {
      let tokens = await getTokenList(dataSource, "", true);
    }

    if (filterToken) {
      getTransactions(dataSource,'',true);
      startCameraSpin();
    }
  }
 
  useEffect(() => {
    if (reload) {
      loadView();
      setReload(false);
    }
  }, [reload]);


  const enableNFTFilter = async () => {
    if (filterNFT) { return; }

    //stopCameraSpin();
    setShowChart(false);
    setChartData(null);
    setFilterNFT(true);
    setFilterToken(false);
    setViewGraph(false);
    setReload(true);
    setIsDataReady(false);
    setTokenData(null);
    setTokenList([]);
    setGraphData({ nodes: [], links: [] });
    isLoaded = false;
  }


  const enableTokenFilter = async () => {
    //if (filterToken) { return; }
    const newDate = new Date();
    const end = Math.floor(newDate.getTime() / 1000); // Current time in seconds
    const start = end - 3600; // One hour before the end time in seconds
    setDateRange(start, end);
    await updateRate();
    resetGraph()
    setShowChart(true);
    setChartData(null);
    setFilterNFT(false);
    setFilterToken(true);
    setViewGraph(true);
    setReload(true);
    setIsDataReady(false);
    setTokenData(null);
    setTokenList([]);
    setGraphData({ nodes: [], links: [] });
    isLoaded = false;
    setTrigger2(true);
  }


  const getActiveTokenPrice = async () => {
    const token = tokenList.find(t => t.id === activeNodeId);
    if (token && token.price) {
      return token.price;
    }
    return 0;
  }

  const getActiveTokenUniswapVersion = async () => {
    const token = tokenList.find(t => t.id === activeNodeId);
    if (token && token.version) {
      console.log('getActiveTokenUniswapVersion.  activeNodeId: ', activeNodeId, ' token.version: ', token.version);
      return token.version;
    }
    return 0;
  }

  useEffect(() => {
    if (isAuthenticated && activeNodeId) {
      //console.log('call fetchTokenPrice.  activeNodeId: ', activeNodeId);

      const fetchTokenPrice = async () => {
        const price = await getActiveTokenPrice();
        setTokenPrice(price);
      };

      const fetchTokenUniswapVersion = async () => {
        const uniswapVersion = await getActiveTokenUniswapVersion();
        setTokenUniswapVersion(uniswapVersion);
      };

      fetchTokenPrice();
      fetchTokenUniswapVersion();
    }
  }, [activeNodeId]);


  if (isFirstOrient) {
    setIsFirstOrient(false);
    handleOrientationChange();
  }
  

  function Navigation({ classes, goToPreviousNode, isTourRunning, toggleTour, goToNextNodeManually }) {
    return (
        <div style={{border: (isTourRunning) ? '1px solid green' : 'none'}} >
          <Tooltip title={ (isTourRunning) ? "Stop Tour" : "Start Tour" }>
            <IconButton onClick={toggleTour} className={classes.toolIconStyle}>
              <FaBus /> {/* Spin Icon */}
            </IconButton>
          </Tooltip>
        </div> 
    );
  }

  const optionsPopupClose = (save, subscribeNewsletterParam) => {
    setShowOptionsPopup(false);

    if (save) {
      const response = axios.post(`${SERVER_NAME}/subscribeNewsletter`, {
        username: username,
        subscribeNewsletter: subscribeNewsletterParam,
      });    
    }
  }


  function SearchField({ initialSearchText, onSearchTextChange, handleSearch }) {
    const [searchFieldText, setSearchFieldText] = useState(initialSearchText);
    const inputRef = useRef();

    useEffect(() => {
      inputRef.current.focus();
    }, [searchFieldText]);
    
    const handleChange = (event) => {
      setSearchFieldText(event.target.value);
    };
  
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        onSearchTextChange(event.target.value);
        handleSearch(searchFieldText);
      }
    };

    return (
      <TextField
        inputRef={inputRef}
        value={searchFieldText}
        onChange={handleChange}
        className={classes.searchField}
        style={{ width: (showAdvanced) ? '500px' : '100px' }}
        variant="outlined"
        size="small"
        onKeyDown={handleKeyDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: 'white' }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchText && (
                <IconButton
                  aria-label="clear text"
                  onClick={() => setSearchText('')}
                  edge="end"
                  size="small"
                >
                  <ClearIcon style={{ color: 'white' }} />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
      />
    );
  }
  

  if (showHomePage) {
    // Render HomePage only, skipping the rest of the logic
    return (
      <div className="app">
        <HomePage
          setShowHomePage={setShowHomePage}
          setShowShortcutPage={setShowShortcutPage}
          setShowSignup={setShowSignup}
        />
        {showSignup && (
          <SignupDialog
              onSignup={handleSignup}
              onClose={() => setShowSignup(false)}
            />
        )}
      </div>
    );
  }

  return (
    <div className="app">
      {showHomePage && ( 
        <div>
          <HomePage
            setShowHomePage={setShowHomePage}
            setShowShortcutPage={setShowShortcutPage}
            setShowSignup={setShowSignup}
          />
        </div>
      )}
      {showNodeInfoDialog && (
        <NodeInfoDialog
          onClose={handleCloseNodeInfoDialog}
          top={cursorPos.x}
          left={cursorPos.y}
        />
      )}
      {showCookieDialog && (
        <CookieConsentDialog
          onClose={handleDeclineCookies}
          onAccept={handleAcceptCookies}
        />
      )}
      {showAboutDialog && (
        <AboutDialog
          onClose={() => setShowAboutDialog(false)}
        />
      )}
      {showLogin && (
        <LoginDialog
            onLogin={handleLogin}
            onClose={() => setShowLogin(false)}
            setShowSignup={setShowSignup}
          />
      )}
      {showLogoutConfirm && (
        <LogoutConfirmDialog
            onLogout={handleLogout}
            onClose={() => setShowLogoutConfirm(false)}
          />
      )}
      {showSignup && (
        <SignupDialog
            onSignup={handleSignup}
            onClose={() => setShowSignup(false)}
          />
      )}
      {showMembersOnly && (
        <MembersOnlyDialog
            onSignup={() => {setShowMembersOnly(false); setShowSignup(true)}}
            onClose={() => setShowMembersOnly(false)}
            onLogin={() => {setShowMembersOnly(false); setShowLogin(true)}}
          />
      )}
      {showVerifyEmailCode && (
        <VerifyEmailCodeDialog  
            username={username} 
            onSignup={handleVerifyEmailCode}
            onClose={() => setShowVerifyEmailCode(false)}
          />
      )}
      {showSaveMapDialog && (
        <SaveMapDialog
            open={showSaveMapDialog}
            onSave={saveMap}
            onClose={() => setShowSaveMapDialog(false)}
          />
      )}
      {showLoadMapDialog && (
        <LoadMapDialog
            open={showLoadMapDialog}
            maps={maps}
            onLoad={openMap}
            onClose={() => setShowLoadMapDialog(false)}
          />
      )}
      {showSearchDialog && (
        <SearchDialog
          searchTextParam={searchText}
          setSearchTextParam={setSearchText}
          open={showSearchDialog}
          onSearch={handleSearchFromDialog}
          onClose={() => setShowSearchDialog(false)}
        />
      )}
      {showOptionsPopup && (
        <OptionsPopup 
          open={showOptionsPopup} 
          onClose={optionsPopupClose} 
          tourSpeedGraph={tourSpeedGraph} 
          setTourSpeedGraph={setTourSpeedGraph} 
          tourSpeedList={tourSpeedList} 
          setTourSpeedList={setTourSpeedList} 
          spinSpeed={spinSpeed} 
          setSpinSpeed={setSpinSpeed}
          useAspectRatio={useAspectRatio} 
          setUseAspectRatio={setUseAspectRatio} 
          imageSize={imageSize} 
          setImageSize={setImageSize} 
          resetCookieConsent={resetCookieConsent} 
          setResetCookieConsent={setResetCookieConsent}
          subscribeNewsletter={subscribeNewsletter}
          setSubscribeNewsletter={setSubscribeNewsletter}
      />
      )}
      {showNewsletter && (
        <Newsletter 
          open={showNewsletter} 
          onClose={() => setShowNewsletter(false)} 
          newsDate={newsDate}
          setNewsDate={setNewsDate}
        />
      )}
      <div id="topContainer" className={classes.topContainer}>
        <div className={classes.leftAlignedContainer}>
          <div style={{ backgroundColor: 'black', width: '40px', padding: '1px', margin: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={() => setShowHomePage(true)} className={classes.menuButtonStyle}>
              <img src={futLogoIcon200} style={{width:'30px', height:'30px'}} alt="" />
            </IconButton>
            
          </div>
          {!isMobile && (
            <div>
              <SearchField
                initialSearchText={searchText}
                onSearchTextChange={setSearchText}
                handleSearch={handleSearchFromDialog}
              />

              <Tooltip title="Search / Reload">
                <IconButton onClick={() => handleMenuClick('refresh')} className={classes.toolIconStyle}>
                  <FaRedo />
                </IconButton>
              </Tooltip>
            </div>
          )}
          
          {isMobile && (
            <Tooltip title="Search">
              <IconButton onClick={() => setShowSearchDialog(true)} className={classes.toolIconStyle}>
                <FaSearch />
              </IconButton>
            </Tooltip>
          )}
          {!isMobile && (      
            <Tooltip title="Filter on current selection">
              <IconButton onClick={() => filterOnCurrentNode()} className={classes.toolIconStyle}>
                <FaFilter /> 
              </IconButton>
            </Tooltip>
          )}

          {viewGraph && showAdvanced && (
            <div style={{display: 'flex'}}>
              <Tooltip title={`File`}>
                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenOpen} className={classes.toolIconStyle}>
                  <FaSave/>
                </IconButton>
              </Tooltip>
              <Menu className={classes.menuStyle}
                id="simple-menu"
                anchorEl={anchorOpen}
                keepMounted
                open={Boolean(anchorOpen)}
                onClose={handleCloseOpen}
              >
                <MenuItem onClick={() => setShowSaveMapDialog(true)}>
                  <FaSave style={{ marginRight: '8px' }} />
                  Save Graph Coordinates
                </MenuItem>
                <MenuItem onClick={() => selectMap()}>
                  <FaFileExport style={{ marginRight: '8px' }} />
                  Load Graph Coordinates
                </MenuItem>
                <MenuItem
                  aria-controls="save-menu"
                  aria-haspopup="true"
                  onClick={handleOpenSaveAs}
                >
                  <FaSave style={{ marginRight: '8px' }} />
                  Save Graph as...
                  <FaChevronRight style={{ marginLeft: 'auto' }} />
                </MenuItem>
                <Menu
                  className={classes.menuStyle}
                  id="save-menu"
                  anchorEl={anchorOpenSaveAs}
                  keepMounted
                  open={Boolean(anchorOpenSaveAs)}
                  onClose={handleCloseSaveAs}
                >
                  <MenuItem onClick={() => handleMenuClick('saveCSV')}>Save as CSV</MenuItem>
                  <MenuItem onClick={() => handleMenuClick('saveJPG')}>Save as JPEG</MenuItem>
                  <MenuItem onClick={() => handleMenuClick('savePNG')}>Save as PNG</MenuItem>
                  <MenuItem onClick={() => handleMenuClick('savePDF')}>Save as PDF</MenuItem>
                </Menu>
              </Menu>
              <input
                type="file"
                ref={openFileInputRef}
                onChange={handleFileChange}
                accept=".csv"
                style={{ display: 'none' }}
              />
              <input
                type="file"
                ref={openPcapRef}
                onChange={handleFileChangePCAP}
                accept=".pcap"
                style={{ display: 'none' }}
              />
              <input
                type="file"
                ref={openNameTableInputRef}
                onChange={handleNameTableChange}
                accept=".json"
                style={{ display: 'none' }}
              />
            </div>
          )}

          <Tooltip title="View Menu">
            <IconButton onClick={handleMenuClickEl} className={classes.toolIconStyle}>
              <FaEye />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuCloseEl}
          >
            <MenuItem key="nftFilter" onClick={handleMenuItemClick(enableNFTFilter)}>
              <FaCertificate style={{ marginRight: 8 }} />
              NFT View
            </MenuItem>
            <MenuItem key="tokenFilter" onClick={handleMenuItemClick(() => { window.location.href = '/token' })}>
              <FaBitcoin style={{ marginRight: 8 }} />
              Token Volume Sphere
            </MenuItem>
            <MenuItem key="userTransactions" onClick={handleMenuItemClick(() => { window.location.href = '/token?users&organic' })}>
              <FaUsers style={{ marginRight: 8 }} />
              Token and Users Map
            </MenuItem>
            <MenuItem key="toggleViewGraph" onClick={handleMenuItemClick(() => { window.location.href = '/price' })}>
              <FaDollarSign style={{ marginRight: 8 }} />
              Token Price List       
            </MenuItem>
            <MenuItem key="etherscan" onClick={handleMenuItemClick(gotoEtherscan)}>
              <img src={etherscanLogo} style={{ width: 24, height: 24, marginRight: 8 }} />
              Etherscan
            </MenuItem>
            {viewGraph && [
              <MenuItem key="spinCamera" onClick={handleMenuItemClick(() => handleMenuClick('spin'))}>
                <FaSync style={{ marginRight: 8 }} />
                {spinCamera ? "Stop Spinning" : "Start Spinning"}
              </MenuItem>,
              <MenuItem key="clearGraph" onClick={handleMenuItemClick(resetGraph)}>
                <FaTimes style={{ marginRight: 8 }} />
                Clear the graph
              </MenuItem>
            ]}
            {(viewGraph && showAdvanced) && (
              <MenuItem key="advancedOptions" onClick={handleAdvancedMenuClickEl}>
                Advanced Options
                <FaChevronRight style={{ marginLeft: 'auto' }} />
              </MenuItem>
            )}
          </Menu>

          {showAdvanced && (
            <Menu
              anchorEl={anchorElAdvanced}
              keepMounted
              open={Boolean(anchorElAdvanced)}
              onClose={handleAdvancedMenuCloseEl}
            >
              {viewGraph && [
                <MenuItem key="useTime" onClick={handleAdvancedMenuItemClick(() => setUseTime(!useTime))}>
                  <FaClock style={{ marginRight: 8 }} />
                  Use time in the search
                </MenuItem>,
                <MenuItem key="autoRefresh" onClick={handleAdvancedMenuItemClick(toggleTimer)}>
                  <FaHourglassHalf style={{ marginRight: 8 }} />
                  {intervalEthId || intervalBscId ? 'Stop Auto Refresh' : 'Start Auto Refresh'}
                </MenuItem>,
                <MenuItem key="toggleLabels" onClick={handleAdvancedMenuItemClick(() => handleMenuClick('toggleLabels'))}>
                  <FaFont style={{ marginRight: 8 }} />
                  {showText ? "Hide Labels" : "Show Labels"}
                </MenuItem>,
                <MenuItem key="showChart" onClick={handleAdvancedMenuItemClick(() => setShowChart(!showChart))}>
                  <FaChartBar style={{ marginRight: 8 }} />
                  {showChart ? "Hide the transactions chart" : "Show the transactions chart"}
                </MenuItem>,
                <MenuItem key="showAdvancedToolbar" onClick={handleAdvancedMenuItemClick(() => setShowAdvancedToolbar(!showAdvancedToolbar))}>
                  <FaTools style={{ marginRight: 8 }} />
                  {showAdvancedToolbar ? "Hide advanced toolbar" : "Show advanced toolbar"}
                </MenuItem>,
              ]}
            </Menu>
          )}

          {(viewGraph && showAdvanced) && (
            <Navigation
              classes={classes}
              goToPreviousNode={goToPreviousNode}
              isTourRunning={isTourRunning}
              toggleTour={toggleTour}
              goToNextNodeManually={goToNextNodeManually}
            />
          )}

          <Tooltip title="Newsletter">
            <IconButton onClick={() => setShowNewsletter(true)} className={classes.toolIconStyle}>
              <FaNewspaper />
            </IconButton>
          </Tooltip>

          <Tooltip title="Options">
            <IconButton onClick={() => setShowOptionsPopup(true)} className={classes.toolIconStyle}>
              <FaCog />
            </IconButton>
          </Tooltip>

          <Tooltip title={`Documentation`}>
            <IconButton onClick={() => window.open('e3d_doc.html', '_blank')} className={classes.toolIconStyle}>
              <FaQuestionCircle/>
            </IconButton>
          </Tooltip>

        </div>

        <div className={classes.rightAlignedContainer}>
          {tokenPrice && (
            <WalletConnect activeNodeId={activeNodeId} activeNodeName={activeNodeName} ethPrice={rate} tokenPriceParam={tokenPrice} tokenUniswapVersion={tokenUniswapVersion} e3dPrice={e3dPrice}/>
          )}
          {isAuthenticated ? (
            <button onClick={handleLogoutConfirm} className={classes.linkButtonStyle}>{username}</button>
          ) : (
            <>
              <button onClick={() => setShowLogin(true)} className={classes.linkButtonStyle}>Login</button>
            </>
          )}
          {(!isMobile || ((isMobile && !isPortrait))) && viewGraph && (
            <Tooltip title="Toggle Token Name List View">
              <IconButton onClick={() => handleMenuClick('toggleList')} className={classes.toolIconStyle}>
                <FaBars />
              </IconButton>
            </Tooltip>
          )}

      </div>
      </div>

      {isLoading && <Spinner />}

      <div id="container" ref={containerRef} tabIndex="-1" className={classes.container}>
        {viewGraph && (
          <div id="leftPanel" className={classes.leftPanel}>

            {(!isMobile) && ( 
              <div style={{border: '2px solid white' }} >
                <div style={{border: (!mode3D) ? '3px solid green' : 'none'}} >
                  <Tooltip title="2D mode for distributions">
                    <IconButton onClick={() => setMode3D(false)} className={classes.toolIconStyle}>
                      <FaRegSquare/> 
                    </IconButton>
                  </Tooltip>
                </div>

                <div style={{border: (mode3D) ? '3px solid green' : 'none'}} >
                  <Tooltip title="3D mode for distributions">
                    <IconButton onClick={() => setMode3D(true)} className={classes.toolIconStyle}>
                      <FaCube /> 
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            )}
    
            <Tooltip title={`Distribute Everything as a ${(mode3D) ? 'Sphere' : 'Circle'}`}>
              <IconButton onClick={() => handleDistributeAll()} className={classes.toolIconStyle2}>
                {(mode3D) ? <FaGlobe /> : <FaCircle /> }
              </IconButton>
            </Tooltip>

            <Tooltip title={`Distribute as Pyramid or Triangle`}>
              <IconButton onClick={() => distributeNodesInPyramidOrTriangle()} className={classes.toolIconStyle2}>
                <FaExclamationTriangle />
              </IconButton>
            </Tooltip>

            <Tooltip title="Distribute Everything around Tube">
              <IconButton onClick={() => distributeConnectedNodesAroundTube()} className={classes.toolIconStyle2}>
                <FaGripLines /> 
              </IconButton>
            </Tooltip>

            <Tooltip title="Distribute Everything as a Grid or Cube">
              <IconButton onClick={() => distributeIconsInGridOrCube(graphData.nodes, globalSize)} className={classes.toolIconStyle2}>
                <FaTh /> 
              </IconButton>
            </Tooltip>

            {(!isMobile) && ( 
              <div>
                <Tooltip title="Distribute Icons in Straight Line">
                  <IconButton onClick={() => handleDistributeIconsAsLine()} className={classes.toolIconStyle2}>
                    <FaEllipsisH /> 
                  </IconButton>
                </Tooltip>

                <Tooltip title="Distribute Tokens">
                  <IconButton onClick={() => handleDistributeTokens()} className={classes.toolIconStyle2}>
                    <FaDotCircle /> 
                  </IconButton>
                </Tooltip>

                <Tooltip title="Distribute All Users">
                  <IconButton onClick={() => handleDistributeUsers()} className={classes.toolIconStyle2}>
                    <FaUserCircle /> 
                  </IconButton>
                </Tooltip>

                <Tooltip title="Distribute Users around Token">
                  <IconButton onClick={() => distributeUsersAroundToken()} className={classes.toolIconStyle2}>
                    <FaCircleNotch /> 
                  </IconButton>
                </Tooltip>

                <Tooltip title="Distribute Users around All Token">
                  <IconButton onClick={() => distributeUsersAroundAllTokens()} className={classes.toolIconStyle2}>
                    <FaObjectGroup /> 
                  </IconButton>
                </Tooltip>

                <Tooltip title="Lock Positions">
                  <IconButton onClick={() => handleDistributeLock()} className={classes.toolIconStyle2}>
                    <FaLock /> 
                  </IconButton>
                </Tooltip>

                <Tooltip title="Undo Distribute">
                  <IconButton onClick={() => handleDistributeUndo()} className={classes.toolIconStyle2}>
                    <FaUndo /> 
                  </IconButton>
                </Tooltip>
              </div>
            )}

            <Tooltip title="Compress Graph">
              <IconButton onClick={() => handleCompressGraph()} className={classes.toolIconStyle2}>
                <FaCompress /> 
              </IconButton>
            </Tooltip>

            <Tooltip title="Expand Graph">
              <IconButton onClick={() => handleExpandGraph()} className={classes.toolIconStyle2}>
                <FaExpand /> 
              </IconButton>
            </Tooltip>

            <Tooltip title="Zoom In">
              <IconButton onClick={() => zoomIn()} className={classes.toolIconStyle2}>
                <FaPlus /> 
              </IconButton>
            </Tooltip>

            <Tooltip title="Zoom Out">
              <IconButton onClick={() => zoomOut()} className={classes.toolIconStyle2}>
                <FaMinus /> 
              </IconButton>
            </Tooltip>

          </div>
        )}

          {viewGraph && (
            <div>
              {isFirstLoad && <div className={classes.spacepacketText}><Spinner/></div>}
            </div>
          )}
    
          {((viewGraph && filterToken) || filterNFT) && (
            <div width={dimensions.width} height={dimensions.height} className={classes.graphContainer}>
              {viewGraph && isDataReady && (

                <div key={graphKey} id="graphContainer" ref={graphContainerRef} tabIndex="-1" width={dimensions.width} height={dimensions.height} className={classes.graphContainer}>
                  <div className={classes.forceGraphComponent}>
                    <ForceGraphComponent width={dimensions.width} height={dimensions.height} {...getForceGraphProps()} />
                  </div>

                  {/*}
                  {(!isMobile && showChart && chartData) && (<FutcoChart rawData={chartData} showList={showBottom} onClose={handleCloseChart}/>)}
                  {*/}
                </div>
              )}
              
              {!viewGraph && isDataReady && (
                <TokenContainer 
                  imageKey={imageKey} 
                  tokenContainerRef={tokenContainerRef} 
                  dimensions={dimensions} 
                  getCurrentNodeIcon={getCurrentNodeIcon} 
                  getCurrentNode={getCurrentNode} 
                  futLogoIcon200={futLogoIcon200} 
                  graphData={graphData} 
                  currentNodeIndex={currentNodeIndex} 
                  setCurrentNodeIndex={setCurrentNodeIndex} 
                  setImageKey={setImageKey} 
                  imgRefs={imgRefs} 
                  goToPreviousNode={goToPreviousNode} 
                  goToNextNodeManually={goToNextNodeManually} 
                  filterNFT={filterNFT}
                  filterToken={filterToken}
                  showChart={showChart}
                  tokenData={tokenData}
                  handleCloseTokenInfo={handleCloseTokenInfo}
                  isMobile={isMobile}
                  isPortrait={isPortrait}
                  setIsTourRunning={setIsTourRunning}
                  useAspectRatio={useAspectRatio}
                  userID={userID}
                  setShowLogin={setShowLogin}
                />
              )}
            </div>
          )}

          {isModalOpen && (
            <Modal onClose={() => setIsModalOpen(false)}>
              <iframe
                src={`https://etherscan.io/dex/uniswapv2/${selectedNode.id}`}
                width="100%"
                height="400px"
                frameborder="0"
                allowtransparency="true"
                scrolling="no"
              ></iframe>
            </Modal>
          )}


          {((viewGraph || filterNFT) && showPanel && memoizedGraphData.nodes.length) && (
            <div id="panelContainer" className={classes.panelContainer}>
              <NodeList
                ref={nodeListRef}
                graphData={memoizedGraphData}
                graphRef={memoizedGraphRef}
                dataSource={memoizedDataSource}
                linkCount={linkCount}
                getTransactions={getTransactions}
                handleNodeClick={handleNodeClick}
                stopCameraSpin={stopCameraSpin}
                isMobile={isMobile}
                isPortrait={isPortrait}
                tokenType={filterNFT ? "NFT" : "Token"}
                tokenList={memoizedTokenList}
                viewGraph={viewGraph}
              />

            </div>
          )}
        
      </div>

      {(memoizedGraphData.nodes.length && ((!viewGraph && filterToken) || (showBottom && isMobile && isPortrait))) && (
        <div id="linkContainer" className={classes.linkContainer}>
          <NodeList
            ref={nodeListRef}
            graphData={memoizedGraphData}
            graphRef={memoizedGraphRef}
            dataSource={memoizedDataSource}
            linkCount={linkCount}
            getTransactions={getTransactions}
            handleNodeClick={handleNodeClick}
            stopCameraSpin={stopCameraSpin}
            isMobile={isMobile}
            isPortrait={isPortrait}
            tokenType={filterNFT ? "NFT" : "Token"}
            tokenList={memoizedTokenList}
            viewGraph={viewGraph}
          />
        </div>
      )}

      {(filterToken && showChart && tokenData) && (
        <TokenInfoComponent data={tokenData} onClose={handleCloseTokenInfo} isMobile={isMobile} isPortrait={isPortrait}/>
      )}

      {(showBottom && !isMobile) && (
        <div id="linkContainer" className={classes.linkContainer}>
            { (dataSource == PCAP) 
              ? <LinkListPCAP 
                  graphData={graphData} 
                  graphRef={graphRef} 
                  mapData={mapData} 
                  stopCameraSpin={stopCameraSpin} 
                  handleNodeClick={handleNodeClick}
                  handleLinkClick={handleLinkClick}
                  activeLinkId={activeLinkId}
                /> 
              : <LinkList 
                  graphData={graphData} 
                  graphRef={graphRef} 
                  dataSource={dataSource} 
                  rate={rate} 
                  stopCameraSpin={stopCameraSpin} 
                  handleNodeClick={handleNodeClick}
                  handleLinkClick={handleLinkClick}
                  activeLinkId={activeLinkId}
                  activeNodeId={activeNodeId}
                  transactions={transactions}
                />  
            } 
        </div>
      )}  

      {(!isMobile && !isPortrait && filterToken) && (
        <div id="bottomContainer" className={classes.bottomContainer}> 
          {((!isMobile) || (isMobile && isPortrait)) && (
            <Tooltip title="Toggle Transaction List View">
              <Button onClick={() => handleMenuClick('toggleBottom')} className={classes.linkButtonStyle}>
                <FaBars /> {/* Bars Icon */}
              </Button>
            </Tooltip>
          )}
      
          {(viewGraph && showAdvancedToolbar && graphData && graphData.nodes && graphData.nodes.length && isDataReady) && (
            <div className={`${classes.toolbarContainer} ${viewGraph && showAdvancedToolbar ? '' : classes.hidden}`}>
              <div className={classes.advancedToolbar}>
                {/* Range Selector */}
                <div className={classes.toolbarSection}>
                  <label className={classes.toolbarLabel}>
                    <span title="Select the range interval">Range:</span>
                  </label>
                  <IconButton
                    aria-controls="interval-menu"
                    aria-haspopup="true"
                    onClick={handleOpenInterval}
                    className={classes.iconButton}
                  >
                    {formatIntervalRange(rangeInterval.toString())}
                  </IconButton>
                  <Menu
                    id="interval-menu"
                    anchorEl={anchorInterval}
                    keepMounted
                    open={Boolean(anchorInterval)}
                    onClose={handleCloseInterval}
                    className={classes.menuStyle} 
                  >
                    {['1m', '5m', '10m', '30m', '1h', '1d', '1w'].map((interval) => (
                      <MenuItem key={interval} onClick={() => handleIntervalSelection(interval)}>
                        {interval}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
            
                {/* Time Selector */}
                <div className={classes.toolbarSection}>
                  <label className={classes.toolbarLabel}>
                    <span title="Adjust time range">Time:</span>
                  </label>
                  <div className={classes.compactContainer}>
                    <IconButton onClick={goToPreviousTimeRange} className={classes.iconButton}>
                      <FaArrowLeft />
                    </IconButton>
                    <div className={classes.dateDisplay}>
                      <FaCalendarAlt
                        className={classes.calendarIcon}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering the span's click
                          setShowPicker(true); // Always show the picker
                        }}
                      />
                      <div>
                        <span>{new Date(dateRange.startDate * 1000).toLocaleString()}</span>
                        {showPicker && (
                          <div className={classes.datePickerOverlay}>
                            <DateTimeRangePicker
                              startDate={dateRange.startDate || Math.floor(Date.now() / 1000)}
                              endDate={dateRange.endDate || Math.floor(Date.now() / 1000)}
                              onDateChange={(newDateRange) => {
                                handleDateChange(newDateRange);
                                setShowPicker(false); // Close the picker after selection
                              }}
                              className={classes.dateTimePicker}
                            />
                          </div>
                        )}
                        </div>
                    </div>
                    <IconButton onClick={goToNextTimeRange} className={classes.iconButton}>
                      <FaArrowRight />
                    </IconButton>
                  </div>
                </div>
            
                {/* Duration Display */}
                <div className={classes.toolbarSection}>
                  <label className={classes.toolbarLabel}>
                    <span title="Duration of the range">Duration:</span>
                  </label>
                  <span className={classes.durationDisplay}>
                    {dateRange.startDate ? formatDuration(dateRange.startDate, dateRange.endDate) : '00:00'}
                  </span>
                </div>
            
                {/* Max Transactions */}
                <div className={classes.toolbarSection}>
                  <label className={classes.toolbarLabel}>
                    <span title="Set maximum transactions">Max:</span>
                  </label>
                  <input
                    type="number"
                    id="maxTransactions"
                    name="maxTransactions"
                    value={maxTransactions}
                    onChange={handleMaxTransactionsChange}
                    className={classes.inputField}
                    min={1}
                    max={100000}
                  />
                </div>
              </div>
            
              {/* Visualization Statistics */}
              {viewGraph && graphData && graphData.nodes && graphData.links && (
                <div className={classes.statisticsContainer}>
                  <div>
                    <label className={classes.statisticsLabel}>Tokens:</label>
                    <span className={classes.statisticsValue}>{graphData.nodes.length}</span>
                  </div>
                  <div>
                    <label className={classes.statisticsLabel}>Links:</label>
                    <span className={classes.statisticsValue}>{graphData.links.length}</span>
                  </div>
                  <div>
                    <label className={classes.statisticsLabel}>X:</label>
                    <span id="X" className={classes.statisticsValue}>0</span>
                  </div>
                  <div>
                    <label className={classes.statisticsLabel}>Y:</label>
                    <span id="Y" className={classes.statisticsValue}>0</span>
                  </div>
                  <div>
                    <label className={classes.statisticsLabel}>Z:</label>
                    <span id="Z" className={classes.statisticsValue}>0</span>
                  </div>
                </div>
              )}
            
              {/* Progress Bar */}
              {progressVisible && (
                <div className={classes.progressBarContainer}>
                  <div className={classes.progressBar} style={{ width: `${progress}%` }} />
                </div>
              )}
            </div>
          )}  
        </div>
      )}
    </div>
  );
}

export default App;

