import React, { useState, useEffect } from 'react';
import { makeStyles, TextField, Button } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { FaWallet, FaExchangeAlt, FaDollarSign, FaBitcoin } from 'react-icons/fa';
import { IconButton, Tooltip } from '@material-ui/core';
import { metamaskLogo } from './config';
import { ethers } from 'ethers';
import { futLogoIcon200 } from './config';

const useStyles = makeStyles((theme) => ({
    swapDialog: {
        color: 'lightgray',
    },
    swapText: {
        fontFamily: "'Roboto Mono', monospace",
        fontSize: '14px',
    },
    inputStyle: {
        width: '180px'
    },
    row: {
        margin: '0px',
        padding: '0px', 
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    linkButtonStyle: {
        color: 'white',
        backgroundColor: 'transparent',
        border: 'none',
        padding: '2px 4px', // Further reduce padding
        margin: '0',
        textDecoration: 'none',
        cursor: 'pointer',
        fontSize: '12px',
        textTransform: 'none',
        lineHeight: '1', // Reduce line height
    },
    walletButton: {
        borderRadius: '20px',  // Rounded corners
        borderColor: 'white',
        borderWidth: '2px',
        borderStyle: 'solid',
        backgroundColor: '#D3D3D3', // Light gray background
        color: theme.palette.primary.main, // Text color using primary from theme
        marginRight: '20px',
        height: '30px', // Smaller height
        '&:hover': {
            backgroundColor: '#B0B0B0' // Darker gray on hover
        }
    },
    toolIconStyle: {
        margin: theme.spacing(0.0), // No external margins
        padding: '5px', // Reduce padding inside the IconButton
        color: 'rgba(255, 255, 255, 0.7)', // Makes the color less bright
        '&:hover': {
            color: 'rgba(255, 255, 255, 1.0)', // Slightly brighter on hover
        },
        '& svg': { // Assuming the FontAwesome icon renders as an <svg> element
            fontSize: '0.9em',
        },
    },
    stack: {
        position: 'relative',
        display: 'inline-block',
        width: '1em',
        height: '1em',
      },
    bitcoin: {
        position: 'absolute',
        width: '1em',
        height: '1em',
        left: '0.1em', // Adjust this value to move the icon horizontally
        top: '0.0em', // Adjust this value to move the icon vertically
    },
    exchange: {
        position: 'absolute',
        width: '1em',
        height: '1em',
        left: '0.1em', // Adjust this value to move the icon horizontally
    },
    linkButtonStyle: {
        fontSize: '10px',
      },
}));

function WalletButton({ onClick, label }) {
    const classes = useStyles();
  
    return (
      <Button
        onClick={onClick}
        className={classes.walletButton}
      >
        {label}
      </Button>
    );
}

const SwapDialog = ({ open, onClose, tokenName, tokenAddress, provider, walletAddress, tokenPriceParam, tokenUniswapVersion, ethPrice }) => {
    const classes = useStyles();
    const [ethAmount, setEthAmount] = useState('0.01'); // Default or last used amount
    const [ethBalance, setEthBalance] = useState('0.00');
    const [errorText, setErrorText] = useState("");
    const [tokensPerEth, setTokensPerEth] = useState(0);
    const [tokenPrice, setTokenPrice] = useState(tokenPriceParam);
    const [tokenCount, setTokenCount] = useState(0);

    // Uniswap V2 and V3 Router Addresses
    const UNISWAP_V2_ROUTER_ADDRESS = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
    const UNISWAP_V3_ROUTER_ADDRESS = "0xE592427A0AEce92De3Edee1F18E0157C05861564";

    // Uniswap V2 Router ABI
    const UNISWAP_V2_ROUTER_ABI = [
        {
            "constant": false,
            "inputs": [
                { "name": "amountOutMin", "type": "uint256" },
                { "name": "path", "type": "address[]" },
                { "name": "to", "type": "address" },
                { "name": "deadline", "type": "uint256" }
            ],
            "name": "swapExactETHForTokens",
            "outputs": [{ "name": "amounts", "type": "uint256[]" }],
            "payable": true,
            "stateMutability": "payable",
            "type": "function"
        }
    ];

    // Uniswap V3 Router ABI
    const UNISWAP_V3_ROUTER_ABI = [
        {
            "inputs": [
                {
                    "components": [
                        { "internalType": "address", "name": "tokenIn", "type": "address" },
                        { "internalType": "address", "name": "tokenOut", "type": "address" },
                        { "internalType": "uint24", "name": "fee", "type": "uint24" },
                        { "internalType": "address", "name": "recipient", "type": "address" },
                        { "internalType": "uint256", "name": "deadline", "type": "uint256" },
                        { "internalType": "uint256", "name": "amountIn", "type": "uint256" },
                        { "internalType": "uint256", "name": "amountOutMinimum", "type": "uint256" },
                        { "internalType": "uint160", "name": "sqrtPriceLimitX96", "type": "uint160" }
                    ],
                    "internalType": "struct ISwapRouter.ExactInputSingleParams",
                    "name": "params",
                    "type": "tuple"
                }
            ],
            "name": "exactInputSingle",
            "outputs": [{ "internalType": "uint256", "name": "amountOut", "type": "uint256" }],
            "stateMutability": "payable",
            "type": "function"
        }
    ];

    // Uniswap V2 Router addresse and ABI details
    const dexContractAddress = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
    const dexContractABI = [
            {
                "constant": false,
                "inputs": [
                    { "name": "amountOutMin", "type": "uint256"},
                    { "name": "path", "type": "address[]"},
                    { "name": "to", "type": "address" },
                    { "name": "deadline", "type": "uint256" }
                ],
                "name": "swapExactETHForTokens",
                "outputs": [
                    { "name": "amounts", "type": "uint256[]" }
                ],
                "payable": true,
                "stateMutability": "payable",
                "type": "function"
            },
            {
                "constant": false,
                "inputs": [
                    { "name": "amountOutMin", "type": "uint256"},
                    { "name": "path", "type": "address[]" },
                    { "name": "to", "type": "address" },
                    { "name": "deadline", "type": "uint256" }
                ],
                "name": "swapExactTokensForETH",
                "outputs": [
                    { "name": "amounts", "type": "uint256[]" }
                ],
                "payable": false,
                "stateMutability": "nonpayable",
                "type": "function"
            },
            {
                "name": "getAmountsOut",
                "type": "function",
                "inputs": [
                  { "type": "uint256", "name": "amountIn" },
                  { "type": "address[]", "name": "path" }
                ],
                "outputs": [{ "type": "uint256[]", "name": "" }],
                "stateMutability": "view"
            }         
    ]; // ABI of the DEX contract

    const uniswapV3ContractAddress = "0xE592427A0AEce92De3Edee1F18E0157C05861564"; // Uniswap V3 Router
    const wethAddressRaw = "0xC02aaa39b223FE8D0A0e5C4F27eAD9083C756Cc2";  // Mainnet WETH Address
    const wethAddressLower = wethAddressRaw.toLowerCase();
    const wethAddress = ethers.utils.getAddress(wethAddressLower); 
    
    const handleClose = async () => {
        setErrorText("");
        onClose();
    }

    
    const handleConfirm = async () => {
        if (!provider) {
            console.error("Wallet not connected or provider not set.");
            onClose();
            return;
        }

        try {
            const signer = provider.getSigner();

            // Select router address and ABI based on the version
            console.log("Version:", tokenUniswapVersion);
            const isV3 = tokenUniswapVersion === 3;
            const routerAddress = isV3 ? UNISWAP_V3_ROUTER_ADDRESS : UNISWAP_V2_ROUTER_ADDRESS;
            const routerABI = isV3 ? UNISWAP_V3_ROUTER_ABI : UNISWAP_V2_ROUTER_ABI;

            // Initialize the contract
            const contract = new ethers.Contract(routerAddress, routerABI, signer);

            // Common variables
            const tokenIn = wethAddress;
            const tokenOut = ethers.utils.getAddress(tokenAddress.toLowerCase());
            const deadline = Math.floor(Date.now() / 1000) + 1800; // Current time + 30 minutes
            const amountIn = ethers.utils.parseEther(ethAmount); // ETH input amount

            if (isV3) {
                // Uniswap V3: exactInputSingle
                const fee = 3000; // 0.3% fee tier
                const amountOutMinimum = 0; // No slippage for now (update later for slippage tolerance)
                const sqrtPriceLimitX96 = 0; // No price limit

                const swapParams = {
                    tokenIn,
                    tokenOut,
                    fee,
                    recipient: walletAddress,
                    deadline,
                    amountIn,
                    amountOutMinimum,
                    sqrtPriceLimitX96
                };

                // Execute the swap
                const tx = await contract.exactInputSingle(swapParams, {
                    value: amountIn // Send ETH value directly
                });

                console.log("Transaction submitted:", tx);
                await tx.wait();
                console.log("Transaction confirmed!");
            } else {
                // Uniswap V2: swapExactETHForTokens
                const path = [tokenIn, tokenOut]; // Swap path
                const amountOutMinimum = 0; // No slippage for now (update later for slippage tolerance)

                // Encode the function call for V2
                const iface = new ethers.utils.Interface(routerABI);
                const data = iface.encodeFunctionData("swapExactETHForTokens", [
                    amountOutMinimum, // Minimum tokens to receive
                    path, // Swap path
                    walletAddress, // Recipient
                    deadline // Deadline
                ]);

                // Build transaction parameters
                const params = {
                    from: walletAddress,
                    to: routerAddress,
                    value: amountIn.toHexString(), // ETH amount
                    data // Encoded transaction data
                };

                // Send the transaction via MetaMask
                await window.ethereum.request({
                    method: "eth_sendTransaction",
                    params: [params]
                });

                console.log("Transaction submitted to MetaMask!");
            }

            onClose(); // Close dialog after submission
        } catch (error) {
            console.error("Transaction failed:", error);

            // Provide user-friendly error feedback
            if (error.code !== "ACTION_REJECTED") {
                const message = `Error: ${error.message}`;
                setErrorText(message);
            }
        }
    };
            
    
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setErrorText("");
            setEthAmount(event.target.value);
        }
    };

    const handleEtherscan = (event) => {
        const url = `https://etherscan.io/token/${tokenAddress}`;
        window.open(url, '_blank');
    };

    useEffect(() => {
        const initialize = async () => {
            setErrorText("");
            const signer = provider.getSigner();

            // Get the user's address
            const address = await signer.getAddress();
            console.log('Address:', address);
        
            // Get the user's ETH balance
            const balance = await provider.getBalance(address);
            const ethBalance1 = parseFloat(ethers.utils.formatEther(balance)).toFixed(4);
            console.log('ETH Balance:', ethBalance1);
            setEthBalance(ethBalance1);
            //if (ethBalance1 < ethAmount) {
            //    setEthAmount(ethBalance1);
            //}

            const tokensPerEth1 = parseFloat(ethPrice / tokenPrice).toFixed(4);
            setTokensPerEth(tokensPerEth1);

            const tokenCount1 = parseFloat(ethAmount * tokensPerEth1).toFixed(4);
            setTokenCount(tokenCount1);
        };

        if (open) {
            initialize();
        }
    }, [open,ethAmount]);
    
    useEffect(() => {
        const tokenPrice1 = parseFloat(tokenPriceParam).toFixed(7);
        setTokenPrice(tokenPrice1);
    }, [tokenPriceParam]);

    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="swap-dialog-title" className={classes.swapDialog}>
        <DialogTitle id="swap-dialog-title">Token Swap Details</DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <DialogContentText>
                <div>
                    {`You are swapping `}
                    <span style={{ color: 'orange' }}>{`ETH`}</span>
                    {` for `}
                    <span style={{ color: 'orange' }}>{`${tokenName}`}</span>
                </div>
                <div>
                    <Tooltip title="Validate address on etherscan.io">
                        <button onClick={handleEtherscan} className={classes.linkButtonStyle}>
                            <span>{`Verify token addres on etherscan.io`}</span>
                        </button>
                    </Tooltip>
                </div>
            </DialogContentText>
            <div className={classes.row}>
                <div className={classes.row}>
                    <span className={classes.swapText}>{'ETH price: '}</span>
                    <span style={{ color: 'green' }} className={classes.swapText}>{ethPrice}</span>
                </div>
                <div className={classes.row}>
                    <span className={classes.swapText}>{'Token price: '}</span>
                    <span style={{ color: 'green' }} className={classes.swapText}>{tokenPrice}</span>
                </div>
                <div className={classes.row}>
                    <span className={classes.swapText}>{'Tokens per ETH: '}</span>
                    <span style={{ color: 'green' }} className={classes.swapText}>{tokensPerEth}</span>
                </div>
                <div className={classes.row}>
                    <span className={classes.swapText}>{'Your ETH balance: '}</span>
                    <span style={{ color: 'green' }} className={classes.swapText}>{ethBalance}</span>
                </div>
            </div>
  
            <TextField
                autoFocus
                margin="dense"
                id="ethAmount"
                label="Amount of ETH to swap"
                type="number"
                width="200px"
                variant="outlined"
                value={ethAmount}
                onKeyDown={handleKeyDown}
                onChange={(e) => setEthAmount(e.target.value)}
                className={classes.inputStyle}
                inputProps={{ step: "0.01" }} // Allows decimal increments
            />

            <div className={classes.row}>
                <div className={classes.row}>
                    <span className={classes.swapText}>{'Tokens after swap: '}</span>
                    <span style={{ color: 'green' }} className={classes.swapText}>{tokenCount}</span>
                </div>
            </div>

          <span style={{ color: 'red' }}>{errorText}</span>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Continue to Wallet
          </Button>
        </DialogActions>
      </Dialog>
    );
};
  

const WalletConnect = ({ activeNodeId, activeNodeName, tokenPriceParam, tokenUniswapVersion, ethPrice, e3dPrice }) => {
    const [walletAddress, setWalletAddress] = useState('');
    const [provider, setProvider] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogOpenE3D, setDialogOpenE3D] = useState(false);
    const classes = useStyles();

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleOpenDialogE3D = () => {
        setDialogOpenE3D(true);
    };

  const handleCloseDialogE3D = () => {
        setDialogOpenE3D(false);
    };

    const requestAccount = async () => {
        if (window.ethereum) {
            try {
                const accounts = await window.ethereum.request({
                    method: 'eth_requestAccounts'
                });
                setWalletAddress(accounts[0]);
                const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                console.log(provider1);
                setProvider(provider1);
            } catch (error) {
                console.error("Failed to get wallet access", error);
            }
        } else {
            alert('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
        }
    }; 

    useEffect(() => {
        const checkIfWalletIsConnected = async () => {
            if (window.ethereum) {
                try {
                    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
                    if (accounts.length > 0) {
                        setWalletAddress(accounts[0]);
                        const provider1 = new ethers.providers.Web3Provider(window.ethereum);
                        console.log(provider1);
                        setProvider(provider1);
                    }
                } catch (error) {
                    console.error("Failed to retrieve wallet", error);
                }
            }
        };

        checkIfWalletIsConnected();
    }, []);
    
    const disconnectWallet = () => {
        // Clear the wallet address state
        setWalletAddress(null);
        
        // Clear the provider state if you store it
        setProvider(null);
      
        // Clear any other state or side effects as needed
        console.log("Wallet disconnected. State cleared.");
      
        // If you need to do any further clean-up, do it here
    };
 
    return (
        <div>
            {walletAddress ? (
                <div style={{display: 'flex', paddingRight: '10px'}}>
                    <Tooltip title="Swap ETH for current Token">
                        <IconButton enabled={tokenPriceParam} onClick={handleOpenDialog} className={classes.toolIconStyle}>
                            <span className={classes.stack}>
                                <FaBitcoin className={classes.bitcoin} style={{width:'25px', height:'25px', color: 'green'}}/>
                                <FaExchangeAlt className={classes.exchange} />
                            </span>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Swap ETH for E3D Token">
                        <IconButton enabled={tokenPriceParam} onClick={handleOpenDialogE3D} className={classes.toolIconStyle}>
                            <span className={classes.stack}>
                                <img src={futLogoIcon200} style={{width:'25px', height:'25px', color: 'white'}} alt="" className={classes.bitcoin}/>
                                <FaExchangeAlt className={classes.exchange} />
                            </span>
                        </IconButton>
                    </Tooltip>
                    <div style={{border: '1px solid green' }} >
                        <Tooltip title="Disconnect Wallet">
                            <IconButton onClick={disconnectWallet} className={classes.toolIconStyle}>
                                <img src={metamaskLogo} style={{width:'30px', height:'30px'}} alt="Connect Wallet"/> 
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            ) : (
                <div>
                    <Tooltip title="Connect Wallet">
                        <IconButton onClick={requestAccount} className={classes.toolIconStyle}>
                            <img src={metamaskLogo} style={{width:'30px', height:'30px'}} alt="Connect Wallet"  /> 
                        </IconButton>
                    </Tooltip>
                </div>
            )}
            <SwapDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                tokenName={activeNodeName}
                tokenAddress={activeNodeId}
                provider={provider}
                walletAddress={walletAddress}
                tokenUniswapVersion={tokenUniswapVersion}
                tokenPriceParam={tokenPriceParam}
                ethPrice={ethPrice}
            />
            <SwapDialog
                open={dialogOpenE3D}
                onClose={handleCloseDialogE3D}
                tokenName={"E3DToken"}
                tokenAddress={"0x6488861b401F427D13B6619C77C297366bCf6386"}
                provider={provider}
                walletAddress={walletAddress}
                tokenPriceParam={e3dPrice}
                tokenUniswapVersion={tokenUniswapVersion}
                ethPrice={ethPrice}
            />
        </div>
    );
};

export default WalletConnect;
