// NodeLists.js
import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle, useMemo } from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import useStyles from './sharedStyles'; 
import { useTable, useSortBy, useResizeColumns } from 'react-table';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { SERVER_NAME, ETHERSCAN } from './config';
import { genericUserIcon } from './config';
import Web3 from 'web3';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import { useInView } from 'react-intersection-observer';

const web3 = new Web3();

const PriceDetailsDialog = ({ onClose }) => {
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Price Calculation Details</DialogTitle>
      <DialogContent>
        <PriceDetailsContent />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PriceDetailsContent = () => {
  return (
    <div>
      <Typography variant="body1"><strong>Note:</strong> Token values are derived from on-chain data.</Typography>
      <List>
        <ListItem>
          <ListItemText primary="Prices are updated every five minutes." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Token valuation is established via pairing with WETH to determine equivalent ETH amounts." />
        </ListItem>
        <ListItem>
          <ListItemText primary="These amounts are then adjusted according to current USD price of Ethereum." />
        </ListItem>
      </List>
      <Typography variant="body1">To sum it up, the price should be thought of and used as an estimate, and may not match other exchanges exactly. But hey, they don't match each other either. ;-)</Typography>
    </div>
  );
};

function formatPrice(price, decimals = 2) {
  //if (price <= 0 || price > 100000 || price < 1e-7) {
  //  return '';
  //}

  // Convert to string using fixed maximum number of decimal places
  const maxDecimals = 10; // You can adjust this for maximum desired decimals
  let priceStr = price.toFixed(maxDecimals);

  // Find the position of the first non-zero digit after the decimal point
  const decimalIndex = priceStr.indexOf('.');
  const firstNonZeroIndex = priceStr.slice(decimalIndex + 1).search(/[1-9]/) + decimalIndex + 1;

  // Determine the number of decimal places to show
  let requiredDecimals;
  if (firstNonZeroIndex > decimalIndex && firstNonZeroIndex - decimalIndex > 2) {
      // If the first non-zero digit is within the first two places, use that precision
      requiredDecimals = firstNonZeroIndex - decimalIndex;
  } else {
      // Otherwise, use the specified number of decimal places
      requiredDecimals = Math.min(decimals, priceStr.length - decimalIndex - 1); // Avoid exceeding actual length
  }

  // Return the formatted price with the determined number of decimal places
  return '$' + Number(priceStr).toFixed(requiredDecimals).toString();
}

function formatMarketCap(marketCap) {
  // Convert to a number and then to a string with commas
  const formattedMarketCap = Number(marketCap).toLocaleString();
  return '$' + formattedMarketCap;
}

function formatShortAddress(address) {
  if (address && address.length > 8) {
    const start = address.substring(0, 6); // '0x' + first 4 characters
    const end = address.substring(address.length - 4); // last 4 characters
    return `${start}…${end}`; // Using the ellipsis character directly
  }
  return address;
}


const handleLinkClick = (graphData, graphRef, id) => {
  const link = graphData.links.find((n) => n.tx === id);
  if (link) {
    const node = graphData.nodes.find((n) => n.id === link.target.id);
    if (node) {
      const distance = 40;
      const distRatio = 1 + distance/Math.hypot(node.x, node.y, node.z);
  
      graphRef.current.cameraPosition(
        { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, // new position
        node, // lookAt ({ x, y, z })
        3000  // ms transition duration
      );
    }
  }
};


const arePropsEqual = (prevProps, nextProps) => {
  // If activeNodeId has changed, return true to prevent a re-render
  if (prevProps.activeNodeId !== nextProps.activeNodeId) {
    return true;
  }

  // Otherwise, do a shallow comparison of the other props
  for (let key in prevProps) {
    if (key !== 'activeNodeId' && prevProps[key] !== nextProps[key]) {
      return false;
    }
  }

  return true;
};


export const NodeList = React.memo(forwardRef((props, ref) => {
    const { graphData, graphRef, dataSource, linkCount, getTransactions, stopCameraSpin, handleNodeClick, isMobile, isPortrait, tokenType, tokenList, viewGraph } = props;
    const classes = useStyles();
    const graphDataRef = useRef(graphData);
    const dataSourceRef = useRef(dataSource);
    const nodeRefs = useRef({});
    const nodeListRef = useRef();
    const [showPriceDetails, setShowPriceDetails] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [prevNodeId, setPrevNodeId] = useState(null);
    const [activeNodeId, setActiveNodeId] = useState(null);
    const [trigger1, setTrigger1] = useState(0);

    // Create a filtered copy of tokenList based on viewGraph
    const tokenListCopy = useMemo(() => {
        if (!viewGraph) return tokenList;
        const graphAddresses = new Set(graphData.nodes.map(node => node.id));
        return tokenList.filter(token => graphAddresses.has(token.id));
    }, [viewGraph, tokenList, graphData]);

    // Log when a prop changes
    /*
    useEffect(() => {
      console.log('graphData changed');
    }, [graphData]);
  
    useEffect(() => {
      console.log('graphRef changed');
    }, [graphRef]);
  
    useEffect(() => {
      console.log('dataSource changed');
    }, [dataSource]);
  
    useEffect(() => {
      console.log('linkCount changed');
    }, [linkCount]);
  
    useEffect(() => {
      console.log('getTransactions changed');
    }, [getTransactions]);
  
    useEffect(() => {
      console.log('stopCameraSpin changed');
    }, [stopCameraSpin]);
  
    useEffect(() => {
      console.log('handleNodeClick changed');
    }, [handleNodeClick]);
  
    useEffect(() => {
      console.log('isMobile changed');
    }, [isMobile]);
  
    useEffect(() => {
      console.log('isPortrait changed');
    }, [isPortrait]);
  
    useEffect(() => {
      console.log('tokenType changed');
    }, [tokenType]);
  
    useEffect(() => {
      console.log('tokenList changed');
    }, [tokenList]);
  */


    useEffect(() => {
      graphDataRef.current = graphData;
      dataSourceRef.current = dataSource;
    }, [graphData, dataSource]);

    useEffect(() => {
      const preventDefaultKeyDown = (event) => {
          if (event.key === 'ArrowDown') {
              event.preventDefault();
          }
      };
      document.addEventListener('keydown', preventDefaultKeyDown);
      return () => document.removeEventListener('keydown', preventDefaultKeyDown);
   }, []);

    useEffect(() => {
      const nodeListElement = nodeListRef.current;
  
      const handleMouseEnter = () => {
          nodeListElement.focus();
      };
  
      const handleMouseLeave = () => {
          nodeListElement.blur(); // Optional, based on desired behavior
      };
  
      nodeListElement.addEventListener('mouseenter', handleMouseEnter);
      nodeListElement.addEventListener('mouseleave', handleMouseLeave);
  
      return () => {
          nodeListElement.removeEventListener('mouseenter', handleMouseEnter);
          nodeListElement.removeEventListener('mouseleave', handleMouseLeave);
      };
    }, []);
 
    useEffect(() => {
      const nodeListElement = nodeListRef.current;
  
      const handleFocus = () => {
          nodeListElement.focus();
      };
  
      nodeListElement.addEventListener('focus', handleFocus);
  
      return () => {
          nodeListElement.removeEventListener('focus', handleFocus);
      };
    }, []);

    useEffect(() => {
        //console.log("Effect running", { activeNodeId, rowsLength: rows.length });

        const handleKeyDown = (event) => {
            if ((event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
                // Check if the nodeListContainer is currently focused
                if (document.activeElement === nodeListRef.current || tokenType === "NFT") {
                    const currentIndex = rows.findIndex(row => row.original.id === activeNodeId);
                    const newIndex = (event.key === 'ArrowDown') ? currentIndex + 1 : currentIndex - 1;
    
                    // Ensure newIndex is within bounds
                    if (newIndex >= 0 && newIndex < rows.length) {
                        setCurrentIndex(newIndex);
                        const newActiveNodeId = rows[newIndex].original.id;
                        
                        // Update the active node
                        handleNodeClick(dataSourceRef.current, graphDataRef.current, graphRef, newActiveNodeId, false, tokenType);
                        
                        // Scroll to the new node if it's not visible
                        if (!isRowVisible(newIndex)) {
                            scrollToActiveNode(newActiveNodeId);
                        }
                    }
                }
            }
        };
      
        document.addEventListener('keydown', handleKeyDown);
 
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    }, [activeNodeId, handleNodeClick, dataSourceRef, graphDataRef, graphRef, setCurrentIndex ]); 

    const debounce = (func, delay) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), delay);
      };
    };
    
 
    const scrollToActiveNodeLocal = (nodeId) => {  
      const element = nodeRefs.current[nodeId];

      if (element) {
        if (prevNodeId) {
          const prevElement = nodeRefs.current[prevNodeId];
          prevElement.classList.remove('highlight');
        }
        setPrevNodeId(nodeId);

        requestAnimationFrame(() => {
          if (!isInViewport(element)) {
            const scrollOptions = { 
              behavior: 'smooth',
              block: 'center', 
              inline: 'nearest'
            }; 
      
            console.log("scrollIntoView calling scrollToActiveNode nodeId:", nodeId);
            element.scrollIntoView(scrollOptions);  
          }
          element.classList.add('highlight');
          setPrevNodeId(nodeId);
        });
      }
    };

    const scrollToActiveNode = (nodeId) => {  
      setActiveNodeId(nodeId);
      setTrigger1(trigger1 + 1)
    }

    useImperativeHandle(ref, () => ({
      scrollToActiveNode,
    }));

    //const debouncedScroll = debounce(scrollToActiveNodeLocal, 100);
    
    useEffect(() => {
      if (trigger1 === 0) { return; }
      setTrigger1(0);
  
      //debouncedScroll(activeNodeId);
      //scrollToActiveNodeLocal(activeNodeId);

      // Ensure that the element is rendered before scrolling
      //setTimeout(() => {
        scrollToActiveNodeLocal(activeNodeId);
      //}, 50);

      // Small delay before resetting the trigger to avoid conflicts
      //setTimeout(() => setTrigger1(0), 100);
    }, [trigger1]);

 
    const isInViewport = (element) => {
      const rect = element.getBoundingClientRect();
      //console.log("Element rect:", rect);  // Debugging
      const isAllZeros = rect.top === 0 && rect.right === 0 && rect.bottom === 0 && rect.left === 0 && rect.width === 0 && rect.height === 0;
      return !isAllZeros && rect.top >= 0 && rect.bottom <= window.innerHeight;
  };

    const isRowVisible = (rowIndex) => {
        const rowElement = nodeRefs.current[rows[rowIndex].original.id];
        if (!rowElement || !nodeListRef.current) return false;
    
        const listRect = nodeListRef.current.getBoundingClientRect();
        const rowRect = rowElement.getBoundingClientRect();
    
        // Check if the row is within the nodeListContainer's visible bounds
        return rowRect.top >= listRect.top && rowRect.bottom <= listRect.bottom;
    };
  
    const TokenColumns = React.useMemo(
      () => {
        const columns = [
          {
            Header: () => (
              <div style={{ textAlign: 'right', marginLeft: '12px' }}>&#x1F60A;</div>
            ),
            accessor: 'icon',
            Cell: ({ row }) => {
              const { ref, inView } = useInView({ triggerOnce: true });
              const [imageFailed, setImageFailed] = React.useState(false);
    
              return (
                <div ref={ref} className={classes.iconColumn} style={{ textAlign: 'center', marginLeft: '4px' }}>
                  {inView && !imageFailed && (
                    <img src={row.original.icon} alt="" className={classes.iconColumn} style={{ textAlign: 'center', marginLeft: '4px' }}
                      onClick={(e) => handleNodeLinkClick(row.original.id, 'NFT', e)} loading="lazy"
                      onError={() => setImageFailed(true)} />
                  )}
                </div>
              );
            },
          },
          {
            id: 'label',
            Header: () => (
              <div>Name</div>
            ),
            accessor: 'label',
            Cell: ({ row }) => (
              <div style={isMobile && isPortrait ? { maxWidth: viewGraph ? '240px' : '180px', width: viewGraph ? '240px' : '180px' } : !viewGraph ? { maxWidth: '400px', width: '400px' } : {}}> 
                <button onClick={(e) => handleNodeLinkClick(row.original.id, 'address', e)}
                  className={classes.listTextStyle}
                >
                  {row.original.label}
                </button>
              </div>
            ),
          },
        ];
    
        if (!isMobile || (isMobile && viewGraph)) {
          columns.push({
            Header: () => (
              <div style={{ textAlign: 'left' }}>Symbol</div>
            ),
            accessor: 'symbol',
            Cell: ({ row }) => (
              <div>
                <button onClick={(e) => handleNodeLinkClick(row.original.id, 'address', e)} className={classes.listTextStyle} style={{ maxWidth: '100px' }}>
                  {row.original.symbol}
                </button>
              </div>
            ),
          });
        }
        
        if (!viewGraph) {
          columns.push({
            Header: () => (
              <div className={classes.tableHeaderRight}>
                <span>Price</span>
                <button 
                  onClick={() => setShowPriceDetails(true)} 
                  className={classes.listTextStyle} 
                  style={{ color: 'yellow', marginLeft: '5px' }}
                >
                  ?
                </button>
              </div>
            ),
            accessor: 'price',
            Cell: ({ row }) => (
              <div style={{ textAlign: 'right' }}>
                <button 
                  onClick={(e) => handleNodeLinkClick(row.original.id, 'address', e)} 
                  className={classes.listTextStyle} 
                  style={{ color: 'RGB(0, 255, 0)' }}
                >
                  {(row.original.price) ? formatPrice(row.original.price) : ''}
                </button>
              </div>
            ),
            sortType: (rowA, rowB) => {
              const priceA = parseFloat(rowA.original.price);
              const priceB = parseFloat(rowB.original.price);
              return priceA - priceB;
            },
          });          
        }
        
        if (!viewGraph) {
          columns.push({
            Header: () => (
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', textAlign: 'right' }}>
                <div style={{ marginRight: '8px' }}>Market Cap</div>
                <button onClick={() => setShowPriceDetails(true)} className={classes.listTextStyle} style={{ color: 'yellow' }}>
                  <span style={{ color: 'yellow' }}>?</span>
                </button>
              </div>
            ),
            accessor: 'marketCap',
            Cell: ({ row }) => (
              <div style={{ textAlign: 'right' }}>
                <button onClick={(e) => handleNodeLinkClick(row.original.id, 'address', e)} className={classes.listTextStyle} style={{ color: 'RGB(0, 255, 0)' }}>
                  {(row.original.marketCap) ? formatMarketCap(row.original.marketCap) : '-'}
                </button>
              </div>
            ),
            sortType: (rowA, rowB) => {
              const capA = parseFloat(rowA.original.marketCap);
              const capB = parseFloat(rowB.original.marketCap);
              return capA - capB;
            },
          });
        }
        
        if (!viewGraph && !isMobile) {
          columns.push({
            Header: () => (
              <div style={{ textAlign: 'right' }}>Total Supply</div>
            ),
            accessor: 'supply',
            Cell: ({ row }) => (
              <div style={{ textAlign: 'right' }}>
                <button onClick={(e) => handleNodeLinkClick(row.original.id, 'address', e)} className={classes.listTextStyle} style={{ color: 'RGB(0, 255, 0)' }}>
                  {(row.original.supply) ? Number(row.original.supply).toLocaleString() : '-'}
                </button>
              </div>
            ),
          });
        }

        if (!viewGraph && !isMobile) {
          columns.push({
            Header: () => (
              <div style={{ textAlign: 'right' }}>Version</div>
            ),
            accessor: 'version',
            Cell: ({ row }) => (
              <div style={{ textAlign: 'right' }}>
                <button onClick={(e) => handleNodeLinkClick(row.original.id, 'address', e)} className={classes.listTextStyle} style={{ color: 'RGB(0, 255, 0)' }}>
                  {(row.original.version) ? row.original.version : '-'}
                </button>
              </div>
            ),
          });
        }

        if (viewGraph) {
          columns.push({
            Header: () => (
              <div style={{ textAlign: 'right' }}>Volume</div>
            ),
            accessor: 'links',
            width: 50,
            Cell: ({ row }) => {
              // Find the matching node in graphData.nodes
              const matchingNode = graphData.nodes.find(node => node.id === row.original.id);
              const volume = matchingNode ? matchingNode.volume : [];
        
              return (
                <div style={{ textAlign: 'right', width: "100%" }}>
                  <Sparklines data={volume} width={100} height={14}>
                    <SparklinesLine color="RGB(0, 255, 0)" />
                  </Sparklines>
                </div>
              );
            },
          });
        }
        
        return columns;
      },
      [viewGraph, classes, isMobile, isPortrait, setShowPriceDetails]
    );

    const NFTColumns = React.useMemo(
      () => [    
        {
          id: 'icon', 
          Header: () => (
            <div className={classes.listTextStyle} style={{ textAlign: 'center', marginLeft: '4px' }}>&#x1F60A;</div> // Adds a thin gray line under the header text
          ),
          accessor: 'icon',
          Cell: ({ row }) => {
            const { ref, inView } = useInView({ triggerOnce: true });
            const [imageFailed, setImageFailed] = React.useState(false);

            return (
                <div ref={ref} className={classes.iconColumn} style={{ textAlign: 'center', marginLeft: '4px' }} > 
                  {inView && !imageFailed && (
                      <img src={row.original.icon} alt="" className={classes.iconColumn} style={{ textAlign: 'center', marginLeft: '4px' }}
                        onClick={(e) => handleNodeLinkClick(row.original.id, 'NFT', e)} loading="lazy"
                        onError={() => setImageFailed(true)} /> 
                  )}
              </div>
            );
          },
        }, 
        {
          id: 'label', 
          Header: () => (
            <div style={{ textAlign: 'left' }}>Name</div> // Adds a thin gray line under the header text
          ),
          accessor: row => row.label.trim().toLowerCase(),  // Trim and convert to lowercase
          Cell: ({ row }) => (
            <div>
                <button onClick={(e) => handleNodeLinkClick(row.original.id, 'NFT', e)} 
                  className={classes.listTextStyle} 
                  style={{ maxWidth: (isMobile && isPortrait) ? '270px' : '150px'}}
                >
                  {row.original.label}
                </button>
            </div>
          ),
        }, 
        {
          id: 'likes',
          Header: () => (
            <div style={{ textAlign: 'right' }}>Likes</div> // Adds a thin gray line under the header text
          ),
          accessor: 'likes',
          Cell: ({ row }) => (
            <div className={classes.tableCellNumber}>
              <button onClick={(e) => handleNodeLinkClick(row.original.id, 'NFT', e)} className={classes.listTextStyle} style={{ maxWidth: '125px' }}>
                {row.original.likes}
              </button>
            </div>
          ),
        },
        {
          id: 'count',
          Header: () => (
            <div style={{ textAlign: 'right' }}>Count</div> // Adds a thin gray line under the header text
          ),
          accessor: 'count',
          Cell: ({ row }) => (
            <div className={classes.tableCellNumber}>
                <button onClick={(e) => handleNodeLinkClick(row.original.id, 'NFT', e)} className={classes.listTextStyle} style={{ maxWidth: '100px'}}>
                  {row.original.count}
                </button>
            </div>
          ),
        },         
      ],
      []
    );
  

    // Define the columns based on the tokenType. If viewGraph is false, hide the Market Cap column
    const columns = React.useMemo(
      () => tokenType === "Token" ? 
        (viewGraph ? TokenColumns.filter(column => column.accessor !== 'marketCap') : TokenColumns) 
        : NFTColumns,
      [tokenType, viewGraph]
    );

    const data = React.useMemo(() => tokenListCopy
      .filter(token => !token.label.startsWith('0x')) // Add this line to filter out labels starting with '0x'
      .map(token => ({
        ...token,
        links: linkCount.get(token.id) || 0,
    })),
    [tokenListCopy]); // Ensure graphData.nodes is the only dependency unless others are needed for re-computation

    const tableKey = React.useMemo(() => Math.random(), [tokenListCopy]);
 
    //console.log('activeNodeId:', activeNodeId);
    //console.log('tokenType:', tokenType);
    //console.log('columns:', columns.map(column => column.id));

    const initialState = React.useMemo(() => ({
      sortBy: tokenType === "Token" ? (viewGraph ? [{ id: 'links', desc: true }] : [{ id: 'price', desc: true }]) : [{ id: 'label', asc: true }]
    }), [tokenType, viewGraph]);
    
    const {
      getTableProps,
      getTableBodyProps, 
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ 
      columns, 
      data, 
      initialState
    }, useSortBy); 

    useEffect(() => {
      rows.forEach(row => {
        if (!nodeRefs.current[row.original.id]) {
          nodeRefs.current[row.original.id] = {};  // Initialize ref only once
        }
      });
    }, [rows]);


    //console.log('SortBy State:', initialState.sortBy);

    
    const highlightRowStyle = { backgroundColor: '#A9A9A9' }; // Replace with your desired highlight style

    const handleNodeLinkClick = (id, type, e) => {
      stopCameraSpin();
      if (e.shiftKey && id.startsWith('0x')) {
        getTransactions(dataSourceRef.current, id);
      } else if (type == 'tx')  {
        handleLinkClick(graphDataRef.current, graphRef, id, true);
      } else {
        handleNodeClick(dataSourceRef.current, graphDataRef.current, graphRef, id, true, type);
      }
    };
  
    const NodeListTable = React.memo(() => {
      return ( 
        <table {...getTableProps()} key={tableKey} className={classes.table} style={{ height: '100px', overflow: 'auto' }}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={classes.tableHeader}
                  style={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: '#000',
                    textAlign: column.id === 'price' || column.id === 'marketCap' || column.id === 'supply' || column.id === 'version' || column.id === 'likes' || column.id === 'count'
                      ? 'right' // Only right-align these columns
                      : 'left',
                    padding: '0',
                  }}
                  >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: column.id === 'price' || column.id === 'marketCap' || column.id === 'supply'  | column.id === 'version' || column.id === 'likes' || column.id === 'count'
                        ? 'flex-end' // Right-align content for these columns
                        : 'flex-start',
                      alignItems: 'center',
                      height: '20px',
                      borderBottom: '1px solid gray',
                    }}
                  >
                    {column.render('Header')}
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className={classes.tableBody}>
            {rows.map(row => {
              prepareRow(row); 
              const isRowActive = row.original.id === activeNodeId;
              return ( 
                <tr {...row.getRowProps({ 
                  className: isRowActive ? classes.activeRow : classes.tableRow,
                  style: isRowActive ? highlightRowStyle : {},
                  ref: element => {
                    // Store a ref for each row
                    if (element) {  //} && row.original) {
                      // Only set the ref if it doesn't already exist to avoid unnecessary resets
                    //  if (!nodeRefs.current[row.original.id]) {
                        nodeRefs.current[row.original.id] = element;
                    //  }
                    }
                  } })}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} className={classes.tableCell}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }, (prevProps, nextProps) => {
      // Only rerender if rows or activeNodeId changes
      return prevProps.rows === nextProps.rows && prevProps.activeNodeId === nextProps.activeNodeId;
    });
    
    
    return (
      <div>
        <div ref={nodeListRef} tabIndex="-1" 
          className={(isMobile && isPortrait) ? classes.nodeListContainerMobile : classes.nodeListContainer}
          id={(isMobile && isPortrait) ? "nodeListContainerMobile" : "nodeListContainer" }         
        >
          <div>
            <NodeListTable/>
            {showPriceDetails && (
              <PriceDetailsDialog onClose={() => setShowPriceDetails(false)} />
            )}
          </div>
        </div>
      </div>
    );
}), arePropsEqual);
  

// LinkList - list of transactions
export const LinkList = (props) => {
    const { graphData, graphRef, dataSource, rate, stopCameraSpin, handleNodeClick, handleLinkClick, activeLinkId, activeNodeId, transactions  } = props;
    const classes = useStyles();
    const graphDataRef = useRef(graphData);
    const dataSourceRef = useRef(dataSource);
    const linkRefs = useRef({});
    const linkListRef = useRef();
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      graphDataRef.current = graphData;
      dataSourceRef.current = dataSource;
    }, [graphData, dataSource]);

    useEffect(() => {
      const preventDefaultKeyDown = (event) => {
          if (event.key === 'ArrowDown') {
              event.preventDefault();
          }
      };
      document.addEventListener('keydown', preventDefaultKeyDown);
      return () => document.removeEventListener('keydown', preventDefaultKeyDown);
   }, []);

    useEffect(() => {
      if (activeLinkId && linkRefs.current[activeLinkId]) {
        scrollToActiveLink(activeLinkId);
      }
    }, [activeLinkId]);

    useEffect(() => {
      const linkListElement = linkListRef.current;
  
      const handleMouseEnter = () => {
          linkListElement.focus();
      };
  
      const handleMouseLeave = () => {
          linkListElement.blur(); // Optional, based on desired behavior
      };
  
      linkListElement.addEventListener('mouseenter', handleMouseEnter);
      linkListElement.addEventListener('mouseleave', handleMouseLeave);
  
      return () => {
          linkListElement.removeEventListener('mouseenter', handleMouseEnter);
          linkListElement.removeEventListener('mouseleave', handleMouseLeave);
      };
    }, []);


    useEffect(() => {
        //console.log("Effect running", { activeNodeId, rowsLength: rows.length });

        const handleKeyDown = (event) => {
            if ((event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
                // Check if the nodeListContainer is currently focused
                if (document.activeElement === linkListRef.current) {
                    const currentIndex = rows.findIndex(row => row.original.blockNumber === activeLinkId);
                    const newIndex = (event.key === 'ArrowDown') ? currentIndex + 1 : currentIndex - 1;
    
                    // Ensure newIndex is within bounds
                    if (newIndex >= 0 && newIndex < rows.length) {
                        setCurrentIndex(newIndex);
                        const newActiveLinkId = rows[newIndex].original.id;
                        
                        // Update the active node
                        handleLinkClick(dataSourceRef.current, graphDataRef.current, graphRef, newActiveLinkId, false);
                        
                        // Scroll to the new node if it's not visible
                        if (!isRowVisible(newIndex)) {
                            scrollToActiveLink(newActiveLinkId);
                        }
                    }
                }
            }
        };
      
        document.addEventListener('keydown', handleKeyDown);
 
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    }, [activeLinkId, handleLinkClick, dataSourceRef, graphDataRef, graphRef, setCurrentIndex ]); 

  
    const scrollToActiveLink = (txHash) => {
      const element = linkRefs.current[txHash];
    
      if (element && !isInViewport(element)) {
        const scrollOptions = {
          behavior: 'smooth',
          block: 'center', 
          inline: 'nearest'
        };
    
        element.scrollIntoView(scrollOptions);
      }
    };

    const isInViewport = (element) => {
        const rect = element.getBoundingClientRect();
        return rect.top >= 0 && rect.bottom <= window.innerHeight;
    };

    const isRowVisible = (rowIndex) => {
        const rowElement = linkRefs.current[rows[rowIndex].original.blockNumber];
        if (!rowElement || !linkListRef.current) return false;
    
        const listRect = linkListRef.current.getBoundingClientRect();
        const rowRect = rowElement.getBoundingClientRect();
    
        // Check if the row is within the nodeListContainer's visible bounds
        return rowRect.top >= listRect.top && rowRect.bottom <= listRect.bottom;
    };
  
    const onClick = (id, type, e) => {
      stopCameraSpin();
      if (e.shiftKey && id.startsWith('0x') && dataSource > 0) {
        const explorerBaseUrl = (dataSource == ETHERSCAN) ? 'https://etherscan.io' : 'https://bscscan.com';
        const url = explorerBaseUrl + '/' + type + '/' + id;
        window.open(url, '_blank');
      } else if (type == 'tx')  {
        handleLinkClick(dataSourceRef, graphDataRef.current, graphRef, id, true);
      } else {
        handleNodeClick(dataSourceRef, graphDataRef.current, graphRef, id, true);
      }
    };

  
    function formatValueEth(valueWei, ethToUsdRate) {
      const valueEth = convertWeiToEth(valueWei).toFixed(6);
      const valueUsd = (valueEth * ethToUsdRate).toLocaleString('en-US', {style: 'currency', currency: 'USD'});
      const value = `${valueEth} ETH `;
      return value;
    }

    function formatValueUSD(valueWei, ethToUsdRate) {
      const valueEth = convertWeiToEth(valueWei).toFixed(6);
      const valueUsd = (valueEth * ethToUsdRate).toLocaleString('en-US', {style: 'currency', currency: 'USD'});
      const value = `(${valueUsd})`;
      return value;
    }

    function convertWeiToEth(wei) {
      return wei / 1e18;
    }
   
    function formatWeiToGwei(wei) {
      const weiInOneEth = 1e18; // 1,000,000,000,000,000,000 Wei in one Ether
      const weiInOneGwei = 1e9; // 1,000,000,000 Wei in one Gwei
      // Convert Wei to Gwei and ETH
      const gwei = wei / weiInOneGwei; // Convert Wei to Gwei
      const eth = wei / weiInOneEth; // Convert Wei to Ether
      // Format to a fixed number of decimal places for readability
      const formattedGwei = gwei.toFixed(9); // Gwei usually does not require many decimal places
      const value = `${formattedGwei} Gwei `; 
      return value; 
    }

    function formatWeiToEth(wei) {
      const weiInOneEth = 1e18; // 1,000,000,000,000,000,000 Wei in one Ether
      const weiInOneGwei = 1e9; // 1,000,000,000 Wei in one Gwei
      // Convert Wei to Gwei and ETH
      const gwei = wei / weiInOneGwei; // Convert Wei to Gwei
      const eth = wei / weiInOneEth; // Convert Wei to Ether
      // Format to a fixed number of decimal places for readability
      const formattedGwei = gwei.toFixed(9); // Gwei usually does not require many decimal places
      const formattedEth = eth.toFixed(18); // ETH can be very small, so keeping all decimal places
      const value = `(${formattedEth} ETH)`;
      return value; 
    }

    function formatTransactionCostEth(gasLimit, gasPrice) {
      const gasLimitNum = Number(gasLimit);
      const gasPriceNum = Number(gasPrice); // Assuming gasPrice is already in wei
      // No need to convert gasPrice from gwei to wei
      const totalGasCostInWei = gasLimitNum * gasPriceNum;
      const totalGasCostInEther = totalGasCostInWei / 1e18; // Convert wei to ether
      const value = `${totalGasCostInEther.toFixed(6)} ETH `;
      return value;
    }
  
    function formatTransactionCostUSD(gasLimit, gasPrice, currentEthRate) {
      const gasLimitNum = Number(gasLimit);
      const gasPriceNum = Number(gasPrice); // Assuming gasPrice is already in wei
      const ethRateNum = Number(currentEthRate);
      // No need to convert gasPrice from gwei to wei
      const totalGasCostInWei = gasLimitNum * gasPriceNum;
      const totalGasCostInEther = totalGasCostInWei / 1e18; // Convert wei to ether
      const totalGasCostInUSD = totalGasCostInEther * ethRateNum;
      const value = `($${totalGasCostInUSD.toFixed(2)})`;
      return value;
    }

    function convertUTCToLocalAsTimestamp(utcDateString) {
      // Append 'Z' to the date string to indicate that it's in UTC time
      const utcDateWithZ = utcDateString + 'Z';
      // Create a date object using the updated string
      const date = new Date(utcDateWithZ);
      // Convert to local time string in Pacific Time
      const localTimeString = date.toLocaleString("en-US", { timeZone: "America/Los_Angeles" });
      return localTimeString;
    }
    

    const columns = React.useMemo(
      () => [
        {
          Header: 'Timestamp',
          accessor: 'timestamp',
          Cell: ({ row }) => (
            <div style={{ display: 'flex', alignItems: 'left' }}>
               <button onClick={(e) => onClick(row.original.tx, 'tx', e)} className={classes.linkButtonStyle}>
                {convertUTCToLocalAsTimestamp(row.original.timestamp)}
              </button>
            </div>
          ),
        },
        {
          Header: 'Type',
          accessor: 'txType',
          Cell: ({ row }) => (
            <div style={{ display: 'flex', alignItems: 'left' }}>
              <button onClick={(e) => onClick(row.original.tx, 'tx', e)} className={classes.linkButtonStyle}>
                {row.original.txType === '0' ? 'Swap' : row.original.txType}
              </button>
            </div>
          ),
        },
        {
          Header: 'From',
          accessor: 'source.label',
          Cell: ({ row }) => (
            <div style={{ display: 'flex', alignItems: 'left' }}>
              <img src={(row.original.fromIcon) ? row.original.fromIcon : genericUserIcon}  alt="" className={classes.iconColumn} />
              <button onClick={(e) => onClick(row.original.tx, 'tx', e)} className={classes.linkButtonStyle}>
                {formatShortAddress(row.original.source)}
              </button>
            </div>
          ),
        },
        {
          Header: 'To',
          accessor: 'target.label',
          Cell: ({ row }) => (
            <div style={{ display: 'flex', alignItems: 'left' }}>
              <img src={(row.original.toIcon) ? row.original.toIcon : genericUserIcon} alt="" className={classes.iconColumn} />
              <button onClick={(e) => onClick(row.original.tx, 'tx', e)} className={classes.linkButtonStyle}>
                {formatShortAddress(row.original.target)}
              </button>
            </div>
          ),
        },
        {
          Header: 'Block#',
          accessor: 'blockNumber',
          Cell: ({ row }) => (
            <div style={{ display: 'flex', alignItems: 'left' }}>
              <button onClick={(e) => onClick(row.original.tx, 'tx', e)} className={classes.linkButtonStyle}>
                {row.original.blockNumber}
              </button>
            </div>
          ),
        },
        {
          Header: 'Transaction',
          accessor: 'tx',
          Cell: ({ row }) => (
            <button onClick={(e) => onClick(row.original.tx, 'tx', e)} className={classes.linkButtonStyle}>
              {formatShortAddress(row.original.tx)}
            </button>
          ),
        },
        {
          Header: 'Value',
          accessor: 'value',
          Cell: ({ row }) => (
            <div>
              <button onClick={(e) => onClick(row.original.tx, 'tx', e)} className={classes.linkButtonStyle}>
                {formatValueEth(row.original.value,rate)}
              </button>
              <button onClick={(e) => onClick(row.original.tx, 'tx', e)} className={classes.goldButtonStyle}>
                {formatValueUSD(row.original.value,rate)}
              </button>
            </div>
          ),
        },
        {
          Header: 'Transaction Fee',
          accessor: 'fee',
          Cell: ({ row }) => (
            <div>
              <button onClick={(e) => onClick(row.original.tx, 'tx', e)} className={classes.linkButtonStyle}>
                {formatTransactionCostEth(row.original.gasLimit, row.original.gasPrice)}
              </button>
              <button onClick={(e) => onClick(row.original.tx, 'tx', e)} className={classes.goldButtonStyle}>
                {formatTransactionCostUSD(row.original.gasLimit, row.original.gasPrice, rate)}
              </button>
            </div>
          ),
        },
        {
          Header: 'Gas Limit',
          accessor: 'gasLimit',
          Cell: ({ row }) => (
            <button onClick={(e) => onClick(row.original.tx, 'tx', e)} className={classes.linkButtonStyle}>
              {row.original.gasLimit}
            </button>
          ),
        },
        {
          Header: 'Gas Used',
          accessor: 'gasUsed',
          Cell: ({ row }) => (
            <button onClick={(e) => onClick(row.original.tx, 'tx', e)} className={classes.linkButtonStyle}>
              {row.original.gasUsed}
            </button>
          ),
        },
        {
          Header: 'Gas Price',
          accessor: 'gasPrice',
          Cell: ({ row }) => (
            <div>
              <button onClick={(e) => onClick(row.original.tx, 'tx', e)} className={classes.linkButtonStyle}>
                {formatWeiToGwei(row.original.gasPrice)}
              </button>
              <button onClick={(e) => onClick(row.original.tx, 'tx', e)} className={classes.goldButtonStyle}>
                {formatWeiToEth(row.original.gasPrice)}
              </button>
            </div>
          ),
        },

        // ... other columns ...
      ],
      [classes.linkButtonStyle]
    );
  
    const data = React.useMemo(() => 
      transactions.filter(tx => ((tx.txType != '1' && tx.txType != '2' && tx.txType != '') && (!activeNodeId || (tx.source == activeNodeId || tx.target == activeNodeId)))),
      [transactions, activeNodeId] // Ensure graphData.links is the only dependency unless others are needed for re-computation
    );

    const highlightRowStyle = { backgroundColor: '#A9A9A9' }; // Replace with your desired highlight style

    // Use the useTable Hook to send the columns and data to build the table
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ columns, data }, useSortBy);
  
    return (
      <div ref={linkListRef}  tabIndex="-1" className={classes.linkListContainer}>
        <table {...getTableProps()} className={classes.table}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} className={classes.tableHeader}>
                    <div style={{ display: 'flex', alignItems: 'center', height: '20px', borderBottom: '1px solid gray' }}>
                      {column.render('Header')}
                      {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className={classes.tableBody}>
            {rows.map(row => {
              prepareRow(row);
              const isRowActive = row.original.tx === props.activeLinkId;
              return (
                <tr {...row.getRowProps({ 
                  className: isRowActive ? classes.activeRow : classes.tableRow,
                  style: isRowActive ? highlightRowStyle : {},
                  ref: element => {
                    // Store a ref for each row
                    if (element && row.original) {
                      linkRefs.current[row.original.tx] = element;
                    }
                  } })}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} className={classes.tableCell}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  
  
export const LinkListPCAP = (props) => {
    const { graphData, graphRef, mapData, stopCameraSpin, handleNodeClick  } = props;
    const classes = useStyles();
  
    const handleNodeLinkClick = (id, e) => {
        stopCameraSpin();
        handleNodeClick(id);
    };
  
    return (
      <div className={classes.nodeListContainer}>
        <table style={{ tableLayout: 'fixed', width: '100%' }}>
          <thead>
            <tr className={classes.tableRow}>
              <th className={classes.tableHeader}>From</th>
              <th className={classes.tableHeader}>To</th>
              <th className={classes.tableHeader}>Value</th>
            </tr>
          </thead> 
          <tbody>
            {graphData.links.map((link, index) => {
              const sourceData = mapData.get(link.source.id);
              const targetData = mapData.get(link.target.id);
              const sourceColor = sourceData ? sourceData.color : 'white';
              const targetColor = targetData ? targetData.color : 'white';
              const date = new Date(link.timestamp * 1000).toDateString();
  
              return (
                <tr className={classes.tableRow} key={`${link.source.id}-${link.target.id}-${index}`}>
 
                  <td className={`${classes.tableCell}`}>
                    <Button onClick={(e) => handleNodeLinkClick(graphData, graphRef, link.source.id, e)} className={classes.linkButtonStyle}>
                      {link.source.label}
                    </Button>
                  </td>
                  <td className={classes.tableCell}>
                    <Button onClick={(e) => handleNodeLinkClick(graphData, graphRef, link.target.id, e)} className={classes.linkButtonStyle}>
                      {link.target.label}
                    </Button>
                  </td>
                  <td className={classes.tableCellNumber}>
                    <p className={classes.linkButtonStyle}>
                      {link.value} 
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };
  